// Importera React och nödvändiga komponenter
import React from 'react';
// Importera styled components
import { ServicesContainer, ServicesH1, ServicesH2, ServicesWrapper, ServiceCard, ServicesP } from './FeaturesElements';

// Komponenten för sidan "Funktioner"
const Features = () => {
  return (
    <ServicesContainer id="features">
        <ServicesH1>Funktioner</ServicesH1>
        <ServicesWrapper>
            {/* Kort 1 till 6 med respektive beskrivning */}
            <ServiceCard>
                <ServicesH2>Digitala Planritningar</ServicesH2>
                <ServicesP>Spara och hantera dina planritningar digitalt. Markera enkelt åtgärdspunkter direkt på ritningen för tydlig översikt och uppföljning.</ServicesP>
            </ServiceCard>

            <ServiceCard>
                <ServicesH2>Flexibla Kommentarer</ServicesH2>
                <ServicesP>Använd din röst eller tangentbordet för att snabbt lägga till kommentarer via vår mobilapp. Gör dina observationer levande och detaljrika.</ServicesP>
            </ServiceCard>

            <ServiceCard>
                <ServicesH2>Bildintegration</ServicesH2>
                <ServicesP>Ta bilder på plats eller välj från ditt galleri. Bilderna kopplas automatiskt till dina kommentarer för en visuell och detaljerad dokumentation.</ServicesP>
            </ServiceCard>

            <ServiceCard>
                <ServicesH2>Effektiv Rapportering</ServicesH2>
                <ServicesP>Justera kommentarer och välj bilder med vår webbapplikation. Färdigställ din rapport med några få klick och dela med intressenter.</ServicesP>
            </ServiceCard>

            <ServiceCard>
                <ServicesH2>Samarbeta med ditt Team</ServicesH2>
                <ServicesP>Bjud in kollegor för att gemensamt arbeta med projekt. Dela information, framsteg och uppgifter för ett sömlöst samarbete.</ServicesP>
            </ServiceCard>

            <ServiceCard>
                <ServicesH2>Kontinuerlig Uppföljning</ServicesH2>
                <ServicesP>Ta med åtgärdspunkter från tidigare rapporter för effektiv uppföljning. Säkerställ kontinuitet och optimal hantering av åtgärdspunkter från dina fuktronder.</ServicesP>
            </ServiceCard>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

// Exportera komponenten
export default Features;
