import React, { useRef, useState } from "react";
import { useAuth } from "../Context/AuthContext";
import logo2 from "../images/projektalliansLogo3.png";
import { Link } from "react-router-dom";
import Logo from "../images/WhiteLogo.svg";
import newLogo from '../images/ProjectLogoThree.png'

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Se efter i din inbox för vidare information");
    } catch {
      setError("Misslyckades med att återställa lösenord");
    }
    setLoading(false);
  }
  return (
    <>
      <div className="flex h-screen items-center justify-center px-6 py-12 lg:px-8 bg-gradient-to-r from-[#2750E4] to-[#3C7AE9]">
        <Link to="/" className="absolute left-10 top-10">
          <img src={Logo} alt="Logo" className="h-12" />
        </Link>
        <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white p-8 rounded-lg shadow-xl">
        <img src={newLogo} alt="" className="mx-auto h-36 w-auto" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Behöver du ett nytt lösenord?
          </h2>
          {error && (
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-10"
              role="alert"
            >
              <strong class="font-bold">{error}</strong>
            </div>
          )}
          {message && (
            <div
              class="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mt-10"
              role="alert"
            >
              <strong class="font-bold">{message}</strong>
            </div>
          )}
        

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  ref={emailRef}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#2750E4] to-[#3C7AE9] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 max-w-1/3"
              >
                Återställ lösenord
              </button>
            </div>
          </form>

          <div className="mt-10 text-center text-sm text-gray-500">
            <Link
              className="font-semibold leading-6 text-blue-500 hover:text-blue-700"
              to="/login"
            >
              Logga in
            </Link>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
