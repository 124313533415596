import styled from 'styled-components'

export const InfoContainer = styled.div` 
    color: #fff; 
    background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};
    height: 100vh; // Default height for larger screens

    @media screen and (max-width: 768px) {
        height: auto; // Make height auto to expand as needed with content
        min-height: 100vh; // Ensure it is at least as tall as the viewport
        padding: 100px 0; // Keep your existing padding to add space above and below the content
    }
`;


export const InfoWrapper = styled.div` 
    display: grid;
    z-index: 1;
    height: 860px; 
    width: 100%;
    max-width: 1100px; 
    margin-right: auto;
    margin-left: auto; // Added to center the grid
    padding: 0 24px; 
    justify-content: center;
    align-items: center;
`

export const InfoRow = styled.div` 
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center; 
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1' 'col2'`)};
    }
`

export const Column1 = styled.div` 
    margin-bottom: 15px;
    padding: 0 15px; 
    grid-area: col1;
`

export const Column2 = styled.div` 
    margin-bottom: 15px;
    padding: 0 15px; 
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 540px; 
    padding-top: 0;
    padding-bottom: 60px; 

`
export const TopLine = styled.p` 
    color: #2750E4;
    font-size: 16px; 
    line-height: 16px; 
    font-weight: 700; 
    letter-spacing: 1.4px; 
    text-transform: uppercase; 
    margin-bottom: 16px; 
`

export const Heading = styled.h1` 
    margin-bottom: 24px; 
    font-size: 48px; 
    line-height: 1.1; 
    font-weight: 600; 
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

@media screen and (max-width: 480px) {
    font-size: 32px;
}
`
export const Subtitle = styled.p` 
    max-width: 440px; 
    margin-bottom: 35px; 
    font-size: 18px; 
    line-height: 24px; 
    color: ${({darkText}) =>darkText ? '#010606' : '#fff'};
`

export const BtnWrap = styled.div` 
    display: flex; 
    justify-content: flex-start; 
`

export const ImgWrap = styled.div` 
    max-width: 555px; 
    height: auto;
    @media screen and (max-width: 768px) {
        max-width: 100%; // Allow image to use more space if needed
    }
`

export const Img = styled.img`
    width: 100%; // Ensure image scales with its container
    height: auto; // Maintain aspect ratio
    margin: 0 0 10px 0; 
    padding-right: 0;
`;