import styled from 'styled-components'
import { Link as linkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'

export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s alll ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding 0 24px;
    max-width: 1100px;
`;
export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    font-family: 'Encode Sans Expanded', sans-serif;

    img {
        width: 30px; /* Reduced from 40px to make the logo smaller */
        margin-right: 10px;
    }
`;




export const MobileIcon = styled.div`
    display: none; 

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer; 
        color: #fff
    }
`;

export const NavMenu = styled.ul`
   display: flex;
   align-items: center;
   list-style: none;
   text-align: center; /* Corrected 'text-aligh' to 'text-align' */
   margin-right: -22px; 

   @media screen and (max-width: 768px) {
    display: none;
   }
`;

export const NavItem = styled.li`
   height: 80px; 
`;

export const NavLinks = styled(linkS)`
   color: #fff;
   display: flex;
   align-items: center;
   text-decoration: none;
   padding: 0 1rem;
   height: 100%;
   cursor: pointer; 
   font-family: 'Encode Sans Expanded', sans-serif;


  &.active {
    border-bottom: 3px solid #2750E4;
  }
`;

export const NavBtn = styled.nav`
   display: flex;
   align-items: center;

   @media screen and (max-width: 768px) {
    display: none;
   }
`

export const NavBtnLink = styled(LinkR)`
   border-radius: 50px;
   background: #2750E4;
   white-spacee: nowrap;
   padding: 10px 22px;
   color: #fff;
   font-size: 16px;
   outline: none;
   border: none;
   cursor: pointer;
   transition: all 02s ease-in-out;
   text-decoration: none;
   margin-right: 24px;

   &:hover {
    transition: all 0.2s ease-in-out,
    background: #fff;
    color: #fff;
   }
   }
`