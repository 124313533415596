import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/WhiteLogo.svg";
import newLogo from "../images/ProjectLogoThree.png";

const PrivacyPolicy = () => {
  return (
    <div className="flex h-full min-h-screen items-center justify-center px-6 pt-12 lg:px-8 bg-gradient-to-r from-[#2750E4] to-[#3C7AE9]">
      <Link to="/" className="absolute top-10 left-10">
        <img src={Logo} alt="Logo" className="h-12" />
      </Link>
      <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white p-8 rounded-lg shadow-xl mt-24 mb-5">
        
        <img src={newLogo} alt="Project Logo" className="mx-auto h-36" />
        <h2 className="mt-10 text-3xl font-bold text-center text-gray-900">
          Privacy Policy
        </h2>
        <p className="my-4 text-lg text-gray-700">
          Last Updated: February 19, 2024
        </p>
        <div className="h-80 overflow-y-auto">
          {/* This div will make the content scrollable within its fixed height */}
          <p className="mb-4">
            This Privacy Notice for Fuktrond.se ("we," "us," "our") describes
            how and why we may collect, store, and/or share ("process") your
            information when you use our services ("Services"), such as when
            you:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              Visit our website at fuktrond.se, or any website of ours that
              links to this privacy notice.
            </li>
            <li>
              Download and use our mobile application (Fuktrond), or any other
              application of ours that links to this privacy notice.
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events.
            </li>
          </ul>
          <p className="mb-4">
            Questions or Concerns? Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with
            our policies and practices, please do not use our Services.
          </p>
          <h3 className="text-xl font-semibold mb-2">Summary of Key Points</h3>
          <p className="mb-4">
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by browsing our
            tables of contents below to find the section you are looking for.
            What personal information do we process? When you visit, use or
            navigate our Services, we may process personal information depending
            on how you interact with us and the services, the choices you make
            and the products and features you use. Do we process any sensitive
            personal information? We do not process sensitive personal
            information. Do we receive any information from third parties? We
            don't receive any information from third parties. How do we process
            your information? We process your information to provie, improve and
            administer your services, communicate with you for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent. We process your
            information only when we have valid legal reason to do so. In what
            situations and which parts do we share personal information? We may
            share in specific situations and with specific third parties. Learn
            more about when and with whom we share your personal information
            under the title: … How do we keep your information safe? We have
            organizational and technical processes and procedures in place to
            protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we can not promise or guarantee
            that hackers, cybercriminals, our other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access steal, or modify your information, learnt more about how we
            keep your information safe under the title: ….. What are your
            rights? The easiest way to exercise your rights is by visiting
            https://fuktrond.se/update-profile , or by contacting us. We will
            consider and act upon any request in accordance with applicable data
            protection laws.
          </p>
          <ol className="list-decimal pl-8 mb-4">
            <li>What Personal Information Do We Process?</li>
            <li>How Do We Process Your Information?</li>
            <li>When and With Whom Do We Share Your Personal Information?</li>
            <li>How Long Do We Keep Your Information?</li>
            <li>How Do We Keep Your Information Safe?</li>
            <li>What Are Your Privacy Rights?</li>
            <li>Do We Make Updates to This Notice?</li>
            <li>How Can You Contact Us About This Notice?</li>
            <li>
              How Can You Review, Update, or Delete the Data We Collect From
              You?
            </li>
          </ol>
          <h3 className="text-xl font-semibold mb-2">
            What Personal Information Do We Process?{" "}
          </h3>
          <p className="mb-4">
            Personal information you disclose to us. in Short: we collect
            personal information that you provide to us. We collect personal
            information that you voluntarily provide to us when you express an
            interest in obtaining information about us or our products and
            services, when you participate in activities on the Services, or
            otherwise when you contact us. Personal information provided by you.
            the personal information that we collect depends on the context of
            your interactions with us and the Services, the choices you make,
            and the products and features you use. The Personal information we
            collect may include the following:
          </p>

          <ul className="list-disc pl-8 mb-4">
            <li>Names</li>
            <li>Email addresses</li>
            <li>Passwords</li>
          </ul>

          <p className="mb-4">
            Sensitive Information. We do not process sensitive information.
          </p>
          <p className="mb-4">
            Application Data. if you use our application(s), we also may collect
            the following information if you choose to provide us with access
            and permission:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              Mobile Device Access. We may request access or permission to
              certain features from your mobile device, including the mobile
              device’s gallery and other features. If you wish to change our
              access or permissions, you may do so in your device’s settings.
            </li>
          </ul>
          <p className="mb-4">
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting and for our
            internal analytics and reporting purposes.
          </p>

          <p className="mb-4">
            all personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <h3 className="text-xl font-semibold mb-2">
            2. How do we process your information?
          </h3>
          <p className="mb-4">
            In Short: we process your information to provide, improve and
            administer our Services, community with you, for security and fraud
            prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </p>
          <p className="mb-4">
            We process your personal information for a variety of reasons,
            depending on how you interact with our services, including:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              To deliver and facilitate delivery of services to the user. We may
              process your information to provide you with the requested
              service.
            </li>
          </ul>
          <h3 className="text-xl font-semibold mb-2">
            When and With Whom Do We Share Your Personal Information?
          </h3>
          <p className="mb-4">
            In short: We may share information in specific situations described
            in this section and/or with the following parties.
          </p>
          <p className="mb-4">
            We may need to share your personal information in the following
            situations:
          </p>
          <ul className="list-disc pl-8 mb-4">
            <li>
              Business transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">
            4. How Long Do We Keep Your Information?
          </h3>
          <p className="mb-4">
            In Short: we keep your information for as long as necessary to
            fulfill the purpose outlined in this privacy notice unless otherwise
            required by law.
          </p>
          <p className="mb-4">
            We will only keep your personal information as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law, such as
            tax, accounting or other legal requirements.
          </p>
          <p className="mb-4">
            When we have no ongoing legitimate business need to to process your
            information we will ether delete or anonymise such information, or,
            if this is not possible (for example, because your personal
            information has been stored in backup archives), then we will
            securely store your personal information and isolate it from any
            further processing until deletion is possible.
          </p>
          <h3 className="text-xl font-semibold mb-2">
            5. How Do We Keep Your Information Safe?
          </h3>
          <p className="mb-4">
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </p>
          <p className="mb-4">
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, No electronic transmission
            of the Internet or information storage technology can be guaranteed
            to be 100% secure, so we can not promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information at
            and from our Services is at your own risk. You should only access
            the Services within a secure environment.
          </p>
          <h3 className="text-xl font-semibold mb-2">
          6. What Are Your Privacy Rights?
          </h3>

          <p className="mb-4">
          In Short: You may review, change, or terminate your account at any time. 
          </p>

          <p className="mb-4">
          Withdrawing your consent: If we are relying y9oh your consent to process your personal information, which may be express and/or implied content depending on the applicable law, you have the right to withdraw your consent at any time You can withdraw your consent at any time by contacting us.
          </p>

          <h3 className="text-xl font-semibold mb-2">
          7. Do We Make Updates to This Notice?
          </h3>
          <p className="mb-4">
          In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
          </p>
          <p className="mb-4">
          We may update this privacy notice from time to time. The updated version will be indicated by an updated ‘Revised’ date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
          </p>
          <h3 className="text-xl font-semibold mb-2">
          8. How Can You Contact Us About This Notice?
          </h3>
          <p className="mb-4">
          Based on the application laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. You can update the personal information you have provided to us at this link: <Link to="/update-information" className="text-blue-600 hover:underline">
              https://fuktrond.se/update-information
            </Link> to request to delete your personal information please visit: <Link to="/contact" className="text-blue-600 hover:underline">
              https://fuktrond.se/contact
            </Link> We will delete your information as soon as it is possible. 

          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
