import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/LandingPageSidebar';
import HeroSection from '../Components/HeroSection';
import InfoSection from '../Components/InfoSection';
import { homeObjectOne, homeObjFour } from '../Components/InfoSection/Data'; // Update the import based on new structure
import Services from '../Components/Services';
import Footer from '../Components/Footer';
import Features from '../Components/Features';

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <HeroSection />
      {homeObjectOne.map((item, index) => (
        <InfoSection key={index} {...item} />
      ))}
      <Features />
      <Services />
      <InfoSection {...homeObjFour} />
      <Footer />
    </>
  );
};

export default LandingPage;
