import React, {useState} from "react";
import Video from "../../videos/Ny.mp4";
import { Button }from '../LandingPageButton/ButtonElements'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from "./HeroElements";
import { useNavigate } from 'react-router-dom'
import BgImage from '../../images/BgImage.jpeg' // use this image for background on smaller screens
const HeroSection = () => {

    const [hover, setHover] = useState(false)
    const navigate = useNavigate()

    const onHover = () => {
        setHover(!hover) 
    }

    const navigateToContact = () => { // Add this function to handle navigation
      navigate('/contact');
  };
  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>Vi hjälper dig bygga fuktsäkert</HeroH1>
        <HeroP>Kontaka oss för att ta reda på hur vårt system kan hjälpa dig och ditt team</HeroP>
        <HeroBtnWrapper>
        <Button onClick={navigateToContact} onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
            Skriv till oss {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
