import React from 'react'
import {Link as LinkS } from 'react-scroll';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SideBtnWrapp, SidebarLink, SidebarRoute, SidebarMenu } from './SidebarElements'
const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink to="om-appen" onClick={toggle}>
              Om appen
            </SidebarLink>
            <SidebarLink to="features" onClick={toggle}>
              funktioiner
            </SidebarLink>
            <SidebarLink to="services" onClick={toggle}>
              Processen
            </SidebarLink>
            <SidebarLink to="kontakta-oss" onClick={toggle}>
              Kontakta oss
            </SidebarLink>
            
          </SidebarMenu>
          <SideBtnWrapp>
            <SidebarRoute to="/login">
              Logga in
            </SidebarRoute>
          </SideBtnWrapp>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar