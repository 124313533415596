import React, { useEffect, useState } from "react";
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from "react-scroll";
import Logo from '../../images/WhiteLogo.svg';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink
} from "./NavbarElements";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const changeNav = () => {
    const scrollY = window.scrollY;
    // Adjusted logic to consider being at the top of the page
    if (scrollY > 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
      setActiveSection(''); // Ensure activeSection is cleared at the top
    }

    // Dynamically calculate positions with a slight adjustment for better accuracy
    const omOssPosition = document.getElementById('om-oss')?.offsetTop - 80;
    const featuresPosition = document.getElementById('features')?.offsetTop - 80;
    const servicesPosition = document.getElementById('services')?.offsetTop - 80;
    const kontaktPosition = document.getElementById('kontakta-oss')?.offsetTop - 80;
    
    if (scrollY >= omOssPosition && scrollY < featuresPosition) {
      setActiveSection('om-oss');
    } else if (scrollY >= featuresPosition && scrollY < servicesPosition) {
      setActiveSection('features');
    } else if (scrollY >= servicesPosition && scrollY < kontaktPosition) {
      setActiveSection('services');
    } else if (scrollY >= kontaktPosition) {
      setActiveSection('kontakta-oss');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => window.removeEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop({
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart"
    });
    setTimeout(() => {
      setScrollNav(false); // Manually reset navbar to transparent
      setActiveSection(''); // Ensure no section is marked as active, even 'om-oss'
    }, 10);
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <img src={Logo} alt="Logo" />
            Fuktrond.se
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="om-oss"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                className={activeSection === 'om-oss' ? 'active' : ''}
              >Om appen</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="features"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                className={activeSection === 'features' ? 'active' : ''}
              >Funktioner</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                className={activeSection === 'services' ? 'active' : ''}
              >Processen</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="kontakta-oss"
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                className={activeSection === 'kontakta-oss' ? 'active' : ''}
              >Kontakta oss</NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to="/login">Logga In</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;

