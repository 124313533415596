import React, { useState, useEffect, useRef } from 'react';
import newLogo from '../images/ProjectLogoThree.png';
import { useAuth } from '../Context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { db } from "../firebase";
import Logo from '../images/WhiteLogo.svg';

export default function Header({ onSearchChange }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref for the dropdown
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [userCompany, setUserCompany] = useState(null);
  
  const handleInputChange = (e) => {
    // Call the onSearchChange function with the new input value
    onSearchChange(e.target.value);
  };

  async function handleLogout() {
    try {
      await logout();
      navigate("/login");
    } catch {
      // Handle error case
    }
  }

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (isSidebarOpen && !event.target.classList.contains("sidebar")) {
      setIsSidebarOpen(false);
    }
  }

  useEffect(() => {
    // Attach event listener when dropdown opens or sidebar opens
    if (dropdownOpen || isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen, isSidebarOpen]);

  useEffect(() => {
    if (currentUser) {
      const docRef = db.collection("users").doc(currentUser.uid);
  
      docRef.get().then((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          const userCompany = userData.company; // Set user's company name
          
          // Fetch users with the same company name
          db.collection("users")
            .where("company", "==", userCompany)
            .get()
            .then((querySnapshot) => {
              const users = querySnapshot.docs
                .filter((doc) => doc.id !== currentUser.uid) // Filter out the current user
                .map((doc) => doc.data().name);
              setCompanyUsers(users);
            })
            .catch((error) => {
              console.error("Error fetching company users:", error);
            });
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.error("Error fetching user data:", error);
      });
    }
  }, [currentUser]);
  

  useEffect(() => {
    console.log("Current User Company:", userCompany);
    if (userCompany) {
      const unsubscribe = db.collection("users")
        .where("company", "==", userCompany)
        .onSnapshot(snapshot => {
          const users = snapshot.docs.map(doc => doc.data().name);
          setCompanyUsers(users);
        }, err => console.log(err));

      return () => unsubscribe();
    }
  }, [userCompany]);

  return (
    <header className="sticky top-0 z-50 flex items-center justify-between  bg-gradient-to-r from-[#2750E4] to-[#3C7AE9] shadow-md py-4 md:px-8">
    <div className="flex items-center">
      <MenuIcon className="text-white cursor-pointer mr-3" style={{ fontSize: 30 }} onClick={toggleSidebar} />
      <Link to="/">
        <img src={Logo} alt="Projektallians logo" className="h-14 w-22 md:h-10 mr-3" style={{ width: '33px' }} />
      </Link>
      <h1 className="text-white text-xl font-semibold hidden md:block">Fuktrond.se</h1>
    </div>

    {/* Adjust the search bar width to match the dashboard components */}
    <div className="mx-5 md:mx-20 flex flex-grow items-center px-2 py-0 bg-white text-gray-600 rounded-lg focus-within:shadow-md focus-within:bg-white ">
  <div className="relative w-full">
    <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    <input
      type="text"
      placeholder="Sök bland dina projekt"
      className="w-full pl-10 pr-4 py-2 rounded-lg bg-transparent outline-none text-base transition duration-150 ease-in-out"
      onChange={handleInputChange}
      onFocus={(e) => e.target.placeholder = ""}
      onBlur={(e) => e.target.placeholder = "Sök bland dina projekt"}
    />
  </div>
</div>

    <div className="flex items-center md:space-x-6">
      <span className="text-white">{currentUser && currentUser.displayName}</span>
      <div className="relative" ref={dropdownRef}>
        <PersonIcon className="text-white cursor-pointer" style={{ fontSize: 30 }} onClick={toggleDropdown} />
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
            <Link to="/update-profile" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
              <SettingsIcon className="mr-2" />
              Uppdatera profil
            </Link>
            <a href="#!" className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleLogout}>
              <ExitToAppIcon className="mr-2" />
              Logga ut
            </a>
          </div>
        )}
      </div>
    </div>

    {isSidebarOpen && (
      <div className="fixed top-0 left-0 h-screen w-80 bg-white shadow-lg z-50 sidebar">
        <h2 className="text-xl font-semibold p-4">Organisation</h2>
        <ul>
          {companyUsers.map((user, index) => (
            <li key={index} className="pl-6 py-2 border-b border-gray-200">{user}</li>
          ))}
        </ul>
      </div>
    )}
  </header>
  );
}





{/* 
import React, { useState } from 'react'
import logo from '../images/LogoIcon3.png'
import { useAuth } from '../Context/AuthContext'
import { Link, useNavigate} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person'

export default function Header() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handlelogout() {
    
    setError('');

    try {
      await logout();
      navigate("/login");
    } catch {
      setError('failed to log out');
    }
  }

  return (
    <header className="sticky top-0 py-4 px-4 md:px-8 bg-white shadow-md z-50 flex items-center justify-between">
      <div className="flex items-center">
      <MenuIcon />
        <Link to="/">
          <img src={logo} alt="Projektallians logo" className="h-8 md:h-12" />
        </Link>
        <h1 className="ml-4 text-gray-800 text-2xl font-semibold">Projektallians</h1>
      </div>

      <div className="relative mx-5 flex-grow md:flex">
        <input 
          type="text"
          placeholder="Sök bland dina projekt"
          className="w-full p-2 pl-10 text-base bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
        />
        <span className="absolute left-0 top-0 mt-2 ml-4 text-gray-400">
          🔍
        </span>
      </div>

      <div className="flex items-center space-x-4 md:space-x-6">
        <h2 className="hidden md:block text-gray-700">{currentUser && currentUser.displayName}</h2>
        <Link to="/update-profile" className="hidden md:block text-gray-600 hover:text-gray-800">Uppdatera profil</Link>
        <button 
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-full"
          onClick={handlelogout}
        >
          Logga ut
        </button>
      </div>
    </header>
  );
}

*/}




{/* <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
  <span>Download</span>
</button> */}

// export default function Header() {
//   const [ error ,setError] = useState("")
//   const { currentUser, logout } = useAuth();
//   const navigate = useNavigate();

//   async function handlelogout() {
//     setError('')

//     try{
//       await logout()  // This now refers to the logout function from useAuth
//       navigate("/login");
//     } catch{
//       setError('failed to log out')
//     }
//   }

//   return (
//     <header className='stickey py-5 px-8 z-50 flex items-center shadow-md bg-white'>
        
//         <button><img src={logo} alt='' className='mr-3 h-6 sm:h-9 ' /></button>
        
//         <h1 className='md:inline-flex ml-2 text-gray-700 text-2xl mr-4'>Projektallians</h1>

//         <div className='mx-5 flex flex-grow items-center p-5 py-2 bg-gray-100 text-gray-600 rounded-lg focus-within:text-gray-600 focus-within:shadow-md'>
            
//             <input type='text' placeholder='Sök bland dina projekt' className='flex-grow px-5 text-base bg-transparent outline-none' />
//         </div>
//         <div>
//         </div>
//         <div className='hidden md:inline-flex'>
//         <h2 className='mr-5 ml-5 '>{currentUser && currentUser.displayName}</h2>
//         <Link to="/update-profile" className='mr-5 ml-5 hidden md:inline-flex'>Uppdatera profil</Link>
//         <button className=' mr-5 ml-5 hidden md:inline-flex bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded-full inline-flex items-center' onClick={handlelogout}>
//           Logga ut
//         </button>
//         </div>
        
       
        
//     </header> 

        
    
//   )
// }