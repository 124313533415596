import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import firebase from "firebase/compat/app";
import Select from "react-select";

export default function CreateProjectPopUp({ visible, onClose, company }) {
  const [input, setInput] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [userUID, setUserUID] = useState(null);
  const [companyUsers, setCompanyUsers] = useState([]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserUID(user.uid);
      setInput(""); // Reset input on component mount
    }
  }, []);

  useEffect(() => {
    if (userUID && company) {
      db.collection("users")
        .where("company", "==", company)
        .get()
        .then((querySnapshot) => {
          const users = querySnapshot.docs
            .filter((doc) => doc.id !== userUID) // Exclude current user
            .map((doc) => ({ value: doc.id, label: doc.data().name }));
          setCompanyUsers(users);
        })
        .catch((error) => {
          console.error("Error fetching company users:", error);
        });
    }
  }, [userUID, company]);

  const createDocument = () => {
    console.log("Creating document...");

    if (!input || !userUID) return;

    const sharedWithUIDs = selectedCompanies.map((company) => company.value);

    const projectData = {
      fileName: input,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      sharedWith: sharedWithUIDs,
      ownerUID: userUID
    };

    db.collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .add(projectData)
      .then((docRef) => {
        console.log("Document successfully added with ID:", docRef.id);
        setProjectId(docRef.id);
        setInput("");
        setSelectedCompanies([]); // Clear selected companies
        onClose();
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  useEffect(() => {
    if (projectId) {
      console.log("New Project ID:", projectId);
    }
  }, [projectId]);

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-30 backdrop-blur-sm">
      <div className="bg-white p-6 rounded-lg shadow-xl w-96">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">
          Nytt projekt
        </h2>

        <Select
          isMulti
          options={companyUsers}
          value={selectedCompanies}
          onChange={(selectedOption) => setSelectedCompanies(selectedOption)}
          placeholder="Dela ditt projekt med..."
        />

        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          type="text"
          placeholder="Namnge ditt projekt"
          className="w-full p-2 mb-6 border rounded-md outline-none focus:ring-2 focus:ring-indigo-400"
          onKeyDown={(e) => e.key === "Enter" && createDocument()}
        />
        <div className="flex justify-end gap-4">
          <button
            className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            onClick={createDocument}
          >
            Skapa
          </button>
          <button
            onClick={onClose}
            className="flex items-center justify-center px-4 py-2 text-gray-700 border border-gray-300 text-sm font-medium rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            Avbryt
          </button>
        </div>
      </div>
    </div>
  );
}
