import styled from 'styled-components';

export const ServicesContainer = styled.div` 
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;

    @media screen and (max-width: 768px) { // Added 'px' to the media query
        height: 1100px;
    }

    @media screen and (max-width: 768px) {
        padding-top: 80px; /* Adjust as needed to compensate for the navbar height */
        min-height: auto; /* Allow container to expand based on content */
    }
`
export const ServicesWrapper = styled.div` 
    max-width: 1000px;
    margin: 0 auto;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center; 
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px; // Corrected 'paddding' to 'padding'
    }
`

export const ServiceCard = styled.div` 
    background: #fff;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    border-radius: 10px; 
    min-height: 340px; // Use min-height instead of max-height
    padding: 30px; 
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out; 
        cursor: pointer; 
    }

    @media screen and (max-width: 768px) {
        min-height: 40px; /* Adjust as needed for smaller screens */
    }
`


export const ServicesIcon = styled.img` 
    height: 160px; 
    width: 160px; 
    margin-bottom: 10px; 
`

export const ServicesH1 = styled.h1` 
    font-size: 2.5rem; 
    font-weight: 700; 
    color: #fff; 
    margin-bottom: 64px; 

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`

export const ServicesH2 = styled.h2` 
    font-size: 1rem; 
    font-weight: 700; 
    margin-bottom: 10px; 
`

export const ServicesP = styled.p` 
    font-size: 1rem; 
    text-align: center; 
`