import { useCollection } from "react-firebase-hooks/firestore";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase"; // Adjust the import path as per your directory structure
import useSharedProjects from "./useSharedProjects";

export const useUserDocs = () => {
  const userUID = auth.currentUser?.uid;

  // Query for the user's own projects
  const userProjectsQuery = db
    .collection("userDocs")
    .doc(userUID)
    .collection("docs")
    .orderBy("timestamp", "desc");

  // Perform the query and get the snapshot for the user's own projects
  const [userProjectsSnapshot, userProjectsLoading, userProjectsError] =
    useCollection(userProjectsQuery);

  // Fetch shared projects
  const [sharedProjects, sharedProjectsLoading, sharedProjectsError] =
    useSharedProjects(userUID);

  // Separate snapshots for user's own projects and shared projects
  const userOwnProjectsSnapshot = userProjectsSnapshot?.docs || [];
  const sharedProjectsSnapshot = sharedProjects.filter((project) =>
    project.sharedWith.includes(userUID)
  );

  // Combine loading and error states
  const isLoading = userProjectsLoading || sharedProjectsLoading;
  const error = userProjectsError || sharedProjectsError;

  return { userOwnProjectsSnapshot, sharedProjectsSnapshot, isLoading, error };
};
export const deleteProjectFromFirestore = async (projectId) => {
  const userUID = auth.currentUser?.uid;

  if (!userUID) {
    throw new Error("No authenticated user.");
  }

  try {
    // First, delete the project document from Firestore
    await db.collection("userDocs").doc(userUID).collection("docs").doc(projectId).delete();
    console.log("Project document deleted successfully");

    // Then, delete all associated files in Firebase Storage
    // Construct the path where the project's files are stored
    const projectFolderPath = `userDocs/${userUID}/${projectId}`;

    // List all files and directories under the project's folder
    const listAll = async (path) => {
      const filesToDelete = [];
      const listResult = await storage.ref(path).listAll();
      listResult.items.forEach((itemRef) => {
        filesToDelete.push(itemRef.delete()); // Schedule file for deletion
      });
      listResult.prefixes.forEach((prefix) => {
        filesToDelete.push(listAll(prefix.fullPath)); // Recursively list and schedule deletion for nested files/directories
      });
      return Promise.all(filesToDelete);
    };

    // Start deleting files and wait for completion
    await listAll(projectFolderPath);
    console.log("All project files deleted successfully");

  } catch (error) {
    console.error("Error deleting project:", error);
  }
};

export const fetchProjectById = async (projectId) => {
  console.log(`fetchProjectById - projectId: ${projectId}`);
  const userUID = auth.currentUser?.uid;

  if (!userUID) {
    throw new Error("No authenticated user.");
  }

  try {
    const docRef = db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId);

    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
      return docSnapshot.data();
    } else {
      console.error("Document does not exist!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching project:", error);
    return null;
  }
};

export const updateProjectFileNameInFirestore = async (
  ownerUID, // Add ownerUID as a parameter
  projectId,
  newFileName
) => {
  console.log(
    `Updating project name - projectId: ${projectId}, ownerUID: ${ownerUID}`
  );

  // Use the passed ownerUID instead of the current user's UID
  if (!ownerUID) {
    throw new Error("No owner UID provided.");
  }

  const projectRef = db
    .collection("userDocs")
    .doc(ownerUID)
    .collection("docs")
    .doc(projectId);

  return projectRef.update({
    fileName: newFileName,
  });
};

// firestoreQueries.js
export const updateProjectDataInFirestore = async (
  ownerUID, // Add this parameter
  projectId,
  projectData,
  documentId
) => {
  if (!ownerUID) {
    throw new Error("No owner UID provided.");
  }

  const projectRef = db
    .collection("userDocs")
    .doc(ownerUID) // Use this ownerUID
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId);

  return projectRef.update({
    ...projectData,
  });
};

export const uploadPdfToStorage = async (
  file,
  projectId,
  documentId,
  ownerUID
) => {
  if (!ownerUID) {
    throw new Error("No owner UID provided.");
  }

  // Constructing the path for the file in Firebase Storage
  const storageRef = storage.ref();
  const fileRef = storageRef.child(
    `userDocs/${ownerUID}/${projectId}/Documents/${documentId}/Files/${uuidv4()}-${
      file.name
    }`
  );

  // Uploading the file
  await fileRef.put(file);
  const downloadURL = await fileRef.getDownloadURL();

  // Saving the file metadata in Firestore
  await db
    .collection("userDocs")
    .doc(ownerUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("pdfFiles")
    .add({
      name: file.name,
      url: downloadURL,
    });

  return downloadURL;
};

export const uploadImageToStorage = async (
  imgDataUrl,
  projectId,
  documentId,
  originalFileName,
  pageNumber,
  ownerUID
) => {
  if (!ownerUID) {
    throw new Error("No owner UID provided.");
  }

  // Convert data URL to Blob
  const response = await fetch(imgDataUrl);
  const blob = await response.blob();

  // Retrieve original image dimensions
  const image = new Image();
  image.src = imgDataUrl;
  await new Promise((resolve) => {
    image.onload = () => resolve();
  });
  const originalWidth = image.naturalWidth;
  const originalHeight = image.naturalHeight;

  // Constructing the path for the image file in Firebase Storage
  const storageRef = storage.ref();
  const uniqueImageName = `${originalFileName}_Page${pageNumber}_${uuidv4()}.png`;
  const fileRef = storageRef.child(
    `userDocs/${ownerUID}/${projectId}/Documents/${documentId}/Files/Images/${uniqueImageName}`
  );

  // Uploading the file
  await fileRef.put(blob);
  const downloadURL = await fileRef.getDownloadURL();

  // Saving the file metadata in Firestore
  await db
    .collection("userDocs")
    .doc(ownerUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("imageFiles")
    .add({
      name: uniqueImageName,
      url: downloadURL,
      originalWidth,
      originalHeight,
      usedInReport: false,
    });

  // Return the image object
  return {
    name: uniqueImageName,
    url: downloadURL,
    originalWidth,
    originalHeight,
    usedInReport: false,
  };
};

export const fetchPdfsFromFirestore = async (projectId, documentId) => {
  const userUID = auth.currentUser?.uid;
  if (!userUID) {
    throw new Error("No authenticated user.");
  }

  // Fetching the file metadata from Firestore
  const pdfFilesRef = db
    .collection("userDocs")
    .doc(userUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("pdfFiles");

  const snapshot = await pdfFilesRef.get();
  return snapshot.docs.map((doc) => doc.data());
};

export const fetchImagesFromFirestore = async (
  projectId,
  documentId,
  userUID
) => {
  if (!userUID) {
    throw new Error("No user UID provided.");
  }

  const imageFilesRef = db
    .collection("userDocs")
    .doc(userUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("imageFiles");

  const snapshot = await imageFilesRef.get();
  return snapshot.docs.map((doc) => doc.data());
};

export const updateDocumentFileNameInFirestore = async (
  ownerUID, // Add ownerUID as a parameter
  projectId,
  documentId,
  newFileName
) => {
  console.log(`updateDocumentFileNameInFirestore - documentId: ${documentId}`);

  if (!ownerUID) {
    throw new Error("Owner UID is required.");
  }

  const documentRef = db
    .collection("userDocs")
    .doc(ownerUID) // Use ownerUID instead of the current user's UID
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId);

  return documentRef.update({
    fileName: newFileName,
  });
};

// firestoreQueries.js
export const fetchCommentsFromFirestore = async (
  projectId,
  documentId,
  userUID
) => {
  if (!userUID) throw new Error("User UID is not provided.");

  const titlePriority = {
    "Skydd av material och konstruktion": 1,
    "Läckage, nederbörd": 2,
    "Fukt i material": 3,
    "Uttorkning av betong": 4,
    "Tekniska system": 5,
    "Renhet på ytor": 6,
    "Kontroll av utförandet vid fuktkritiska konstruktioner enligt fuktplan och fuktsäkerhetsprojekteringen": 7,
    "Lufttäthet": 8,
    "Övrigt": 9,
  };

  try {
    const commentsRef = db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers");

    const commentsData = [];
    const commentsSnapshot = await commentsRef.get();

    for (const markerDoc of commentsSnapshot.docs) {
      const markerCommentsSnapshot = await commentsRef
        .doc(markerDoc.id)
        .collection("comments")
        .get();
      markerCommentsSnapshot.forEach((commentDoc) => {
        commentsData.push({
          ...commentDoc.data(),
          id: commentDoc.id,
          imageName: markerDoc.id, // assuming markerDoc.id is the imageName
        });
      });
    }

    // Sort comments by title using the titlePriority map
    commentsData.sort((a, b) => {
      const priorityA = titlePriority[a.title] || Number.MAX_SAFE_INTEGER;
      const priorityB = titlePriority[b.title] || Number.MAX_SAFE_INTEGER;
      return priorityA - priorityB;
    });

    return commentsData;
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
};


export const fetchPhotosFromFirestore = async (
  projectId,
  documentId,
  userUID
) => {
  if (!userUID) throw new Error("User UID is not provided.");

  try {
    const photosRef = db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers");

    const photosDataByImageName = {}; // Initialize an object to group photos by imageName
    const photosSnapshot = await photosRef.get();

    for (const markerDoc of photosSnapshot.docs) {
      const markerPhotosSnapshot = await photosRef
        .doc(markerDoc.id)
        .collection("photos")
        .get();

      if (!photosDataByImageName[markerDoc.id]) {
        photosDataByImageName[markerDoc.id] = {};
      }

      for (const photoDoc of markerPhotosSnapshot.docs) {
        const photoData = {
          ...photoDoc.data(),
          id: photoDoc.id,
          imageName: markerDoc.id,
        };

        const markerNumber = photoData.number;
        if (!photosDataByImageName[markerDoc.id][markerNumber]) {
          photosDataByImageName[markerDoc.id][markerNumber] = [];
        }
        photosDataByImageName[markerDoc.id][markerNumber].push(photoData);
      }
    }

    return photosDataByImageName;
  } catch (error) {
    console.error("Error fetching photos:", error);
    throw error;
  }
};

export const fetchMarkersFromFirestore = async (
  projectId,
  documentId,
  imageName,
  userUID
) => {
  try {
    if (!userUID) throw new Error("User UID is not provided.");

    const markersRef = db
      .collection("userDocs")
      .doc(userUID) // Use the provided UID
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers")
      .doc(imageName) // Use imageName to get specific markers
      .collection("individualMarkers");

    const markersSnapshot = await markersRef.get();
    const markersData = markersSnapshot.docs.map((markerDoc) => ({
      ...markerDoc.data(),
      id: markerDoc.id,
    }));

    return markersData;
  } catch (error) {
    console.error("Error fetching markers for image", imageName, ":", error);
    throw error;
  }
};

export const updateCommentDocument = async (
  projectId,
  documentId,
  imageName,
  number,
  title,
  status,
  commentText,
  markerColor,
  question,
  usedInReport,
  userUID
) => {
  if (!userUID) throw new Error("User UID is not provided.");

  // Assuming your comments are stored within the user's document hierarchy
  const commentDocRef = db
    .collection("userDocs")
    .doc(userUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("markers")
    .doc(imageName)
    .collection("comments")
    .doc(String(number));

  // Update the comment document
  return commentDocRef.update({
    title,
    status,
    commentText,
    color: markerColor,
    question,
    usedInReport,
  });
};

export const updatePhotoInFirestore = async (
  projectId,
  documentId,
  imageName,
  photoId,
  updates,
  userUID
) => {
  try {
    if (!userUID) {
      throw new Error("No authenticated user.");
    }

    const photoDocRef = db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers")
      .doc(imageName)
      .collection("photos")
      .doc(photoId);

    await photoDocRef.update(updates);

    console.log("Photo updated successfully");
  } catch (error) {
    console.error("Error updating photo in Firestore:", error);
    throw error;
  }
};

// firestoreQueries.js

export const updateUsedInReport = async (
  projectId,
  documentId,
  imageName,
  commentNumber,
  updates // This is an object with the fields you want to update
) => {
  const userUID = auth.currentUser?.uid;
  if (!userUID) {
    throw new Error("No authenticated user.");
  }

  const commentDocRef = db
    .collection("userDocs")
    .doc(userUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("markers")
    .doc(imageName)
    .collection("comments")
    .doc(String(commentNumber));

  try {
    await commentDocRef.update(updates);
    console.log(
      `Comment ${commentNumber} in image ${imageName} updated successfully with`,
      updates
    );
  } catch (error) {
    console.error("Error updating comment in Firestore:", error);
    throw error;
  }
};

export const addCommentToUsedInReport = async (
  projectId,
  documentId,
  imageName,
  number,
  commentData // This includes all the comment details and associated photos
) => {
  const userUID = auth.currentUser?.uid;
  if (!userUID) {
    throw new Error("No authenticated user.");
  }

  // Reference to the marker document
  const markerDocRef = db
    .collection("userDocs")
    .doc(userUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("markers")
    .doc(imageName);

  // Reference to the 'commentsUsedInReport' collection, sorted by image name and comment number
  const commentsUsedInReportRef = markerDocRef
    .collection("commentsUsedInReport")
    .doc(String(number));

  // Add the comment data to the 'commentsUsedInReport' collection
  await commentsUsedInReportRef.set({
    ...commentData, // Spread the comment data to include all its properties
    imageName, // Include the image name for sorting
    number, // Include the comment number for sorting
    usedInReport: true, // Flag to indicate the comment is used in a report
  });
};

export const fetchCommentsUsedInReport = async (
  projectId,
  documentId,
  imageName
) => {
  const userUID = auth.currentUser?.uid;
  if (!userUID) {
    throw new Error("No authenticated user.");
  }

  // Reference to the marker document
  const markerDocRef = db
    .collection("userDocs")
    .doc(userUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("markers")
    .doc(imageName);

  // Reference to the 'commentsUsedInReport' subcollection
  const commentsUsedInReportRef = markerDocRef.collection(
    "commentsUsedInReport"
  );

  try {
    // Fetch all comments from the 'commentsUsedInReport' collection
    const snapshot = await commentsUsedInReportRef.get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching comments used in report:", error);
    throw error; // Re-throw the error to be caught by the calling component
  }
};

const documentKey = (projectId, documentId) =>
  `comments_${projectId}_${documentId}`;
const imageKey = (projectId, documentId) => `images_${projectId}_${documentId}`;

export function saveImagesToLocalStorage(projectId, documentId, images) {
  localStorage.setItem(imageKey(projectId, documentId), JSON.stringify(images));
}

export function loadImagesFromLocalStorage(projectId, documentId) {
  try {
    const savedImages = localStorage.getItem(imageKey(projectId, documentId));
    return savedImages ? JSON.parse(savedImages) : [];
  } catch (error) {
    console.error("Error loading images from local storage:", error);
    return [];
  }
}

export function saveCommentsToLocalStorage(projectId, documentId, comments) {
  localStorage.setItem(
    documentKey(projectId, documentId),
    JSON.stringify(comments)
  );
}

export function loadCommentsFromLocalStorage(projectId, documentId) {
  try {
    const savedComments = localStorage.getItem(
      documentKey(projectId, documentId)
    );
    return savedComments ? JSON.parse(savedComments) : null;
  } catch (error) {
    console.error("Error loading comments from local storage:", error);
    return null;
  }
}

export function saveTitleCountsAndLastTitleNum(
  projectId,
  documentId,
  titleCounts,
  lastTitleNum
) {
  const key = `titleData_${projectId}_${documentId}`;
  const data = { titleCounts, lastTitleNum };
  localStorage.setItem(key, JSON.stringify(data));
}

export function loadTitleCountsAndLastTitleNum(projectId, documentId) {
  const key = `titleData_${projectId}_${documentId}`;
  const savedData = localStorage.getItem(key);
  return savedData
    ? JSON.parse(savedData)
    : { titleCounts: {}, lastTitleNum: 0 };
}

// In your Firestore queries file
// In firestoreQueries.js
export const updateCommentUsedInReport = async (
  projectId,
  documentId,
  imageName,
  commentNumber,
  usedInReport,
  uidToUse
) => {
  if (!uidToUse) {
    throw new Error("User UID is not provided.");
  }

  const commentRef = db
    .collection("userDocs")
    .doc(uidToUse) // Use uidToUse here
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("markers")
    .doc(imageName)
    .collection("comments")
    .doc(String(commentNumber));

  try {
    await commentRef.update({
      usedInReport: usedInReport,
    });
    console.log(
      `Comment ${commentNumber} in image ${imageName} updated successfully with usedInReport: ${usedInReport}`
    );
  } catch (error) {
    console.error("Error updating comment in Firestore:", error);
    throw error;
  }
};

export const updateMarkerColor = async (
  projectId,
  documentId,
  imageName,
  number,
  color,
  uidToUse
) => {
  if (!uidToUse) {
    throw new Error("User UID is not provided.");
  }

  const specificMarkerRef = db
    .collection("userDocs")
    .doc(uidToUse)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("markers")
    .doc(imageName)
    .collection("individualMarkers")
    .doc(String(number));

  try {
    await specificMarkerRef.update({ color });
    console.log(`Marker color updated to ${color} for marker number ${number}`);
  } catch (error) {
    console.error("Error updating marker color:", error);
    throw error;
  }
};

export const storeSignatureAndLogoInStorage = async (
  signatureFile,
  logotypeFile,
  ownerUID
) => {
  if (!ownerUID) {
    throw new Error("No owner UID provided.");
  }

  const uploadImage = async (file, path) => {
    const fileRef = storage.ref().child(path);
    await fileRef.put(file);
    return await fileRef.getDownloadURL();
  };

  let signatureUrl, logotypeUrl;
  let signatureFileName, logotypeFileName;

  if (signatureFile) {
    const signaturePath = `userDocs/${ownerUID}/SignatureAndLogo/${uuidv4()}-${signatureFile.name}`;
    signatureUrl = await uploadImage(signatureFile, signaturePath);
    signatureFileName = signatureFile.name; // Capture the file name
  }

  if (logotypeFile) {
    const logotypePath = `userDocs/${ownerUID}/SignatureAndLogo/${uuidv4()}-${logotypeFile.name}`;
    logotypeUrl = await uploadImage(logotypeFile, logotypePath);
    logotypeFileName = logotypeFile.name; // Capture the file name
  }

  // Save URLs and file names to Firestore
  const docRef = db
    .collection("userDocs")
    .doc(ownerUID)
    .collection("SignatureAndLogo")
    .doc("userData");

  await docRef.set({
    signatureUrl, 
    logotypeUrl,
    signatureFileName, // Save the file name
    logotypeFileName // Save the file name
  }, { merge: true });

  return { signatureUrl, logotypeUrl, signatureFileName, logotypeFileName };
};

export const StoreSignatureAndLogoForDocumentInStorage = async (
  signatureImage,
  logotypeImage,
  ownerUID,
  projectId,
  documentId,
  existingSignatureUrl = null,
  existingLogotypeUrl = null
) => {
  if (!ownerUID) {
    throw new Error("No owner UID provided.");
  }

  const uploadImage = async (image, path) => {
    if (!image) return existingSignatureUrl || existingLogotypeUrl; // Return existing URL if no image provided
    const imageRef = storage.ref().child(path);
    await imageRef.put(image); // Upload the image
    return await imageRef.getDownloadURL(); // Get and return the image URL
  };

  // Use existing URLs if provided, otherwise upload new images and get URLs
  let signatureURL = existingSignatureUrl;
  if (!signatureURL && signatureImage) {
    signatureURL = await uploadImage(
      signatureImage,
      `userDocs/${ownerUID}/${projectId}/Documents/${documentId}/signature.jpg`
    );
  }

  let logotypeURL = existingLogotypeUrl;
  if (!logotypeURL && logotypeImage) {
    logotypeURL = await uploadImage(
      logotypeImage,
      `userDocs/${ownerUID}/${projectId}/Documents/${documentId}/logotype.jpg`
    );
  }

  // Prepare the data update object, including only the URLs that are not null
  const updates = {};
  if (signatureURL) updates.signatureURL = signatureURL;
  if (logotypeURL) updates.logotypeURL = logotypeURL;

  // Update the Firestore document with the image URLs
  const projectRef = db
    .collection("userDocs")
    .doc(ownerUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId);

  return projectRef.update(updates);
};

export const fetchCoverPhotosFromFirestore = async (
  projectId,
  documentId,
  effectiveUID
) => {
  const coverPhotosRef = db
    .collection("userDocs")
    .doc(effectiveUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("CoverPhotos");

  try {
    const snapshot = await coverPhotosRef.orderBy("timestamp", "desc").get();
    const coverPhotos = [];
    snapshot.forEach((doc) => coverPhotos.push({ id: doc.id, ...doc.data() }));
    return coverPhotos;
  } catch (error) {
    console.error("Error fetching cover photos: ", error);
    throw error;
  }
};

export const updateCoverPhotoInReport = async (
  projectId,
  documentId,
  selectedCoverPhotoId,
  effectiveUID,
  coverPhotos
) => {
  const coverPhotosRef = db
    .collection("userDocs")
    .doc(effectiveUID)
    .collection("docs")
    .doc(projectId)
    .collection("Documents")
    .doc(documentId)
    .collection("CoverPhotos");

  // Start a batch operation
  const batch = db.batch();

  coverPhotos.forEach((photo) => {
    const photoRef = coverPhotosRef.doc(photo.id);
    if (photo.id === selectedCoverPhotoId) {
      batch.update(photoRef, { usedInReport: true });
    } else if (photo.usedInReport) { // Only update if usedInReport is true to minimize writes
      batch.update(photoRef, { usedInReport: false });
    }
  });

  try {
    await batch.commit(); // Commit the batch
    console.log("Successfully updated cover photos' usedInReport status");
  } catch (error) {
    console.error("Error updating cover photos' usedInReport status: ", error);
    throw error;
  }
};

export const deleteDocumentFromFirestore = async (projectId, documentId) => {
  const userUID = auth.currentUser?.uid;

  if (!userUID) {
    throw new Error("No authenticated user.");
  }

  try {
    // Delete the document from Firestore
    await db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents") // Make sure this matches your actual collection name for documents
      .doc(documentId)
      .delete();
    console.log("Document deleted successfully from Firestore");

    // Construct the path where the document's PDF files are stored
    const documentFilesPath = `userDocs/${userUID}/${projectId}/Documents/${documentId}/Files`;

    // Delete the PDF files from Firebase Storage
    const deleteFiles = async (path) => {
      const listResult = await storage.ref(path).listAll();
      listResult.items.forEach((fileRef) => {
        fileRef.delete().then(() => {
          console.log(`File ${fileRef.name} deleted successfully`);
        }).catch((error) => {
          console.error("Error deleting file:", error);
        });
      });
    };

    // Call deleteFiles function to delete all files under the document's path
    await deleteFiles(documentFilesPath);
    console.log("All files for the document deleted successfully from Firebase Storage");

  } catch (error) {
    console.error("Error deleting document and its files:", error);
    throw error; // Re-throw the error if needed
  }
};

export const deleteFloorPlanImage = async (projectId, documentId, imageName, ownerUID) => {
  try {
    // Delete from Firebase Storage
    const storageRef = storage.ref();
    const imagePath = `userDocs/${ownerUID}/${projectId}/Documents/${documentId}/Files/Images/${imageName}`;
    const imageRef = storageRef.child(imagePath);
    await imageRef.delete();

    // Delete metadata from Firestore
    const imageFilesRef = db.collection("userDocs")
                             .doc(ownerUID)
                             .collection("docs")
                             .doc(projectId)
                             .collection("Documents")
                             .doc(documentId)
                             .collection("imageFiles");

    const snapshot = await imageFilesRef.where("name", "==", imageName).get();
    snapshot.forEach(doc => doc.ref.delete());

    console.log("Floor-plan image and metadata successfully deleted");
  } catch (error) {
    console.error("Error deleting floor-plan image:", error);
  }
};

// firestoreQueries.js
export const deleteComment = async (projectId, documentId, imageName, commentId, userUID) => {
  if (!userUID) throw new Error("User UID is not provided.");

  try {
    await db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers")
      .doc(imageName)
      .collection("comments")
      .doc(commentId)
      .delete();

    console.log("Comment successfully deleted");
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};

// Add this function to firestoreQueries.js or wherever you manage Firestore operations
export const deleteMarker = async (projectId, documentId, imageName, commentId, userUID) => {
  try {
    await db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers")
      .doc(imageName) // Assuming markers are stored under an image/document
      .collection("individualMarkers") // Assuming each marker is stored here
      .doc(commentId) // Assuming the marker's ID matches the comment's ID
      .delete();

    console.log("Marker successfully deleted");
  } catch (error) {
    console.error("Error deleting marker:", error);
    throw error;
  }
};

export const deletePhoto = async (projectId, documentId, imageName, photoId, userUID) => {
  if (!userUID) throw new Error("User UID is not provided.");

  try {
    await db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers")
      .doc(imageName)
      .collection("photos")
      .doc(photoId)
      .delete();

    console.log("Photo successfully deleted");
  } catch (error) {
    console.error("Error deleting photo:", error);
    throw error;
  }
};

// firestoreQueries.js

export const deleteCoverPhoto = async (projectId, documentId, photoId, userUID) => {
  if (!userUID) throw new Error("User UID is not provided.");

  try {
    await db
      .collection("userDocs")
      .doc(userUID)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("CoverPhotos")
      .doc(photoId)
      .delete();

    console.log("Cover photo successfully deleted");
  } catch (error) {
    console.error("Error deleting cover photo:", error);
    throw error;
  }
};

// Function to delete a document and its subcollections (imageFiles and pdfFiles)
export const deleteDocumentWithSubcollections = async (projectId, documentId) => {
  const userUID = auth.currentUser?.uid; // Ensure you have authenticated user UID
  const docRef = db.collection("userDocs").doc(userUID).collection("docs").doc(projectId).collection("Documents").doc(documentId);

  // Function to delete all documents within a subcollection
  const deleteSubcollection = async (parentRef, subcollectionName) => {
    const subcollectionRef = parentRef.collection(subcollectionName);
    const snapshot = await subcollectionRef.get();
    const deletePromises = snapshot.docs.map((doc) => doc.ref.delete());
    await Promise.all(deletePromises);
    console.log(`All documents in ${subcollectionName} deleted`);
  };

  try {
    // Delete documents in subcollections first
    await deleteSubcollection(docRef, "imageFiles");
    await deleteSubcollection(docRef, "pdfFiles");

    // Then delete the main document
    await docRef.delete();
    console.log("Document and its subcollections deleted successfully");
  } catch (error) {
    console.error("Error deleting document and its subcollections:", error);
    throw error;
  }
};



export const createMessageDocument = async (name, email, message) => {
  try {
    const docRef = await db.collection("Messages").add({
      name: name,
      email: email,
      message: message,
      timestamp: new Date(), // Add a timestamp to sort or query messages later
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};
