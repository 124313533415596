import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import firebase from 'firebase/compat/app';


export default function CreateDocumentPopUp({ visible, onClose, projectId, ownerUID, documents }) {
  const [input, setInput] = useState('');
  const [documentId, setDocumentId] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState('');

  const fetchFloorPlanImages = async (selectedDocId) => {
    if (!selectedDocId) return [];
    try {
      const uidToUse = ownerUID || auth.currentUser?.uid;
      const imageFilesRef = db.collection('userDocs').doc(uidToUse).collection('docs').doc(projectId).collection('Documents').doc(selectedDocId).collection('imageFiles');
      const snapshot = await imageFilesRef.get();
      return snapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error('Error fetching floor-plan images:', error);
      return [];
    }
  };

  const fetchGrundinfoData = async (selectedDocId) => {
    if (!selectedDocId) return null;
    try {
      const uidToUse = ownerUID || auth.currentUser?.uid;
      const grundinfoRef = db.collection('userDocs').doc(uidToUse).collection('docs').doc(projectId).collection('Documents').doc(selectedDocId);
      const grundinfoSnapshot = await grundinfoRef.get();
      if (grundinfoSnapshot.exists) {
        const data = grundinfoSnapshot.data();
        delete data.fileName; 
        delete data.timestamp; 
        return data;
      }
      return null;
    } catch (error) {
      console.error('Error fetching grundinfo data:', error);
      return null;
    }
  };



  const fetchComments = async (selectedDocId) => {
    if (!selectedDocId) return [];
    try {
      const uidToUse = ownerUID || auth.currentUser?.uid;
      const commentsRef = db.collection('userDocs').doc(uidToUse).collection('docs').doc(projectId).collection('Documents').doc(selectedDocId).collection('markers');
      const commentsSnapshot = await commentsRef.get();
      let commentsData = [];
      for (const markerDoc of commentsSnapshot.docs) {
        const markerCommentsSnapshot = await commentsRef.doc(markerDoc.id).collection('comments').get();
        markerCommentsSnapshot.forEach((commentDoc) => {
          commentsData.push({
            ...commentDoc.data(),
            id: commentDoc.id,
            imageName: markerDoc.id,
          });
        });
      }
      return commentsData;
    } catch (error) {
      console.error('Error fetching comments:', error);
      return [];
    }
  };

  const fetchPhotosData = async (selectedDocId) => {
    if (!selectedDocId) return {};
    try {
      const uidToUse = ownerUID || auth.currentUser?.uid;
      const photosRef = db.collection('userDocs').doc(uidToUse).collection('docs').doc(projectId).collection('Documents').doc(selectedDocId).collection('markers');
      const photosSnapshot = await photosRef.get();
  
      const photosData = {};
      for (const markerDoc of photosSnapshot.docs) {
        const markerPhotosSnapshot = await photosRef.doc(markerDoc.id).collection('photos').get();
        photosData[markerDoc.id] = markerPhotosSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      }
      return photosData;
    } catch (error) {
      console.error('Error fetching photos:', error);
      return {};
    }
  };
  
  const fetchMarkersData = async (selectedDocId) => {
    if (!selectedDocId) return {};
    try {
      const uidToUse = ownerUID || auth.currentUser?.uid;
      const markersRef = db.collection('userDocs').doc(uidToUse).collection('docs').doc(projectId).collection('Documents').doc(selectedDocId).collection('markers');
      const markersSnapshot = await markersRef.get();
  
      const markersData = {};
      for (const markerDoc of markersSnapshot.docs) {
        const individualMarkersSnapshot = await markersRef.doc(markerDoc.id).collection('individualMarkers').get();
        markersData[markerDoc.id] = {};
        individualMarkersSnapshot.forEach(indMarkerDoc => {
          markersData[markerDoc.id][indMarkerDoc.id] = indMarkerDoc.data();
        });
      }
      return markersData;
    } catch (error) {
      console.error('Error fetching markers:', error);
      return {};
    }
  };
  
  

  const createDocument = async () => {
    if (!input) return;
    const uidToUse = ownerUID || auth.currentUser?.uid;
  
    if (uidToUse) {
      // Fetch data from the selected document
      const grundinfoData = await fetchGrundinfoData(selectedDocument);
      const floorPlanImages = await fetchFloorPlanImages(selectedDocument);
      const comments = await fetchComments(selectedDocument);
      const photosData = await fetchPhotosData(selectedDocument);
      const markersData = await fetchMarkersData(selectedDocument);
  
      // Create a new document
      const docRef = db.collection('userDocs').doc(uidToUse).collection('docs').doc(projectId).collection('Documents').doc();
      await docRef.set({
        fileName: input,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        ...grundinfoData,
      });
  
      // Copy floor plan images to the new document, setting UsedInReport to false
      for (const image of floorPlanImages) {
        const modifiedImage = {
          ...image,
          usedInReport: false, // Ensure UsedInReport is set to false for the new document
        };
        await docRef.collection('imageFiles').add(modifiedImage);
      }
  
      // Copy only relevant comments to the new document, setting UsedInReport to false
      for (const comment of comments) {
        if (comment.Relevant) {
          const modifiedComment = {
            ...comment,
            usedInReport: false, // Ensure UsedInReport is set to false for the new document
          };
          const commentRef = docRef.collection('markers').doc(comment.imageName).collection('comments').doc(comment.id);
          await commentRef.set(modifiedComment);
        }
      }
  
      // Copy photos to the new document
      for (const imageName in photosData) {
        const photos = photosData[imageName];
        for (const photo of photos) {
          if (photo.Relevant) {
            await docRef.collection('markers').doc(imageName).collection('photos').add(photo);
          }
        }
      }
      
      // Copy only relevant markers and individual markers to the new document
      for (const markerId in markersData) {
        const marker = markersData[markerId];
        let hasRelevantMarkers = false;
        const relevantIndividualMarkers = {};
  
        for (const indMarkerId in marker) {
          const individualMarker = marker[indMarkerId];
          if (individualMarker.Relevant) {
            hasRelevantMarkers = true;
            relevantIndividualMarkers[indMarkerId] = individualMarker;
          }
        }
  
        if (hasRelevantMarkers) {
          const newMarkerRef = docRef.collection('markers').doc(markerId);
          await newMarkerRef.set(marker);
  
          for (const indMarkerId in relevantIndividualMarkers) {
            const individualMarker = relevantIndividualMarkers[indMarkerId];
            await newMarkerRef.collection('individualMarkers').doc(indMarkerId).set(individualMarker);
          }
        }
      }
  
      // Set document ID and reset input
      setDocumentId(docRef.id);
      setInput('');
      onClose();
    }
  };
  
  
  
  
  
  

  useEffect(() => {
    if (documentId) {
      console.log('New Document ID:', documentId);
    }
  }, [documentId]);

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-30 backdrop-blur-sm">
      <div className="bg-white p-6 rounded-lg shadow-xl w-96">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Ny fuktrond</h2>
        <div className="mb-4">
          <label htmlFor="document-select" className="block text-sm font-medium text-gray-700">Kopiera åtgärdspunkter från tidigare fuktrond</label>
          <select
            id="document-select"
            value={selectedDocument}
            onChange={(e) => setSelectedDocument(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="">Välj dokument</option>
            {documents.map(doc => (
              <option key={doc.id} value={doc.id}>{doc.fileName}</option>
            ))}
          </select>
        </div>

        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          type="text"
          placeholder="Namnge din fuktrond"
          className="w-full p-2 mb-6 border rounded-md outline-none focus:ring-2 focus:ring-indigo-400"
          onKeyDown={(e) => e.key === 'Enter' && createDocument()}
        />

        <div className="flex justify-end gap-4">
          <button
            className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            onClick={createDocument}
          >
            Skapa
          </button>
          <button
            onClick={onClose}
            className="flex items-center justify-center px-4 py-2 text-gray-700 border border-gray-300 text-sm font-medium rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            Avbryt
          </button>
        </div>
      </div>
    </div>
  );
}