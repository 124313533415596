import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer` 
    background-color: #101522; // Dark background for contrast
    padding: 4rem 0 2rem 0; // Increased padding for better spacing
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


export const FooterWrap = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    max-width: 1200px; 
    width: 100%;
    text-align: center; // Center align the text
`;


export const FooterLinksContainer = styled.div` 
    display: flex;
    justify-content: center;
    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;
    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const FooterLinksItem = styled.div` 
    display: flex; 
    flex-direction: column; 
    align-items: flex-end;
    margin: 0 60px; // Adjusted for better spacing
    text-align: left; 
    color: #fff; 

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const FooterLinkTitle = styled.h2` // Changed to h2 for semantics
    font-size: 16px; 
    margin-bottom: 16px; 
    color: #fff;
`;

export const LinkContainer = styled.div`
    justify-content: start;
    align-items: start; 
`;

export const FooterLink = styled(Link)` 
    color: #fff; 
    text-decoration: none; 
    margin-bottom: 0.5rem; 
    flex-start;
    font-size: 14px; 
    margin-right: 65px; 

    &:hover {
        color: #4b59f7; // Adjust for a more subtle hover effect
        transition: 0.3s ease-out; 
    }
`;

export const Social = styled.section` 
    max-width: 1000px;
    width: 100%;
`;

export const MediaContainer = styled.div` 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin: 40px auto 0 auto; 

    @media screen and (max-width: 820px) {
        flex-direction: column; 
    }
`;

export const SocialLogo = styled(Link)` 
color: #fff;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-left: 24px;
font-weight: bold;
text-decoration: none;
font-family: 'Encode Sans Expanded', sans-serif;

img {
    width: 30px; /* Reduced from 40px to make the logo smaller */
    margin-right: 10px;
}
`;

export const WebsiteRights = styled.small` 
    color: #fff;
    margin-top: 1rem; // Added margin for spacing in mobile view
`;

export const TechtaleLogo = styled.div`
    display: flex;
    color: white;
    align-items: center;
    img {
        width: 180px; // Adjusted width for visibility
        margin-left: 10px;
    }
`;
