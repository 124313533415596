import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import add from "../images/add.png";
import folder from "../images/folder.png";
import CreateProjectPopUp from "../Components/CreateProjectPopUp";
import {
  useUserDocs,
  deleteProjectFromFirestore,
} from "../Context/firestoreQueries";
import Options from "../images/Options.png";
import ProjectPopup from "../Components/ProjectPopup";
import { useNavigate } from "react-router-dom";
import YellowFolder from "../images/YellowFolder.png";
import { useAuth } from "../Context/AuthContext";
import { db } from "../firebase";

export default function Dashboard() {
  const [showCreate, setShowCreate] = useState(false);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const { userOwnProjectsSnapshot, sharedProjectsSnapshot, isLoading, error } =
    useUserDocs();
  const navigate = useNavigate();
  const [company, setCompany] = useState("");
  const { currentUser } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");


  const handleSearchChange = (query) => {
    setSearchQuery(query.toLowerCase());
  };
  
  // Step 3: Filter projects using the search query
  const filteredUserProjects = userOwnProjectsSnapshot.filter((project) =>
    project.data().fileName.toLowerCase().includes(searchQuery)
  );
  const filteredSharedProjects = sharedProjectsSnapshot.filter((project) =>
    project.fileName.toLowerCase().includes(searchQuery)
  );
  


  const handleProjectClick = (project) => {
    console.log("Navigating with ownerUID:", project.ownerUID);
    const isSharedProject = sharedProjectsSnapshot.some(p => p.id === project.id);
    if (isSharedProject) {
      navigate(`/project/${project.id}`, { 
        state: { 
          ownerUID: project.ownerUID, 
          company: company 
        } 
        
      });
    } else {
      navigate(`/project/${project.id}`, { state: { company: company } });
    }
  };

  
  const handleOnClose = () => setShowCreate(false);

  const handleOpenPopup = (e, project) => {
    e.stopPropagation();
    setSelectedProject(project);
    setPopupVisibility(true);
  };

  const handleClosePopup = () => {
    setSelectedProject(null);
    setPopupVisibility(false);
  };

  const handleDeleteProject = (project) => {
    if (window.confirm("Är du säker på att du vill radera detta projekt?")) {
      deleteProjectFromFirestore(project.id);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const docRef = db.collection("users").doc(currentUser.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setCompany(doc.data().company);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [currentUser]);

  if (isLoading) return <p>Loading projects...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="bg-[#F3F4F8] min-h-screen flex flex-col">
      <Header onSearchChange={handleSearchChange}/>

      <section className="mt-10 mb-16">
        <div className="max-w-3xl mx-auto px-4 md:px-6">
          <h2 className="text-gray-800 text-xl font-semibold mb-6">
            Skapa nytt projekt
          </h2>
          <div className="mb-5">
            <div
              onClick={() => setShowCreate(true)}
              className="relative h-52 w-40 border-2 rounded-md cursor-pointer flex justify-center items-center hover:border-blue-700 transition duration-300"
            >
              <img src={add} alt="Add new" />
            </div>
            <p className="mt-4 font-medium text-gray-800">Nytt</p>
          </div>
        </div>
      </section>

{/* Section for user's own projects */}
<section className="bg-white py-8 shadow-md flex-grow">
  <div className="max-w-3xl mx-auto px-4 md:px-6 text-sm text-gray-700">
    <div className="flex items-center justify-between mb-6">
      <h2 className="text-gray-800 text-xl font-semibold">Mina Projekt</h2>
      <div className="flex items-center space-x-4">
        <img src={folder} alt="Folder icon" className="w-6 h-6" />
      </div>
    </div>

    {filteredUserProjects.length > 0 ? (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    {filteredUserProjects.map((doc) => {
      const projectData = doc.data();
      // Check if timestamp exists before calling toDate()
      const formattedDate = projectData.timestamp ? projectData.timestamp.toDate().toLocaleDateString("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }) : "Datum saknas";

      return (
        <div
          key={doc.id}
          className="relative border rounded-lg p-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-col space-y-2"
          onClick={() => handleProjectClick(doc)}
        >
          <div className="flex items-center space-x-2">
            <img src={YellowFolder} alt="Folder icon" className="w-6 h-6" />
            <h3 className="text-lg font-medium">{projectData.fileName}</h3>
          </div>
          <p className="text-gray-600">
            <span className="text-gray-500">Datum: </span>{formattedDate}
          </p>
          <button
            onClick={(e) => handleOpenPopup(e, doc)}
            className="absolute top-4 right-4 p-2 rounded hover:bg-gray-200 transition-colors duration-300"
          >
            <img src={Options} alt="Options" className="w-7 h-7" />
          </button>
          {selectedProject?.id === doc.id && (
            <ProjectPopup
              visible={isPopupVisible}
              onClose={handleClosePopup}
              project={selectedProject}
              onDelete={handleDeleteProject}
              className="absolute bottom-full right-0 z-50"
            />
          )}
        </div>
      );
    })}
  </div>
) : (
  <p>Du har inga projekt ännu.</p>
)}

  </div>
</section>


   {/* Section for shared projects */}
<section className="bg-white py-8 shadow-md flex-grow">
  <div className="max-w-3xl mx-auto px-4 md:px-6 text-sm text-gray-700">
    <div className="flex items-center justify-between mb-6">
      <h2 className="text-gray-800 text-xl font-semibold">Delade Projekt</h2>
      <div className="flex items-center space-x-4">
        <img src={folder} alt="Folder icon" className="w-6 h-6" />
      </div>
    </div>

    {filteredSharedProjects.length > 0 ? (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredSharedProjects.map((project) => (
          <div
            key={project.id}
            className="relative border rounded-lg p-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-col space-y-2"
            onClick={() => handleProjectClick(project)}
          >
            <div className="flex items-center space-x-2">
              <img src={YellowFolder} alt="Folder icon" className="w-6 h-6" />
              <h3 className="text-lg font-medium">{project.fileName}</h3>
            </div>
            <p className="text-gray-600">
              <span className="text-gray-500">Datum: </span>
              {new Date(project.timestamp.seconds * 1000).toLocaleDateString("sv-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </p>
            <button
              onClick={(e) => handleOpenPopup(e, project)}
              className="absolute top-4 right-4 p-2 rounded hover:bg-gray-200 transition-colors duration-300"
            >
              <img src={Options} alt="Options" className="w-7 h-7" />
            </button>
          </div>
        ))}
      </div>
    ) : (
      <p>Du har inga delade projekt ännu.</p>
    )}
  </div>
</section>


      <CreateProjectPopUp
        onClose={handleOnClose}
        visible={showCreate}
        company={company}
      />
    </div>
  );
}

// import React, { useState } from 'react'
// import Header from '../Components/Header'
// import add from '../images/add.png'
// import folder from '../images/folder.png'
// import CreateProjectPopUp from '../Components/CreateProjectPopUp'

// export default function Dashboard() {

//   const [showCreeate, setShowCreate] = useState(false)
//   const handleOnClose = () => setShowCreate(false)

//   return (
//     <div>
//     <Header />

//     <section className='bg-[#F8F9FA] pb-10 mt-1'>
//       <div className='max-w-3xl mx-auto'>
//        <div className='py-6'>
//         <h2 className='text-gray-700'>Skapa nytt projekt</h2>
//        </div>
//        <div>
//         <div onClick={() => setShowCreate(true)} className='relative h-52 w-40 border-2 cursor-pointer flex justify-center items-center hover:border-blue-700'>
//           <img src={add} alt='' />
//         </div>
//         <p className='ml-2  mt- font-semibold text-sm text text-gray-700'>Nytt</p>
//        </div>
//       </div>
//     </section>
//     <section className='bg-white px-10 md:px-0'>
//       <div className='max-w-3xl  mx-auto py-8 text-sm text-gray-700'>
//         <div className='flex items-center justify-between pb-5'>
//           <h2 className='=font-medium flex-grow'>Mina Projekt</h2>
//           <p className='mr-12'>Datum</p>
//           <img src={folder} alt='' />
//         </div>
//       </div>
//     </section>
//     <CreateProjectPopUp onClose={handleOnClose} visable={showCreeate}/>
//     </div>
//   )
// }
