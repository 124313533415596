import React, { useRef, useState } from "react";
import { useAuth } from "../Context/AuthContext";
import logo2 from "../images/projektalliansLogo3.png";
import newLogo from "../images/ProjectLogoThree.png";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/WhiteLogo.svg";

export default function Login() {
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const result = await login(
        emailRef.current.value,
        passwordRef.current.value
      );
      navigate("/");

      if (nameRef.current.value) {
        await result.user.updateProfile({
          displayName: nameRef.current.value,
        });
      }
    } catch {
      setError("Misslyckades med att logga in");
    }

    setLoading(false);
  }

  return (
    <div className="flex h-screen items-center justify-center px-6 py-12 lg:px-8 bg-gradient-to-r from-[#2750E4] to-[#3C7AE9]">
      <Link to="/" className="absolute left-10 top-10">
        <img src={Logo} alt="Logo" className="h-12" />
      </Link>
      <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white p-8 rounded-lg shadow-xl">
        <img src={newLogo} alt="" className="mx-auto h-36 w-auto" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Fuktrond.se
        </h2>

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-10"
            role="alert"
          >
            <strong className="font-bold">{error}</strong>
          </div>
        )}
        <form
          action="#"
          method="Post"
          className="space-y-6 mt-8"
          onSubmit={handleSubmit}
        >
          {/* Email Input */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                ref={emailRef}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          {/* Password Input */}
          <div className="">
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Lösenord
                </label>
                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="font-semibold text-blue-500 hover:text-blue-700"
                  >
                    Glömt ditt lösenord?
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                ref={passwordRef}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>
          {/* Sign in Button */}
          <div>
            <button
              type="submit"
              disabled={loading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-indigo-600 hover:bg-gradient-to-l focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Logga in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

// import React, { useRef, useState } from 'react'
// import { useAuth } from '../Context/AuthContext';
// import logo2 from '../images/projektalliansLogo3.png'
// import { Link, useNavigate } from 'react-router-dom'

// export default function Login() {

//     const nameRef = useRef();
//     const emailRef = useRef();
//     const passwordRef = useRef();
//     const { login } = useAuth();
//     const [error, setError] = useState('')
//     const [loading, setLoading] = useState(false)
//     const navigate = useNavigate()

//     async function handleSubmit(e) {
//         e.preventDefault();

//         try {
//             setError('');
//             setLoading(true);
//             const result = await login(emailRef.current.value, passwordRef.current.value);
//             navigate("/");

//             if (nameRef.current.value) {
//                 await result.user.updateProfile({
//                     displayName: nameRef.current.value
//              });
//             }

//         } catch {
//             setError('Misslyckades med att logga in');
//         }

//         setLoading(false);
//     }
//   return (
//     <>
//         <div className="flex min-h-full flex-1 flex-col items-center px-6 py-12 lg:px-8">
//         <div className="sm:mx-auto sm:w-full sm:max-w-sm">
//           <img
//             className="mx-auto h-32 w-auto"
//             src={logo2}
//             alt=""
//           />
//           <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
//             Välkommen tillbaka!
//           </h2>
//           {
//           error &&
//           <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-10" role="alert">
//             <strong class="font-bold">{error}</strong>
//         </div>
//         }

//         </div>

//         <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
//           <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
//             <div>
//               <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
//                 Email
//               </label>
//               <div className="mt-2">
//                 <input
//                   id="email"
//                   name="email"
//                   type="email"
//                   autoComplete="email"
//                   ref={emailRef}
//                   required
//                   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                 />
//               </div>
//             </div>

//             <div>
//               <div className="flex items-center justify-between">
//                 <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
//                   Lösenord
//                 </label>
//                 <div className="text-sm">
//                   <a href='/forgot-password' className="font-semibold text-blue-500 hover:text-blue-700">
//                     Glömt ditt lösenord?
//                   </a>

//                 </div>

//               </div>
//               <div className="mt-2">
//                 <input
//                   id="password"
//                   name="password"
//                   type="password"
//                   autoComplete="current-password"
//                   ref={passwordRef}
//                   required
//                   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//                 />
//               </div>
//             </div>

//             <div>
//               <button
//                 type="submit"
//                 disabled={loading}
//                 className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#5ABDF4] to-[#3C7AE9] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//               >
//                 Logga in
//               </button>
//             </div>
//           </form>

//           <div className="mt-10 text-center text-sm text-gray-500">
//             har du inget konto? <Link className="font-semibold leading-6 text-blue-500 hover:text-blue-700" to="/signup">Skapa ett här</Link>
//           </div>

//         </div>
//       </div>
//     </>
//   )
// }
