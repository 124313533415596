import React, { useState, useEffect } from "react";
import { updateProjectDataInFirestore } from "../Context/firestoreQueries";
import { useParams, useSearchParams } from "react-router-dom";
import { db, auth, storage } from "../firebase"; // Adjust with your actual path to the Firebase setup
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sv } from "date-fns/locale";
import { StoreSignatureAndLogoForDocumentInStorage } from "../Context/firestoreQueries";


function Grundinfo() {
  const { projectId, documentId } = useParams();
  const [searchParams] = useSearchParams();
  const isSharedProject = searchParams.get("isShared") === "true";
  const ownerUID = searchParams.get("ownerUID");
  const [project, setProject] = useState({
    foretag: "",
    uppdragsansvarig: "",
    datum: "",
    uppdragsnummer: "",
    ort: "",
    bestallare: "",
    fuktsakerhetsansvarig: "",
    kontrollansvarig: "",
    medverkande: "",
    bygglage: "",
    vaderlek: "",
  });
  const [documentData, setDocumentData] = useState(null);

  const [signatureImage, setSignatureImage] = useState(null);
  const [logotypeImage, setLogotypeImage] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [logotypePreview, setLogotypePreview] = useState(null);
  
  useEffect(() => {
    const fetchProjectAndImageInfo = async () => {
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
  
      // Fetch project data
      const projectRef = db.collection("userDocs").doc(uidToUse)
                           .collection("docs").doc(projectId)
                           .collection("Documents").doc(documentId);
      
      projectRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          const data = docSnapshot.data();
          // Adjust date conversion if needed
          if (data.datum?.seconds) {
            data.datum = new Date(data.datum.seconds * 1000);
          }
          setProject(prev => ({ ...prev, ...data }));
  
          // Check and set document-specific images if they exist
          if (data.signatureUrl) {
            setSignaturePreview(data.signatureUrl);
            // Optionally set a filename if stored in the document data
            setSignatureImage({ name: data.signatureFileName || 'Uploaded Signature' });
          }
          if (data.logotypeUrl) {
            setLogotypePreview(data.logotypeUrl);
            // Optionally set a filename if stored in the document data
            setLogotypeImage({ name: data.logotypeFileName || 'Uploaded Logotype' });
          }
        }
      }).catch(console.error);
  
      // If no document-specific images are set, fallback to user's default images
      if (!signaturePreview || !logotypePreview) {
        const defaultImagesRef = db.collection("userDocs").doc(uidToUse).collection("SignatureAndLogo").doc("userData");
        defaultImagesRef.get().then((doc) => {
          if (doc.exists) {
            const { signatureUrl, logotypeUrl, signatureFileName, logotypeFileName } = doc.data();
            if (!signaturePreview) {
              setSignaturePreview(signatureUrl || null);
              setSignatureImage({ name: signatureFileName || 'Default Signature' });
            }
            if (!logotypePreview) {
              setLogotypePreview(logotypeUrl || null);
              setLogotypeImage({ name: logotypeFileName || 'Default Logotype' });
            }
          }
        }).catch(console.error);
      }
    };
  
    fetchProjectAndImageInfo();
  }, [projectId, documentId, isSharedProject, ownerUID, signatureImage, logotypeImage, signaturePreview, logotypePreview]);
  
  
  
  

const uploadImage = async (image, path) => {
  const storageRef = storage.ref(path); // Adjust this to your Firebase storage reference
  const fileSnapshot = await storageRef.put(image);
  const url = await fileSnapshot.ref.getDownloadURL();
  return url;
};

const handleImageChange = async (e, type) => {
  const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
  const file = e.target.files[0];
  if (!file) {
    // If no file is selected, do not proceed
    return;
  }

  const previewUrl = URL.createObjectURL(file);
  const filePath = `userDocs/${uidToUse}/${projectId}/Documents/${documentId}/${type}.jpg`;

  try {
    const imageUrl = await uploadImage(file, filePath);
    // Set the preview and file state
    if (type === 'signature') {
      setSignaturePreview(previewUrl);
      setSignatureImage({file: file, name: file.name});
    } else if (type === 'logotype') {
      setLogotypePreview(previewUrl);
      setLogotypeImage({file: file, name: file.name});
    }

    // Prepare the update object with image URL and name
    const updates = {};
    updates[`${type}Url`] = imageUrl;
    updates[`${type}FileName`] = file.name; // Store the file name in Firestore

    // Update the Firestore document with new image data
    await db.collection("userDocs").doc(uidToUse).collection("docs").doc(projectId)
      .collection("Documents").doc(documentId).update(updates);
  } catch (error) {
    console.error("Error uploading image and updating Firestore:", error);
  }
};







  // Grundinfo.js
const handleDataUpdate = (e) => {
  const { name, value } = e.target;

  setProject((prevProject) => {
    let updatedValue = value;

    if (name === "datum" && value instanceof Date) {
      updatedValue = value.toISOString().split("T")[0];
    }

    const updatedProject = { ...prevProject, [name]: updatedValue };

    // Use the correct UID for updating
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    if (uidToUse) {
      updateProjectDataInFirestore(uidToUse, projectId, updatedProject, documentId);
    } else {
      console.error("Error: UID is null");
    }

    return updatedProject;
  });
};


  return (
    <div className="p-2 bg-white rounded-lg ">
      <h2 className="text-2xl font-semibold">Grundinfo</h2>

      <div className="flex justify-between space-x-4">
        <div className="space-y-2 flex-1">
          <label
            htmlFor="foretag"
            className="block text-sm font-medium text-gray-700"
          >
            Företag
          </label>
           {/* this information underneth */}
          <input
            id="foretag"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Företag"
            name="foretag"
            value={project.foretag}
            onChange={handleDataUpdate}
          />
        </div>

        <div className="space-y-2 flex-1">
          <label
            htmlFor="uppdragsansvarig"
            className="block text-sm font-medium text-gray-700"
          >
            Uppdragsansvarig
          </label>
           {/* this information underneth */}
          <input
            id="uppdragsansvarig"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Uppdragsansvarig"
            name="uppdragsansvarig"
            value={project.uppdragsansvarig}
            onChange={handleDataUpdate}
          />
        </div>
      </div>

      <div className="flex justify-between space-x-4 mt-4">
        <div className="space-y-2 flex-1">
          <label
            htmlFor="uppdragsnummer"
            className="block text-sm font-medium text-gray-700"
          >
            Uppdragsnummer
          </label>
           {/* this information underneth */}
          <input
            id="uppdragsnummer"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Uppdragsnummer"
            name="uppdragsnummer"
            value={project.uppdragsnummer}
            onChange={handleDataUpdate}
          />
        </div>

        <div className="space-y-2 flex-1">
          <label
            htmlFor="datum"
            className="block text-sm font-medium text-gray-700"
          >
            Datum
          </label>
           {/* this information underneth */}
          <ReactDatePicker
            id="datum"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            selected={
              project.datum && !isNaN(new Date(project.datum))
                ? new Date(project.datum)
                : null
            }
            onChange={(date) =>
              handleDataUpdate({ target: { name: "datum", value: date } })
            }
            dateFormat="yyyy-MM-dd"
            locale={sv}
            placeholderText="Datum"
          />
        </div>
      </div>

      <div className="flex justify-between space-x-4 mt-4">
        <div className="space-y-2 flex-1">
          <label
            htmlFor="ort"
            className="block text-sm font-medium text-gray-700"
          >
            Ort
          </label>
           {/* this information underneth */}
          <input
            id="ort"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Ort"
            name="ort"
            value={project.ort}
            onChange={handleDataUpdate}
          />
        </div>

        <div className="space-y-2 flex-1">
          <label
            htmlFor="beställare"
            className="block text-sm font-medium text-gray-700"
          >
            Beställare
          </label>
           {/* this information underneth */}
          <input
            id="bestallare"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Beställare"
            name="bestallare"
            value={project.bestallare}
            onChange={handleDataUpdate}
          />
        </div>
      </div>

      <div className="flex justify-between space-x-4 mt-4">
        <div className="space-y-2 flex-1">
          <label
            htmlFor="fuktsäkerhetsansvarig"
            className="block text-sm font-medium text-gray-700"
          >
            Fuktsäkerhetsansvarig
          </label>
          {/* this information underneth */}
          <input
            id="fuktsakerhetsansvarig"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Fuktsäkerhetsansvarig"
            name="fuktsakerhetsansvarig"
            value={project.fuktsakerhetsansvarig}
            onChange={handleDataUpdate}
          />
        </div>

        <div className="space-y-2 flex-1">
          <label
            htmlFor="kontrollansvarig"
            className="block text-sm font-medium text-gray-700"
          >
            Kontrollansvarig
          </label>
           {/* this information underneth */}
          <input
            id="kontrollansvarig"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Kontrollansvarig"
            name="kontrollansvarig"
            value={project.kontrollansvarig}
            onChange={handleDataUpdate}
          />
        </div>
      </div>

      <div className="space-y mt-4">
        <label
          htmlFor="medverkande"
          className="block text-sm font-medium text-gray-700"
        >
          Medverkande
        </label>
         {/* this information underneth */}
        <input
          id="medverkande"
          className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          type="text"
          placeholder="Medverkande"
          name="medverkande"
          value={project.medverkande}
          onChange={handleDataUpdate}
        />
      </div>

      <div className="flex justify-between space-x-4 mt-4">
        <div className="space-y-2 flex-1">
          <label
            htmlFor="byggläge"
            className="block text-sm font-medium text-gray-700"
          >
            Byggläge
          </label>
           {/* this information underneth */}
          <input
            id="bygglage"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Byggläge"
            name="bygglage"
            value={project.bygglage}
            onChange={handleDataUpdate}
          />
        </div>

        <div className="space-y-2 flex-1">
          <label
            htmlFor="vaderlek"
            className="block text-sm font-medium text-gray-700"
          >
            Väderlek
          </label>
          <input
            id="vaderlek"
            className="w-full p-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            type="text"
            placeholder="Väderlek"
            name="vaderlek"
            value={project.vaderlek}
            onChange={handleDataUpdate}
          />
        </div>
      </div>
 {/* Image Pickers Row */}
 <div className="flex justify-between space-x-4 mt-4">
        {/* Custom Signature File Input */}
        <div className="space-y-2 flex-1">
          <label htmlFor="signature" className="block text-sm font-medium text-gray-900">
            Signatur
          </label>
          <div className="mt-2 flex items-center">
            <span className="mr-2 text-sm font-medium text-gray-700">Välj en fil:</span>
            <label className="block w-full text-center bg-blue-50 text-blue-700 py-2 rounded-md cursor-pointer hover:bg-blue-100">
              {signatureImage ? signatureImage.name : 'Ingen fil vald'}
              <input
                id="signature"
                name="signature"
                type="file"
                onChange={(e) => handleImageChange(e, "signature")}
                className="hidden"
              />
            </label>
          </div>
          {signaturePreview && (
            <img src={signaturePreview} alt="Signature preview" className="mt-2 max-h-10" />
          )}
        </div>

        {/* Custom Logotype File Input */}
        <div className="space-y-2 flex-1">
          <label htmlFor="logotype" className="block text-sm font-medium text-gray-900">
            Logotyp
          </label>
          <div className="mt-2 flex items-center">
            <span className="mr-2 text-sm font-medium text-gray-700">Välj en fil:</span>
            <label className="block w-full text-center bg-blue-50 text-blue-700 py-2 rounded-md cursor-pointer hover:bg-blue-100">
              {logotypeImage ? logotypeImage.name : 'Ingen fil vald'}
              <input
                id="logotype"
                name="logotype"
                type="file"
                onChange={(e) => handleImageChange(e, "logotype")}
                className="hidden"
              />
            </label>
          </div>
          {logotypePreview && (
            <img src={logotypePreview} alt="Logotype preview" className="mt-2 max-h-10" />
          )}
        </div>
      </div>
      {/* ... (Include other components or HTML elements as necessary) */}
    </div>
  );
}

export default Grundinfo;

// function Grundinfo({ onNewPage, projectId }) {
//     const [project, setProject] = useState(null);

//     useEffect(() => {
//       const fetchInitialData = async () => {
//         const projectData = await fetchProjectById(projectId);
//         if (projectData) {
//           setProject({
//             foretag: projectData.foretag || "",
//             uppdragsansvarig: projectData.uppdragsansvarig || "",
//             datum: projectData.datum || "",
//             uppdragsnummer: projectData.uppdragsnummer || "",
//           });
//         }
//       };

//       fetchInitialData();
//     }, [projectId]);

//     const handleDataUpdate = async (e) => {
//       const { name, value } = e.target;

//       setProject((prevProject) => ({
//         ...prevProject,
//         [name]: value,
//       }));

//       // Update Firestore
//       const userUID = auth.currentUser?.uid;
//       if (userUID) {
//         await updateProjectDataInFirestore(
//           projectId,
//           project.foretag,
//           project.uppdragsansvarig,
//           project.datum,
//           project.uppdragsnummer
//         );
//       }
//     };

//     return (
//       <div className="p-6 bg-white rounded-lg shadow-md">
//         <h1 className="text-2xl font-semibold mb-4">Grundinfo</h1>

//         {project === null ? (
//           <p className="text-gray-600">Loading data...</p>
//         ) : (
//           <input
//             className="p-2 border border-gray-300 rounded"
//             type="text"
//             placeholder="Företag"
//             name="foretag"
//             value={project.foretag || ""}
//             onChange={handleDataUpdate}
//           />
//         )}

//         {project ? (
//           <input
//             className="p-2 border border-gray-300 rounded"
//             type="text"
//             placeholder="Uppdragsansvarig"
//             name="datum"
//             value={project.uppdragsansvarig}
//             onChange={handleDataUpdate}
//           />
//         ) : (
//           <p className="text-gray-600">Loading data...</p>
//         )}

//         {project ? (
//           <input
//             className="p-2 border border-gray-300 rounded"
//             type="text"
//             placeholder="Uppdragsnummer"
//             name=""
//             value={project.uppdragsnummer}
//             onChange={handleDataUpdate}
//           />
//         ) : (
//           <p className="text-gray-600">Loading data...</p>
//         )}

//         {project ? (
//           <input
//             className="p-2 border border-gray-300 rounded"
//             type="text"
//             placeholder="Datum"
//             name=""
//             value={project.datum}
//             onChange={handleDataUpdate}
//           />
//         ) : (
//           <p className="text-gray-600">Loading data...</p>
//         )}

//         <button
//           className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out shadow-sm"
//           onClick={onNewPage}
//         >
//           Skapa ny sida
//         </button>
//       </div>
//     );
//   }

//   export default Grundinfo;














































































{/* import React, { useEffect, useRef, useState } from "react";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import doclogo from "../images/projektalliansLogo3.png";

const DocumentPreview = ({
  documentData,
  printRef,
  documentContent,
  pages,
  project,
  estimatedHeights,
  lastTitleNum,
}) => {
  const commentRefs = useRef({});
  const [titleCounts, setTitleCounts] = useState({});

  const ORIGINAL_IMAGE_WIDTH = 2526;
  const ORIGINAL_IMAGE_HEIGHT = 3576;
  const ASPECT_RATIO = ORIGINAL_IMAGE_HEIGHT / ORIGINAL_IMAGE_WIDTH;
  const paddingTopPercentage = ASPECT_RATIO * 100;

  useEffect(() => {
    pages.forEach((page, pageIndex) => {
      page.forEach((content, contentIndex) => {
        if (content.type === "comment") {
          // Ensure we're dealing with a comment
          const commentKey = `comment-${pageIndex}-${contentIndex}`;
          const commentElement = commentRefs.current[commentKey];

          if (commentElement) {
            console.log(
              `${commentKey}: Actual height = ${commentElement.clientHeight}`
            );
          }
        }
      });
    });
  }, [pages]); // We only need to depend on 'pages' here

  const [imageRenderedSize, setImageRenderedSize] = useState({
    width: 0,
    height: 0,
  });

  const extractImageName = (imageUrl) => {
    
    // Extract the filename from the URL and format it
    let namePart = imageUrl.split("F").pop(); // Get the last part after '/'
    // Extract the part before '_Page1'
    namePart = namePart.split("_Page")[0];
    console.log(namePart);
    return namePart;
  };
  const calculateAspectRatio = (width, height) => {
    return height / width;
  };
  const imageRef = useRef(null);

  const handleImageLoad = (event) => {
    // Calculate rendered image size
    const renderedWidth = event.target.clientWidth;
    const renderedHeight = event.target.clientHeight;
    setImageRenderedSize({ width: renderedWidth, height: renderedHeight });
  };

  


  const renderMarkers = (
    markers,
    imageRenderedSize,
    originalImageWidth,
    originalImageHeight
  ) => {
    const aspectRatio = calculateAspectRatio(
      originalImageWidth,
      originalImageHeight
    );

    return markers.map((marker, index) => {
      const left = (marker.xPercentage / 100) * imageRenderedSize.width;
      const top = (marker.yPercentage / 100) * imageRenderedSize.height;
      const markerStyle = {
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,
        transform: "translate(-50%, -50%)",
        backgroundColor: "blue", // Ensuring visibility
        color: "white",
        borderRadius: "50%",
        width: "20px", // Adjust size as needed
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px", // Adjust font size as needed
      };

      return (
        <div key={index} className="marker" style={markerStyle}>
          {marker.number}
        </div>
      );
    });
  };


  
  

  const firstCommentStyle = {
    marginTop: "110px",
  };

  const otherCommentStyle = {
    marginTop: "10px",
  };
  const formatImageName = (imageName) => {
    // Format the image name to remove unwanted parts
    return imageName.split("_Page1")[0];
  };

  return (
    <>

   

      <div ref={printRef}>
        <title>Filename</title>

        <div
          className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden relative p-6 print-container mb-8"
          style={{ width: "21cm", height: "29.7cm" }}
        >
          <div className="">
            <div className="relative">
              <div
                className="absolute top-0 left-0"
                style={{ left: "4em", top: "2.2893em" }}
              >
                <img src={doclogo} alt="Doc logo" className="h-[10%] w-[29%]" />
              </div>
              <div
                className="font-custom1 transform scale-100"
                style={{ transform: "scale(1)" }}
              >
                <div className="relative w-full h-[7em]">
                  <div
                    className="absolute whitespace-nowrap"
                    style={{ left: "27.9758em", top: "2.2893em" }}
                  >
                    <span className="font-calibri-bold text-custom-black text-xl leading-tight font-bold">
                      {documentData?.fileName}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-3"
                    style={{ left: "27.9758em", top: "5.3647em" }}
                  >
                    <span className="font-calibri-bold text-custom-black text-lg leading-relaxed font-bold">
                      {documentData?.foretag}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "8.547em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Uppdragsansvarig&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "10.2647em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.uppdragsansvarig}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "12.127em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Datum:&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "13.4447em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.datum}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "15.607em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Uppdragsnummer&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "17.3247em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.uppdragsnummer}
                    </span>
                  </div>
                  <div className="absolute top-80 left-6 right-6">
                
                    <div className="mb-6">
                      <span className="font-calibri text-custom-black text-lg font-bold">
                        {documentData?.fileName}
                      </span>
                    </div>

                 
                    <div className="flex justify-between">
                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Projekt Nr:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.uppdragsnummer}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Projektnamn:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {project?.fileName}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-r-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Ort:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.ort}
                        </span>
                      </div>
                    </div>

       
                    <div className="flex justify-between">
                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Beställare:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.bestallare}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Fuktsäkerhetsansvarig produktion:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.fuktsakerhetsansvarig}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-r-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Kontrollansvarig PBL:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.kontrollansvarig}
                        </span>
                      </div>
                    </div>

        
                    <div className="flex justify-between">
                      <div className="border-b-2 border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Medverkande:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.medverkande}
                        </span>
                      </div>

                      <div className="border-b-2 border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Byggläge:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.bygglage}
                        </span>
                      </div>

                      <div className="border-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Väderlek:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.vaderlek}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between mb-4">
                      <div className="border-b-2 border-r-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Datum:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.datum}
                        </span>
                      </div>
                      
                      <div className="w-1/3"></div>
                      <div className="w-1/3"></div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-6 right-6">
              <span className="font-calibri text-custom-black text-lg">
                Sida: 1(
                {Array.isArray(documentContent)
                  ? documentContent.length + 1
                  : 1}
                )
              </span>
            </div>
          </div>
        </div>

       
        {Array.isArray(pages) &&
          pages.map((page, pageIndex) => (
            <div
              className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden relative p-6 print-container mb-8"
              style={{ width: "21cm", height: "29.7cm" }}
              key={pageIndex}
            >
              <div className="relative">
               
                <div className="relative">
                  <div
                    className="absolute top-0 left-0"
                    style={{ left: "4em", top: "2.2893em" }}
                  >
                    <img
                      src={doclogo}
                      alt="Doc logo"
                      className="h-[10%] w-[29%]"
                    />
                  </div>
                  <div
                    className="font-custom1 transform scale-100"
                    style={{ transform: "scale(1)" }}
                  >
                    <div className="relative w-full h-[7em]">
                      <div
                        className="absolute whitespace-nowrap"
                        style={{ left: "27.9758em", top: "2.2893em" }}
                      >
                        <span className="font-calibri-bold text-custom-black text-xl leading-tight font-bold">
                          {documentData?.fileName}
                        </span>
                      </div>

                      <div
                        className="absolute whitespace-nowrap mt-2"
                        style={{ left: "27.9758em", top: "8em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                          Datum:&nbsp;
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-1"
                        style={{ left: "27.9758em", top: "10em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed">
                          {documentData?.datum}
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-2"
                        style={{ left: "27.9758em", top: "4em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                          Uppdragsnummer:&nbsp;
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-1"
                        style={{ left: "27.9758em", top: "6em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed">
                          {documentData?.uppdragsnummer}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {page.map((content, contentIndex) => {
                const commentStyle =
                  contentIndex === 0 ? firstCommentStyle : otherCommentStyle;
                if (content && content.comment) {
                  const isTitleDisplayed =
                    content.comment.titleNumber <= lastTitleNum;
                  const displayTitleNumber = isTitleDisplayed
                    ? null
                    : content.comment.titleNumber;
                  const displayCommentNumber = content.comment.commentNumber;
                  console.log(
                    `Content on Page ${pageIndex + 1}, Comment ${
                      contentIndex + 1
                    }:`,
                    content
                  );
                  content.photos &&
                    content.photos.forEach((photo) => {
                      console.log(
                        `Comment Image URL on Page ${pageIndex + 1}, Comment ${
                          contentIndex + 1
                        }:`,
                        photo.imageUrl
                      );
                    });

                  return (
                    <div
                      key={`comment-${pageIndex}-${contentIndex}`}
                      ref={(el) =>
                        (commentRefs.current[
                          `comment-${pageIndex}-${contentIndex}`
                        ] = el)
                      }
                      style={commentStyle}
                      className="comment-content border border-gray-300 rounded p-2"
                    >
                      <div className="flex items-center mb-2">
                        {displayTitleNumber && (
                          <span className="font-semibold mr-2">
                            {displayTitleNumber}
                          </span>
                        )}
                        <span className="font-semibold mr-2">
                          {displayCommentNumber}
                        </span>
                        <h3 className="font-semibold ml-2">
                          {content.comment.title}
                        </h3>
                        <span
                          className={`ml-auto px-2 py-1 inline-flex items-center text-xs leading-5 rounded-full  ${
                            content.comment.status === "Åtgärd Krävs"
                              ? "bg-red-100 text-red-800"
                              : content.comment.status === "Fölljas Upp"
                              ? "bg-yellow-100 text-yellow-800"
                              : "bg-green-100 text-green-800"
                          }`}
                        >
                          {content.comment.status === "Åtgärd Krävs" ? (
                            <ErrorIcon
                              fontSize="small"
                              style={{ marginRight: "4px" }}
                            />
                          ) : content.comment.status === "Fölljas Upp" ? (
                            <WarningIcon
                              fontSize="small"
                              style={{ marginRight: "4px" }}
                            />
                          ) : (
                            <CheckCircleIcon
                              fontSize="small"
                              style={{ marginRight: "4px" }}
                            />
                          )}
                          {content.comment.status}
                        </span>
                      </div>
                      <p className="mb-4">{content.comment.commentText}</p>

                      <div className="flex flex-wrap gap-4 mt-4">
                        {content.comment.photos &&
                          content.comment.photos.map((photo, idx) => (
                            <div
                              key={idx}
                              className="flex-1 min-w-0"
                              style={{ maxWidth: "290px", height: "290px" }}
                            >
                              <img
                                src={photo.imageUrl}
                                alt={`Comment related image ${idx}`}
                                className="w-full h-full object-cover rounded"
                              />
                            </div>
                          ))}
                      </div>

                      <div className="mt-3 text-left flex items-center">
                        <span>Referenspunkt: </span>
                        <div
                          className={`inline-flex w-5 h-5 rounded-full text-white items-center justify-center ml-1 mr-2 ${
                            content.comment.color === "red"
                              ? "bg-red-500"
                              : content.comment.color === "orange"
                              ? "bg-orange-500"
                              : "bg-green-500"
                          }`}
                          style={{
                            backgroundColor:
                              content.comment.color === "red"
                                ? "#ff0000"
                                : content.comment.color === "orange"
                                ? "#ffa500"
                                : "#008000",
                          }}
                        >
                          <span className="text-xs">
                            {content.comment.number}
                          </span>
                        </div>
                        <span>på ritning A-40-1-121</span>
                      </div>
                    </div>
                  );
                } else if (content && content.image) {
                  return (
                    <div
                      key={`image-${pageIndex}-${contentIndex}`}
                      className="image-container"
                      style={{
                        width: "100%",
                        paddingTop: `${paddingTopPercentage}%`,
                        position: "absolute",
                        top: "50%",
                        left: "0",
                        transform: "translateY(-50%)",
                        overflow: "hidden",
                        background: "transparent",
                      }}
                    >
                      <div className="absolute whitespace-nowrap"
                        style={{ left: "2.3em", top: "15.5em" }}>
                    <span className="font-calibri text-custom-black text-lg font-bold">{extractImageName(content.image.url)}</span>
                    </div>
                    
                      <div
                        className="image-content"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={content.image.url}
                          alt="Floor plan"
                          onLoad={handleImageLoad}
                          ref={imageRef}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                        {renderMarkers(content.markers, imageRenderedSize)}
                      </div>
                    </div>
                  );
                }
              })}

              <div className="absolute bottom-6 right-6 flex">
                <span className="font-calibri text-custom-black text-lg">
                  Sida: {pageIndex + 1}({pages.length})
                </span>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default DocumentPreview; */}