import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../images/WhiteLogo.svg';
import newLogo from '../images/ProjectLogoThree.png';
import { createMessageDocument } from '../Context/firestoreQueries';

const Contact = () => {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); // New state for success message
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;

    try {
      await createMessageDocument(name, email, message);
      // Set success message
      setSuccess('Tack för ditt medelande, vi hör av oss till dig så snart vi har tid.');
      setError(''); // Clear any previous errors
      // Optionally reset form fields here
      nameRef.current.value = '';
      emailRef.current.value = '';
      messageRef.current.value = '';
      // Do not navigate away, so the user can see the success message
    } catch (error) {
      setError('Failed to send the message. Please try again.');
      setSuccess(''); // Clear any previous success messages
      console.error("Error storing message: ", error);
    }
  }
  return (
    <div className="flex h-full min-h-screen items-center justify-center px-6 py-12 lg:px-8 bg-gradient-to-r from-[#2750E4] to-[#3C7AE9]">
      <Link to="/" className="absolute left-10 top-10">
        <img src={Logo} alt="Logo" className="h-12" />
      </Link>
      <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white p-8 rounded-lg shadow-xl mt-28">
      <img src={newLogo} alt="" className="mx-auto h-36 w-auto" />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Kom i kontakt med oss</h2>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-6" role="alert">
            <strong className="font-bold">Fel!</strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
{success && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-6" role="alert">
            <strong className="font-bold">Där fick vi det!  </strong>
            <span className="block sm:inline">{success}</span>
          </div>
        )}
        <form className="space-y-6 mt-8" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Ditt namn</label>
            <input
              id="name"
              name="name"
              type="text"
              required
              ref={nameRef}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Din e-post</label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              ref={emailRef}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium leading-5 text-gray-700">Ditt meddelande</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              required
              ref={messageRef}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            ></textarea>
          </div>

          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-indigo-600 hover:bg-gradient-to-l focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Skicka
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
