import React, { useState, useEffect, useRef } from "react";
import add from "../images/add.png";
import { Link, useParams } from "react-router-dom";
import document from "../images/document.png";
import { useAuth } from "../Context/AuthContext";
import CreateDocumentPopUp from "../Components/CreateDocumentPopUp";
import {
  useUserDocs,
  deleteDocumentFromFirestore,
  deleteDocumentWithSubcollections,
} from "../Context/firestoreQueries";
import Options from "../images/Options.png";
import ProjectPopup from "../Components/ProjectPopup";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import logo from "../images/WhiteLogo.svg";
import { updateProjectFileNameInFirestore } from "../Context/firestoreQueries";
import BlueDocument from "../images/BlueDocument.png";
import ShareProjectPopUp from "../Components/ShareProjectPopUp";
import { useLocation } from "react-router-dom";
import DocumentPopup from "../Components/DocumentPopUp";

export default function Project() {
  const params = useParams();
  const projectId = params.projectId;
  const [project, setProject] = useState(null);
  const [isCreateDocumentVisible, setCreateDocumentVisible] = useState(false);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const {
    userOwnProjectsSnapshot,
    sharedProjectsSnapshot,
    isLoading,
    loading,
    error,
  } = useUserDocs();
  const [isSharedProject, setIsSharedProject] = useState(false); // Add this line
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [ownerUID, setOwnerUID] = useState(null);
  const [isSharePopupVisible, setSharePopupVisibility] = useState(false);
  const location = useLocation();
  const company = location.state?.company;
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    const fetchDocuments = async (projectRef) => {
      try {
        const snapshot = await projectRef
          .collection("Documents")
          .orderBy("timestamp", "desc")
          .get();
        if (!snapshot.empty) {
          const newDocs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDocuments(newDocs); // Update the documents state
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    const updateProjectState = (projectData) => {
      // Only update if the project data has changed
      if (JSON.stringify(project) !== JSON.stringify(projectData)) {
        setProject(projectData);
      }
    };

    if (userOwnProjectsSnapshot && sharedProjectsSnapshot) {
      const projectInOwn = userOwnProjectsSnapshot.find(
        (p) => p.id === projectId
      );
      const projectInShared = sharedProjectsSnapshot.find(
        (p) => p.id === projectId
      );

      if (projectInOwn) {
        updateProjectState(projectInOwn.data());
        setIsSharedProject(false);
        const projectRef = db
          .collection("userDocs")
          .doc(auth.currentUser?.uid)
          .collection("docs")
          .doc(projectId);
        fetchDocuments(projectRef);
      } else if (projectInShared && projectInShared.ownerUID) {
        updateProjectState(projectInShared);
        setIsSharedProject(true);
        setOwnerUID(projectInShared.ownerUID);
        const sharedProjectRef = db
          .collection("userDocs")
          .doc(projectInShared.ownerUID)
          .collection("docs")
          .doc(projectId);
        fetchDocuments(sharedProjectRef);
      }
    }
  }, [projectId, userOwnProjectsSnapshot, sharedProjectsSnapshot]);

  const handleShareButtonClick = () => {
    setSharePopupVisibility(true);
  };

  const handleFileNameChange = async (e) => {
    const newFileName = e.target.value;
    setProject((prevProject) => ({ ...prevProject, fileName: newFileName }));

    // Ensure to use the ownerUID for Firestore operations
    const projectOwnerUID = isSharedProject ? ownerUID : auth.currentUser?.uid;
    if (projectOwnerUID) {
      await updateProjectFileNameInFirestore(
        projectOwnerUID,
        projectId,
        newFileName
      );
    }
  };

  const handleProjectClick = (projectId, documentId) => {
    console.log("Received ownerUID:", location.state?.ownerUID);
    const shared = isSharedProject ? "true" : "false";
    navigate(
      `/project/${projectId}/${documentId}?isShared=${shared}&ownerUID=${ownerUID}`
    );
  };

  const handleClosePopup = () => {
    setSelectedProject(null);
    setPopupVisibility(false);
  };

  const handleDeleteDocument = async (doc) => {
    if (window.confirm("Är du säker på att du vill radera detta dokument?")) {
      try {
        // Use the updated function to delete the document and its subcollections
        await deleteDocumentWithSubcollections(projectId, doc.id);
        // Refresh the documents list or remove the deleted document from the state
        setDocuments((prevDocuments) =>
          prevDocuments.filter((document) => document.id !== doc.id)
        );
        console.log("Dokumentet och dess underkollektioner har raderats");
      } catch (error) {
        console.error("Fel vid radering av dokumentet och dess underkollektioner:", error);
      }
    }
  };
  

  const handleOpenDocumentPopup = (e, doc) => {
    e.stopPropagation(); // Prevent triggering the onClick for the entire div
  
    // Check if it's a shared project and prevent opening the popup if true
    if (isSharedProject) {
      console.log("Document popups are not allowed for shared projects.");
      // Optionally, show a message or take some other action
    } else {
      setSelectedDocument(doc.id); // Correctly setting the selected document ID
      setPopupVisibility(true); // Correctly controlling popup visibility
    }
  };
  
  

  const handleRenameProject = (project) => {
    // Open a rename modal, get the new name, and update Firestore
    // ...your renaming logic here...
    handleClosePopup();
  };

  const handleCreateDocumentClick = () => {
    setCreateDocumentVisible(true);
  };

  if (loading) return <p>Laddar projekt...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="bg-[#F3F4F8] min-h-screen flex flex-col">
      <header className="sticky top-0 z-50 flex items-center justify-between  bg-gradient-to-r from-[#2750E4] to-[#3C7AE9] shadow-md py-4 md:px-8">
        <div className="flex items-center space-x-5">
          <Link to="/">
            <img
              src={logo}
              alt="Projeketallians logo"
              className="h-14 w-22 md:h-10 mr-3"
              style={{ width: "33px" }}
            />
          </Link>
          {project ? (
            <input
              type="text"
              value={project.fileName}
              onChange={handleFileNameChange}
              className="bg-gray-100 text-gray-800 text-xl font-medium border rounded-md p-2 shadow-sm transition hover:border-gray-400 focus:outline-none focus:border-blue-500"
              placeholder="Enter file name..."
            />
          ) : (
            <p className="text-gray-600">Loading project...</p>
          )}
        </div>

        <div className="flex items-center space-x-6">
          <h2 className="hidden md:block text-white">
            {currentUser && currentUser.displayName}
          </h2>
          <button
            onClick={handleShareButtonClick}
            className="bg-white text-[#2750E4] font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out shadow-sm hover:bg-gray-200"
          >
            Dela
          </button>

          <ShareProjectPopUp
            visible={isSharePopupVisible}
            onClose={() => setSharePopupVisibility(false)}
            company={company} // Assuming company is stored in currentUser
            projectId={projectId}
          />
        </div>
      </header>
      <section className="mt-10 mb-16">
        <div className="max-w-3xl mx-auto px-4 md:px-6 bg-[#F3F4F8]">
          <h2 className="bg-[#F3F4F8] text-xl font-semibold mb-6">
            Skapa ny Fuktrond
          </h2>
          <div className="mb-5">
            <div
              onClick={handleCreateDocumentClick}
              className="relative h-52 w-40 border-2 rounded-md cursor-pointer flex justify-center items-center hover:border-blue-700 transition duration-300"
            >
              <img src={add} alt="Add new" />
            </div>
            <p className="mt-4 font-medium text-gray-800">Nytt</p>
          </div>
        </div>
      </section>
      <section className="bg-white py-8 shadow-md flex-grow">
        <div className="max-w-3xl mx-auto px-4 md:px-6 text-sm text-gray-700">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-gray-800 text-xl font-semibold">
              {isSharedProject ? "Delade Fuktronder" : "Mina Fuktronder"}
            </h2>
            <div className="flex items-center space-x-4">
              <img src={document} alt="Folder icon" className="w-6 h-6" />
            </div>
          </div>
          {documents.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {documents.map((doc) => (
  <div
    key={doc.id}
    className="relative border rounded-lg p-4 hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-col space-y-2"
    onClick={() => handleProjectClick(projectId, doc.id)}
  >
    <div className="flex items-center space-x-2">
      <img src={BlueDocument} alt="Document" className="w-8 h-8" />
      <h3 className="text-lg font-medium">{doc.fileName}</h3>
    </div>
    <p className="text-gray-600">
      <span className="text-gray-500">Date: </span>
      {doc.timestamp?.toDate().toISOString().slice(0, 10)}
    </p>
    <button
      onClick={(e) => {
        e.stopPropagation(); // Prevent triggering the onClick for the entire div
        handleOpenDocumentPopup(e, doc);
      }}
      className="absolute top-4 right-4 p-2 rounded hover:bg-gray-200 transition-colors duration-300"
    >
      <img src={Options} alt="Options" className="w-7 h-7" />
    </button>
    {isPopupVisible && selectedDocument === doc.id && (
      <DocumentPopup
        visible={true} // This popup is visible because it's the selected document
        onClose={handleClosePopup}
        onDelete={() => handleDeleteDocument(doc)}
        document={doc}
        className="absolute bottom-full right-0 z-50"
      />
    )}
  </div>
))}

            </div>
          ) : (
            <p className="text-start">
              Du har inga fuktronder i detta projekt ännu.
            </p>
          )}
        </div>
      </section>

      <CreateDocumentPopUp
        projectId={projectId} // Pass the projectId as a prop
        visible={isCreateDocumentVisible}
        documents={documents}
        ownerUID={isSharedProject ? ownerUID : null}
        onClose={() => setCreateDocumentVisible(false)}
      />
    </div>
  );
}
