import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { useAuth } from "../Context/AuthContext";
import logo from "../images/WhiteLogo.svg";
import {
  updateDocumentFileNameInFirestore,
  fetchPhotosFromFirestore,
  updateCommentUsedInReport,
  loadCommentsFromLocalStorage,
  saveCommentsToLocalStorage,
  saveTitleCountsAndLastTitleNum,
  loadTitleCountsAndLastTitleNum,
  loadImagesFromLocalStorage,
  saveImagesToLocalStorage,
} from "../Context/firestoreQueries";
import Ritningar from "../Components/Ritningar";
import Grundinfo from "../Components/Grundinfo";
import Bilder from "../Components/Bilder";
import Kommentarer from "../Components/Kommentarer";
import ReactToPrint from "react-to-print";
import DocumentPreview from "../Components/DocumentPreview";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams } from "react-router-dom";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"; // add this to the spara button
function Document() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { projectId, documentId } = params;
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [activeButton, setActiveButton] = useState("Grundinfo");
  const [documentData, setDocumentData] = useState(null);
  const [project, setProject] = useState(null);
  const [pages, setPages] = useState([]);
  const [titleCounts, setTitleCounts] = useState({});
  const [lastTitleNum, setLastTitleNum] = useState(0);
  const [photosByImageName, setPhotosByImageName] = useState({});
  const printRef = useRef();
  const [usedCommentCombinations, setUsedCommentCombinations] = useState(
    new Set()
  );
  const [comments, setComments] = useState([]);
  const isSharedProject = searchParams.get("isShared") === "true";

  const ownerUID = searchParams.get("ownerUID");

  const [loading, setLoading] = useState(false);

  

  const handleCommentDeletion = (deletedCommentId) => {
    // Update the 'pages' state by filtering out the deleted comment.
    // 'pages' is assumed to be an array of pages, where each page is an array of content items (comments, images, etc.).
    setPages((currentPages) => {
      // Map over each page
      return currentPages.map((page) => {
        // Filter out the content where the comment's id matches the deletedCommentId
        const filteredPage = page.filter((content) => {
          // Check if the content has a 'comment' property and if its id doesn't match the deletedCommentId
          return content.comment?.id !== deletedCommentId;
        });
  
        return filteredPage;
      }).filter(page => page.length > 0); // Additionally, remove any empty pages resulting from the deletion
    });
  };
  

  const handleAddImageToReport = (imageData) => {
    setPages((prevPages) => [
      ...prevPages,
      [{ image: imageData, markers: imageData.markers }],
    ]);
  };
  const updatePhotosByImageName = (newPhotosByImageName) => {
    setPhotosByImageName(newPhotosByImageName);
  };

  const handleUseInReport = async (comment, photos) => {
    console.log("Current pages before updating (handleUseInReport):", pages);
    const { title, imageName } = comment;
    const commentIdentifier = `${comment.number}_${imageName}`;

    // Determine the user UID to use based on whether the project is shared
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    // Check if the comment was previously removed, if so, clean-up first
    if (
      usedCommentCombinations.has(commentIdentifier) &&
      !isCommentInPages(commentIdentifier)
    ) {
      cleanUpRemovedComment(commentIdentifier);
    }

    // Check if the comment is already in the report using the unique identifier
    const isAlreadyInReport = pages.some((page) =>
      page.some(
        (content) =>
          content.comment &&
          `${content.comment.number}_${content.comment.imageName}` ===
            commentIdentifier
      )
    );

    if (isAlreadyInReport) {
      console.log("Comment is already in the report, not adding again.");
      return; // Stop the function if the comment is already in the report
    }

    try {
      const currentCommentRef = db
        .collection("userDocs")
        .doc(uidToUse) // Use uidToUse here
        .collection("docs")
        .doc(projectId)
        .collection("Documents")
        .doc(documentId)
        .collection("markers")
        .doc(imageName)
        .collection("comments")
        .doc(String(comment.number));

      const doc = await currentCommentRef.get();
      let newUsedInReport = true;
      if (doc.exists) {
        const currentUsedInReport = doc.data().usedInReport;
        newUsedInReport = !currentUsedInReport;
        await updateCommentUsedInReport(
          projectId,
          documentId,
          imageName,
          comment.number,
          newUsedInReport,
          uidToUse
        );
      }

      const unsubscribe = currentCommentRef.onSnapshot((docSnapshot) => {
        if (docSnapshot.exists) {
          const updatedComment = docSnapshot.data();
          if (updatedComment.usedInReport === newUsedInReport) {
            setPages((prevPages) => {
              if (
                prevPages.some((page) =>
                  page.some(
                    (content) =>
                      content.comment &&
                      `${content.comment.number}_${content.comment.imageName}` ===
                        commentIdentifier
                  )
                )
              ) {
                return prevPages; // Return early if comment already exists
              }

              let currentTitleData = titleCounts[title];

              if (!currentTitleData) {
                currentTitleData = {
                  titleNum: lastTitleNum + 1,
                  commentIds: {},
                  commentCount: 0,
                };
                setLastTitleNum(currentTitleData.titleNum);
              } else {
                // Recalculate commentCount based on existing comments in the report
                const existingComments = prevPages
                  .flat()
                  .filter(
                    (pageContent) =>
                      pageContent.comment && pageContent.comment.title === title
                  );
                currentTitleData.commentCount = existingComments.length;
              }

              const uniqueCommentId = uuidv4();
              currentTitleData.commentCount += 1;
              currentTitleData.commentIds[uniqueCommentId] =
                currentTitleData.commentCount;

              const updatedTitleCounts = {
                ...titleCounts,
                [title]: currentTitleData,
              };
              setTitleCounts(updatedTitleCounts);

              const hasImages = photos && photos.length > 0;
              const newCommentContent = {
                comment: {
                  ...updatedComment,
                  id: uniqueCommentId,
                  photos,
                  titleNumber: currentTitleData.titleNum,
                  commentNumber: `${currentTitleData.titleNum}.${currentTitleData.commentCount}`,
                },
                type: "comment",
                estimatedHeight: estimateCommentHeight(
                  updatedComment.commentText,
                  hasImages
                ),
              };

              const maxPageHeight = 920;
              const lastPage = prevPages[prevPages.length - 1] || [];
              const lastPageHasImage = lastPage.some(
                (content) => content.image
              );

              let newPages;
              if (lastPageHasImage || lastPage.length === 0) {
                newPages = [...prevPages, [newCommentContent]];
              } else {
                const currentPageHeight = lastPage.reduce(
                  (total, content) => total + (content.estimatedHeight || 0),
                  0
                );
                const newPageHeight =
                  currentPageHeight + newCommentContent.estimatedHeight;

                if (newPageHeight > maxPageHeight) {
                  newPages = [...prevPages, [newCommentContent]];
                } else {
                  newPages = prevPages.map((page, index) => {
                    if (index === prevPages.length - 1) {
                      return [...page, newCommentContent];
                    }
                    return page;
                  });
                }
              }

              // Save the updated pages and title data to local storage
              saveCommentsToLocalStorage(projectId, documentId, newPages);
              saveTitleCountsAndLastTitleNum(
                projectId,
                documentId,
                updatedTitleCounts,
                currentTitleData.titleNum
              );

              return newPages;
            });

            setUsedCommentCombinations((prev) => {
              const newSet = new Set(prev);
              newSet.delete(`${commentIdentifier}_removed`);
              newSet.add(commentIdentifier);
              return newSet;
            });
          }
        }
      });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error toggling comment used in report:", error);
    }
  };

  // Helper function to check if comment is currently rendered in the report
  const isCommentInPages = (commentIdentifier) => {
    return pages.some((page) =>
      page.some(
        (content) =>
          content.comment &&
          `${content.comment.number}_${content.comment.imageName}` ===
            commentIdentifier
      )
    );
  };

  // Helper function to clean up any memory of the removed comment
  const cleanUpRemovedComment = (commentIdentifier) => {
    setUsedCommentCombinations((prev) => {
      const newSet = new Set(prev);
      newSet.delete(commentIdentifier);
      return newSet;
    });
    // Add any additional clean-up logic here if needed
  };

  const handleRemoveFromReport = async (comment, uidToUse) => {
    console.log("Attempting to remove from report:", comment);
    const { number: commentNumber, imageName } = comment;
    const commentIdentifier = `${commentNumber}_${imageName}`;

    try {
      const currentCommentRef = db
        .collection("userDocs")
        .doc(uidToUse) // Replacing auth.currentUser?.uid with uidToUse
        .collection("docs")
        .doc(projectId)
        .collection("Documents")
        .doc(documentId)
        .collection("markers")
        .doc(imageName)
        .collection("comments")
        .doc(String(commentNumber));

      const doc = await currentCommentRef.get();
      if (doc.exists && doc.data().usedInReport) {
        // Set 'usedInReport' to false in Firestore
        await updateCommentUsedInReport(
          projectId,
          documentId,
          imageName,
          commentNumber,
          false,
          uidToUse // Pass uidToUse to updateCommentUsedInReport
        );
        // Update the local state to reflect this change and save to local storage
        setPages((prevPages) => {
          const newPages = prevPages
            .map((page) =>
              page.filter(
                (content) =>
                  !(
                    content.comment &&
                    `${content.comment.number}_${content.comment.imageName}` ===
                      commentIdentifier
                  )
              )
            )
            .filter((page) => page.length > 0); // Keep pages that still have content

          // Save the updated pages to local storage
          saveCommentsToLocalStorage(projectId, documentId, newPages);

          return newPages;
        });

        // Mark this comment as removed in the usedCommentCombinations
        setUsedCommentCombinations((prev) => {
          const newSet = new Set(prev);
          newSet.delete(commentIdentifier);
          newSet.add(`${commentIdentifier}_removed`); // Add a _removed flag
          return newSet;
        });
      } else {
        console.log(
          "Comment is not in the report or doesn't exist in Firestore."
        );
      }
    } catch (error) {
      console.error("Error removing comment from report:", error);
    }
  };

  const currentPageHeight = (pages) => {
    if (pages.length === 0) return 0;
    const currentPageIndex = pages.length - 1;
    const currentPage = pages[currentPageIndex];

    // Check if currentPage is an array and has items
    if (Array.isArray(currentPage) && currentPage.length > 0) {
      return currentPage.reduce(
        (total, content) => total + (content.estimatedHeight || 0),
        0
      );
    }

    return 0;
  };

  const estimateCommentHeight = (commentText, hasImages) => {
    const charactersPerLine = 86;
    const lineHeight = 32; // Your line height
    const baseHeightWithImages = 414; // Adjusted base height when images are present
    const baseHeightWithoutImages = 124; // Adjusted base height when no images

    const numberOfLines = Math.ceil(commentText.length / charactersPerLine);
    const textHeight = numberOfLines * lineHeight;

    // Calculate the estimated height
    const estimatedHeight = hasImages
      ? baseHeightWithImages + textHeight
      : baseHeightWithoutImages + textHeight;

    return estimatedHeight;
  };

  const handleFileNameChange = async (e) => {
    const newFileName = e.target.value;
    setDocumentData((prev) => ({ ...prev, fileName: newFileName }));
    try {
      await updateDocumentFileNameInFirestore(
        ownerUID, // Pass ownerUID here
        projectId,
        documentId,
        newFileName
      );
    } catch (error) {
      console.error("Error updating document name:", error);
    }
  };

  useEffect(() => {
    // Load images and comments from local storage and combine them
    const loadAndCombineData = () => {
      const savedImages =
        loadImagesFromLocalStorage(projectId, documentId) || [];
      const savedComments =
        loadCommentsFromLocalStorage(projectId, documentId) || [];
      const combinedPages = [
        ...savedComments,
        ...savedImages.map((imageData) => [
          { image: imageData, markers: imageData.markers },
        ]),
      ];
      setPages(combinedPages);
    };

    // Load title data from local storage and set them to state
    const loadAndSetTitleDataFromLocalStorage = () => {
      const {
        titleCounts: loadedTitleCounts,
        lastTitleNum: loadedLastTitleNum,
      } = loadTitleCountsAndLastTitleNum(projectId, documentId);
      setTitleCounts(loadedTitleCounts || {});
      setLastTitleNum(loadedLastTitleNum || 0);
    };

    // Fetch photos and comments, and set them to state
    const fetchPhotosAndComments = async () => {
      setLoading(true);
      const uidToUse = isSharedProject ? ownerUID : currentUser?.uid;
      try {
        // Fetch photos
        const fetchedPhotosByImageName = await fetchPhotosFromFirestore(
          projectId,
          documentId,
          uidToUse
        );
        setPhotosByImageName(fetchedPhotosByImageName);

        // Fetch comments logic can be added here if needed
      } catch (error) {
        console.error("Error fetching photos and/or comments:", error);
      } finally {
        setLoading(false);
      }
    };

    // Call the functions to load data
    loadAndCombineData();
    loadAndSetTitleDataFromLocalStorage();
    fetchPhotosAndComments();

    // Determine the user UID to use based on whether the project is shared
    const uidToUse = isSharedProject ? ownerUID : currentUser?.uid;

    // Real-time subscription to document data
    const unsubscribeDocument = db
      .collection("userDocs")
      .doc(uidToUse)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .onSnapshot((docSnapshot) => {
        if (docSnapshot.exists) {
          setDocumentData(docSnapshot.data());
        }
      });

    // Fetch and subscribe to project data
    const projectRef = db
      .collection("userDocs")
      .doc(uidToUse)
      .collection("docs")
      .doc(projectId);
    const unsubscribeProject = projectRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          setProject(doc.data());
        } else {
          console.log("No such project!");
        }
      },
      (error) => {
        console.log("Error getting project:", error);
      }
    );

    // Cleanup function to unsubscribe from Firestore
    return () => {
      unsubscribeProject();
      unsubscribeDocument();
    };
  }, [currentUser, projectId, documentId, ownerUID, isSharedProject]);

  console.log("Project data in Document.js", project);

  console.log("Current Document imageName:", documentData?.imageName);

  return (
    <div className="bg-gray-100 min-h-screen font-sans">
      <div className="min-w-[45%] fixed top-0 left-0 h-full bg-white shadow-lg object-contain">
        <header className="sticky top-0 py-6 px-6 md:px-8 bg-gradient-to-r from-[#2750E4] to-[#3C7AE9] z-50 flex items-center justify-between w-full border-b-2 border-gray-200">
          <div className="flex items-center space-x-5">
            <button onClick={() => navigate(-1)}>
              <img
                src={logo}
                className="h-14 w-22 md:h-10 mr-3"
                style={{ width: "33px" }}
              />
            </button>
            {documentData ? (
              <input
                type="text"
                value={documentData.fileName}
                onChange={handleFileNameChange}
                className="bg-gray-100 text-gray-800 text-xl font-medium border rounded-md p-2 shadow-sm transition hover:border-gray-400 focus:outline-none focus:border-blue-500"
                placeholder="Enter file name..."
              />
            ) : (
              <p className="text-gray-600">Loading document...</p>
            )}
          </div>
        </header>
        <aside className="py-2 px-6 min-w-max">
          <div className="flex flex-col space-y-4 mb-2">
            <div className="flex space-x-4 mt-4">
              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Grundinfo"
                    ? "border-2 border-[#2750E4] bg-white text-[#2750E4]"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Grundinfo")}
              >
                Grundinfo
              </button>

              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Ritningar"
                    ? "border-2 border-[#2750E4] bg-white text-[#2750E4]"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Ritningar")}
              >
                Ritningar
              </button>

              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Kommentarer"
                    ? "border-2 border-[#2750E4] bg-white text-[#2750E4]"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Kommentarer")}
              >
                Kommentarer
              </button>

              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Bilder"
                    ? "border-2 border-[#2750E4] bg-white text-[#2750E4]"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Bilder")}
              >
                Bilder
              </button>
            </div>

            <div className="flex space-x-4">
              <ReactToPrint
                trigger={() => (
                  <button
                    className={`flex items-center bg-blue-600 text-white py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                      activeButton === "Print/Download"
                        ? "bg-[#2750E4] hover:bg-blue-700 text-white"
                        : "text-white hover:bg-blue-500"
                    }`}
                  >
                    <PictureAsPdfIcon
                      style={{ marginRight: "8px", fontSize: "24px" }}
                    />
                    Spara som PDF
                  </button>
                )}
                content={() => printRef.current}
              />
            </div>
          </div>

          <div className="object-scale-down">
            {activeButton === "Grundinfo" && <Grundinfo />}
            {activeButton === "Ritningar" && (
              <Ritningar
                projectId={projectId}
                documentId={documentId}
                imageName={documentData.imageName}
                onAddToReport={handleAddImageToReport}
                
              />
            )}

            {activeButton === "Kommentarer" && (
              <Kommentarer
                projectId={projectId}
                documentId={documentId}
                documentData={documentData}
                imageName={documentData && documentData.imageName}
                onUseInReport={handleUseInReport}
                handleRemoveFromReport={handleRemoveFromReport}
                onUpdatePhotosByImageName={updatePhotosByImageName}
                onCommentDeleted={handleCommentDeletion}
              />
            )}
            {activeButton === "Bilder" && (
              <Bilder
                projectId={projectId}
                documentId={documentId}
                imageName={documentData.imageName}
              />
            )}
          </div>
        </aside>
      </div>
      <main className="hidden md:block flex-1 p-12 ml-[45%]">
        {documentData && (
          <DocumentPreview
            projectId={projectId}
            documentId={documentId}
            documentData={documentData}
            printRef={printRef}
            pages={pages}
            setPages={setPages}
            project={project}
            estimatedHeights={pages.map((page) =>
              page.map((content) => content.estimatedHeight)
            )}
            titleCounts={titleCounts}
            lastTitleNum={lastTitleNum}
            imageName={documentData.imageName}
            photosByImageName={photosByImageName}
            usedCommentCombinations={usedCommentCombinations}
            setUsedCommentCombinations={setUsedCommentCombinations}
          />
        )}
      </main>
    </div>
  );
}

export default Document;

{
  /*import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { useAuth } from "../Context/AuthContext";
import logo from "../images/LogoIcon3.png";
import { updateDocumentFileNameInFirestore, saveCommentsToLocalStorage, loadCommentsFromLocalStorage, loadTitleCountsAndLastTitleNum, saveTitleCountsAndLastTitleNum, fetchPhotosFromFirestore} from "../Context/firestoreQueries";
import Ritningar from "../Components/Ritningar";
import Grundinfo from "../Components/Grundinfo";
import Bilder from "../Components/Bilder";
import Kommentarer from "../Components/Kommentarer";
import ReactToPrint from "react-to-print";
import DocumentPreview from "../Components/DocumentPreview";
import { v4 as uuidv4 } from 'uuid';



function Document() {
  const params = useParams();
  const { projectId, documentId } = params;
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [activeButton, setActiveButton] = useState("Grundinfo");
  const [documentData, setDocumentData] = useState(null);
  const [project, setProject] = useState(null);
  const [pages, setPages] = useState([]);
  const [titleCounts, setTitleCounts] = useState({});
  const [lastTitleNum, setLastTitleNum] = useState(0);
  const [photosByImageName, setPhotosByImageName] = useState({});
  const printRef = useRef();

  const handleAddImageToReport = (imageData) => {
    setPages((prevPages) => [
      ...prevPages,
      [{ image: imageData, markers: imageData.markers }],
    ]);
  };
  const updatePhotosByImageName = (newPhotosByImageName) => {
    setPhotosByImageName(newPhotosByImageName);
  };
 
  
  const handleUseInReport = (comment, photos) => {
    setPages((prevPages) => {
      let currentTitleData = titleCounts[comment.title];
  
      if (!currentTitleData) {
        currentTitleData = {
          titleNum: lastTitleNum + 1,
          commentIds: {},
          commentCount: 0,
        };
        setLastTitleNum(lastTitleNum + 1);
      }
  
      const commentId = uuidv4();
      currentTitleData.commentCount += 1;
      currentTitleData.commentIds[commentId] = currentTitleData.commentCount;
  
      const updatedTitleCounts = {
        ...titleCounts,
        [comment.title]: currentTitleData,
      };
      setTitleCounts(updatedTitleCounts);
  
      const hasImages = photos && photos.length > 0;
  
      const newCommentContent = {
        comment: {
          ...comment,
          id: commentId,
          photos,
          titleNumber: currentTitleData.titleNum,
          commentNumber: `${currentTitleData.titleNum}.${currentTitleData.commentIds[commentId]}`,
        },
        type: "comment",
        estimatedHeight: estimateCommentHeight(comment.commentText, hasImages),
      };
  
      const maxPageHeight = 870;
      const lastPage = prevPages[prevPages.length - 1] || [];
      const lastPageHasImage = lastPage.some((content) => content.image);
  
      let newPages;
  
      if (lastPageHasImage || lastPage.length === 0) {
        newPages = [...prevPages, [newCommentContent]];
      } else {
        const currentPageHeight = lastPage.reduce(
          (total, content) => total + (content.estimatedHeight || 0),
          0
        );
        const newPageHeight = currentPageHeight + newCommentContent.estimatedHeight;
  
        if (newPageHeight > maxPageHeight) {
          newPages = [...prevPages, [newCommentContent]];
        } else {
          newPages = prevPages.map((page, index) => {
            if (index === prevPages.length - 1) {
              return [...page, newCommentContent];
            }
            return page;
          });
        }
      }
  
      // Save the updated pages and title data to local storage
      saveCommentsToLocalStorage(projectId, documentId, newPages);
      saveTitleCountsAndLastTitleNum(projectId, documentId, updatedTitleCounts, lastTitleNum);
      return newPages;
    });
  };
  
  

  const currentPageHeight = (pages) => {
    if (pages.length === 0) return 0;
    const currentPageIndex = pages.length - 1;
    const currentPage = pages[currentPageIndex];

    // Check if currentPage is an array and has items
    if (Array.isArray(currentPage) && currentPage.length > 0) {
      return currentPage.reduce(
        (total, content) => total + (content.estimatedHeight || 0),
        0
      );
    }

    return 0;
  };

  const estimateCommentHeight = (commentText, hasImages) => {
    const charactersPerLine = 86;
    const lineHeight = 32; // Your line height
    const baseHeightWithImages = 414; // Adjusted base height when images are present
    const baseHeightWithoutImages = 124; // Adjusted base height when no images

    const numberOfLines = Math.ceil(commentText.length / charactersPerLine);
    const textHeight = numberOfLines * lineHeight;

    // Calculate the estimated height
    const estimatedHeight = hasImages
        ? baseHeightWithImages + textHeight
        : baseHeightWithoutImages + textHeight;

    return estimatedHeight;
};


  const handleFileNameChange = async (e) => {
    const newFileName = e.target.value;
    setDocumentData((prev) => ({ ...prev, fileName: newFileName }));
    try {
      await updateDocumentFileNameInFirestore(
        projectId,
        documentId,
        newFileName
      );
    } catch (error) {
      console.error("Error updating document name:", error);
    }
  };

  useEffect(() => {
    // Load comments and title data from local storage
    const savedComments = loadCommentsFromLocalStorage(projectId, documentId);
    const { titleCounts: loadedTitleCounts, lastTitleNum: loadedLastTitleNum } = loadTitleCountsAndLastTitleNum(projectId, documentId);
  
    // Set the loaded data to state
    if (savedComments) {
      setPages(savedComments);
    }
    setTitleCounts(loadedTitleCounts);
    setLastTitleNum(loadedLastTitleNum);
  
    // Function to fetch photos
    const fetchPhotos = async () => {
      try {
        const fetchedPhotosByImageName = await fetchPhotosFromFirestore(projectId, documentId);
        setPhotosByImageName(fetchedPhotosByImageName);
      } catch (error) {
        console.error("Error fetching photos:", error);
      }
    };
  
    // Call the fetchPhotos function
    fetchPhotos();
  
    // Firestore subscription for project and document updates
    let unsubscribeProject, unsubscribeDocument;
    if (currentUser?.uid && projectId) {
      unsubscribeProject = db
        .collection("userDocs")
        .doc(currentUser.uid)
        .collection("docs")
        .doc(projectId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setProject(doc.data());
          }
        });
  
      if (documentId) {
        unsubscribeDocument = db
          .collection("userDocs")
          .doc(currentUser.uid)
          .collection("docs")
          .doc(projectId)
          .collection("Documents")
          .doc(documentId)
          .onSnapshot((doc) => {
            if (doc.exists) {
              setDocumentData(doc.data());
            }
          });
      }
    }
  
    // Cleanup function for unsubscribing when the component unmounts or dependencies change
    return () => {
      if (unsubscribeProject) unsubscribeProject();
      if (unsubscribeDocument) unsubscribeDocument();
    };
  }, [currentUser, projectId, documentId]); // Ensure all dependencies are correctly listed
  
  
  
  console.log("Photos By Image Name in Document:", photosByImageName);

  return (
    <div className="bg-gray-100 min-h-screen font-sans">
      <div className="w-[45%] fixed top-0 left-0 h-full bg-white shadow-lg">
        <header className="sticky top-0 py-6 px-6 md:px-8 bg-white z-50 flex items-center justify-between w-full border-b-2 border-gray-200">
          <div className="flex items-center space-x-5">
            <button onClick={() => navigate(-1)}>
              <img
                src={logo}
                alt="Projeketallians logo"
                className="h-6 md:h-10"
              />
            </button>
            {documentData ? (
              <input
                type="text"
                value={documentData.fileName}
                onChange={handleFileNameChange}
                className="bg-gray-100 text-gray-800 text-xl font-medium border rounded-md p-2 shadow-sm transition hover:border-gray-400 focus:outline-none focus:border-blue-500"
                placeholder="Enter file name..."
              />
            ) : (
              <p className="text-gray-600">Loading document...</p>
            )}
          </div>
        </header>
        <aside className="py-8 px-6 min-w-max">
       
          <div className="flex flex-col space-y-4 mb-8">
            <div className="flex space-x-4">
              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Grundinfo"
                    ? "border border-blue-500 bg-blue-50 text-blue-600"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Grundinfo")}
              >
                Grundinfo
              </button>

              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Ritningar"
                    ? "border border-blue-500 bg-blue-50 text-blue-600"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Ritningar")}
              >
                Ritningar
              </button>

              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Kommentarer"
                    ? "border border-blue-500 bg-blue-50 text-blue-600"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Kommentarer")}
              >
                Kommentarer
              </button>

              <button
                className={`py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm ${
                  activeButton === "Bilder"
                    ? "border border-blue-500 bg-blue-50 text-blue-600"
                    : "text-gray-600 bg-white"
                }`}
                onClick={() => setActiveButton("Bilder")}
              >
                Bilder
              </button>
            </div>

            <div className="flex space-x-4">
              <ReactToPrint
                trigger={() => (
                  <button
                    className={`bg-blue-600 text-white py-2 px-6 text-lg rounded-md transition duration-300 ease-in-out font-medium shadow-sm w-28 ${
                      activeButton === "Print/Download"
                        ? "bg-blue-600 hover:bg-blue-700 text-white"
                        : "text-white hover:bg-blue-500"
                    }`}
                  >
                    Skriv ut
                  </button>
                )}
                content={() => printRef.current}
                pageStyle={`
        @page {
          size: A4;
          
        }
      
              />
            </div>
          </div>

       
          <div>
            {activeButton === "Grundinfo" && <Grundinfo />}
            {activeButton === "Ritningar" && (
              <Ritningar
                projectId={projectId}
                documentId={documentId}
                imageName={documentData.imageName}
                onAddToReport={handleAddImageToReport}
              />
            )}
            {activeButton === "Kommentarer" && (
              <Kommentarer
                projectId={projectId}
                documentId={documentId}
                imageName={documentData.imageName}
                onUseInReport={handleUseInReport}
                onUpdatePhotosByImageName={updatePhotosByImageName}
              />
            )}
            {activeButton === "Bilder" && (
              <Bilder
                projectId={projectId}
                documentId={documentId}
                imageName={documentData.imageName}
              />
            )}
          </div>
        </aside>
      </div>
      <main className="hidden md:block flex-1 p-12 ml-[45%]">
        {documentData && (
          <DocumentPreview
            projectId={projectId}
            documentId={documentId}
            documentData={documentData}
            printRef={printRef}
            pages={pages}
            project={project}
            estimatedHeights={pages.map((page) =>
              page.map((content) => content.estimatedHeight)
            )}
            titleCounts={titleCounts}
            lastTitleNum={lastTitleNum}
            imageName={documentData.imageName}
            photosByImageName={photosByImageName}
          />
        )}

       
      </main>
    </div>
  );
}

export default Document; */
}

{
  /*  */
}
