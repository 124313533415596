import React, { useContext, useState, useEffect } from 'react'
import { auth, db } from '../firebase'


const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export default function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true)

    //function signup(email, password) {
       // return auth.createUserWithEmailAndPassword(email, password)
        
    //}

    async function signup(email, password) {
        const response = await auth.createUserWithEmailAndPassword(email, password);
        return response.user;
    }

    async function storeUserInFirestore(user) {
        await db.collection('users').doc(user.uid).set({
            uid: user.uid,
            email: user.email,
            name: user.displayName
        });
    }
    


    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }
    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }
    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }
    function updateName(name) {
        return currentUser.updateProfile({
            displayName: name
        });
    }



    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setLoading(false);
            setCurrentUser(user)
        })
        return unsubscribe
    }, [])
    

    const value = {
        currentUser,
        signup, 
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        updateName,
        storeUserInFirestore

    }

  return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
  )
}
