import React, { useState, useEffect, useRef } from "react";
import {
  uploadPdfToStorage,
  fetchPdfsFromFirestore,
  uploadImageToStorage,
  fetchImagesFromFirestore,
  fetchMarkersFromFirestore,
  fetchCommentsFromFirestore,
  saveImagesToLocalStorage,
  loadImagesFromLocalStorage,
  deleteFloorPlanImage
} from "../Context/firestoreQueries";
import { useParams, useSearchParams } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";
import greenFloorPlanTwo from "../images/greenFloorPlanTwo.png";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { auth, db } from "../firebase";
import Trash from '../images/Trash.png'; // add this icon

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;

function Ritningar(props) {
  const { projectId, documentId } = useParams();
  const [searchParams] = useSearchParams();
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState(null);
  const imageRef = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageRenderedSize, setImageRenderedSize] = useState({
    width: 0,
    height: 0,
  });
  
  const [comments, setComments] = useState([]);
  const ORIGINAL_IMAGE_WIDTH = 2526;
  const ORIGINAL_IMAGE_HEIGHT = 3576;
  const MODAL_MAX_WIDTH = "80%"; // or a specific pixel value, e.g., 600px
  const ASPECT_RATIO = ORIGINAL_IMAGE_HEIGHT / ORIGINAL_IMAGE_WIDTH;
  const paddingTopPercentage = ASPECT_RATIO * 100;

  const isSharedProject = searchParams.get("isShared") === "true";
  const ownerUID = searchParams.get("ownerUID");
  console.log(ownerUID)

  const [forceUpdate, setForceUpdate] = useState(0); // new state variable

  const modalStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    maxWidth: MODAL_MAX_WIDTH,
    background: "transparent",
  };

  const outerContainerStyle = {
    width: "100%",
    paddingTop: `${paddingTopPercentage}%`,
    position: "relative",
    background: "transparent",
    overflow: "hidden",
  };

  const innerContainerStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    background: "transparent",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "absolute",
  };

  const closeButtonStyle = {
    position: "absolute",
    bottom: "17px",
    left: "47.5%",
    

    backgroundColor: '#ccc', // Light gray background for visibility
    color: 'black', // Black text for contrast
    fontSize: '12px',
  };
  const ImageTitleStyle = {
    position: "absolute",
    bottom: "88%",
    left: "17%",
    transform: "translateX(-50%)",
  };
  const AddToReportButton = {
    position: "absolute",
    bottom: "17px",
    left: "78.5%",
    
    backgroundColor: 'blue', // Use a distinct color for visibility
    color: 'white', // White text for contrast
    fontSize: '12px',
  };

  const handleAddToReport = async () => {

    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    if (props.onAddToReport) {
      // Create the image data object with URL and markers
      const imageData = { url: selectedImage, markers: markers };

      // Pass the image data to the parent component's handler
      props.onAddToReport(imageData);

      // Save the image data to local storage
      const currentImages = loadImagesFromLocalStorage(projectId, documentId);
      saveImagesToLocalStorage(projectId, documentId, [
        ...currentImages,
        imageData,
      ]);

      // Locate the image document in Firestore and update its usedInReport field
      try {
        const imageRef = db
          .collection("userDocs")
          .doc(uidToUse)
          .collection("docs")
          .doc(projectId)
          .collection("Documents")
          .doc(documentId)
          .collection("imageFiles")
          .where("url", "==", selectedImage);

        const snapshot = await imageRef.get();
        snapshot.forEach((doc) => {
          // Update the usedInReport field to true
          doc.ref.update({ usedInReport: true });
        });
      } catch (error) {
        console.error("Error updating usedInReport field:", error);
      }
    }

    // Close the modal
    setModalOpen(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOpenModal = async (imageObj) => {
    console.log("Image Object:", imageObj);
    setSelectedImage(imageObj.url);
    setSelectedImageName(imageObj.name);

    // Determine the correct userUID to use
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    try {
      const fetchedMarkers = await fetchMarkersFromFirestore(
        projectId,
        documentId,
        imageObj.name,
        uidToUse
      );
      console.log("Fetched Markers:", fetchedMarkers); // Debugging log
      setMarkers(fetchedMarkers);

      const fetchedComments = await fetchCommentsFromFirestore(
        projectId,
        documentId,
        imageObj.name,
        uidToUse
      );
      console.log("Fetched Comments:", fetchedComments); // Debugging log
      setComments(fetchedComments);
    } catch (error) {
      console.error(
        "Error fetching markers or comments for image:",
        imageObj.name,
        error
      );
    }

    setModalOpen(true);
    setImageSize({ width: 0, height: 0 }); // Reset image size to recalculate when the image loads
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setComments([]);
  };

  const handleImageLoad = (event) => {
    const renderedWidth = event.target.width;
    const renderedHeight = event.target.height;
    setImageRenderedSize({ width: renderedWidth, height: renderedHeight });

    // Log the rendered image size for debugging
    console.log("Rendered Image Size:", renderedWidth, renderedHeight);
  };

  const convertPdfToImage = async (pdfFile, originalFileName) => {
    try {
      const imagesList = [];
      const pdf = await pdfjsLib.getDocument({
        url: URL.createObjectURL(pdfFile),
      }).promise;
  
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 1.5 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport }).promise;
        imagesList.push(canvas.toDataURL("image/png"));
      }
  
      return imagesList; // Return the list of images
    } catch (error) {
      console.error("Error converting PDF to image:", error);
      return []; // Return an empty array in case of error
    }
  };
  
  const handleUpload = async () => {
    setUploading(true);
    // Determine the correct userUID to use
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    console.log("Current user UID:", auth.currentUser?.uid); // Add this line
  
    try {
      await uploadPdfToStorage(file, projectId, documentId, uidToUse);
      
      const originalFileName = file.name.replace(".pdf", "");
      const imagesList = await convertPdfToImage(file, originalFileName);
  
      if (imagesList.length === 0) {
        throw new Error("No images were converted from the PDF.");
      }
  
      const newImageObjects = [];
      for (let i = 0; i < imagesList.length; i++) {
        const imageObject = await uploadImageToStorage(
          imagesList[i],
          projectId,
          documentId,
          originalFileName,
          i + 1,
          uidToUse
        );
        if (imageObject && imageObject.url) {
          newImageObjects.push({
            url: imageObject.url,
            name: imageObject.name
          });
        } else {
          console.error("Image upload failed for page:", i + 1);
        }
      }
  
      const fetchedImages = await fetchImagesFromFirestore(projectId, documentId, uidToUse);
      setImages(fetchedImages.map(img => ({ url: img.url, name: img.name })));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setUploading(false);
    setFile(null);
  };
  

  const fetchAndSetFiles = async () => {
    try {
      const fetchedFiles = await fetchPdfsFromFirestore(projectId, documentId);
      setFiles(fetchedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const fetchAndSetImages = async () => {
    try {
      const fetchedImages = await fetchImagesFromFirestore(
        projectId,
        documentId
      );
      setImages(fetchedImages.map((img) => img.url)); // Ensure this line correctly updates the images state
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    // Determine the correct userUID to use
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    const fetchAndSetFilesAndImages = async () => {
      // Determine the correct userUID to use
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    
      try {
        const fetchedFiles = await fetchPdfsFromFirestore(projectId, documentId, uidToUse);
        const fetchedImages = await fetchImagesFromFirestore(projectId, documentId, uidToUse);
        
        console.log("Fetched Images:", fetchedImages);
        setFiles(fetchedFiles);
        setImages(fetchedImages.map(img => ({ url: img.url, name: img.name }))); // Ensure this line correctly updates the images state
      } catch (error) {
        console.error("Error fetching files or images:", error);
      }
    };
    

    const fetchAndSetMarkers = async (imageName) => {
      try {
        const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
        const fetchedMarkers = await fetchMarkersFromFirestore(
          projectId,
          documentId,
          imageName,
          uidToUse
        );
        console.log("Fetched Markers:", fetchedMarkers); // Debugging log
        setMarkers(fetchedMarkers);
      } catch (error) {
        console.error("Error fetching markers:", error);
      }
    };

    const fetchAndSetComments = async (imageName) => {
      try {
        const fetchedComments = await fetchCommentsFromFirestore(
          projectId,
          documentId,
          imageName,
          uidToUse
        );
        setComments(fetchedComments);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    // Load markers from local storage
    const loadedMarkers = loadImagesFromLocalStorage(projectId, documentId);
    setMarkers(loadedMarkers || []);

    // Fetch files and images
    if (projectId && documentId) {
      fetchAndSetFilesAndImages();
    }

    // Fetch markers and comments for the selected image when the modal is opened
    if (modalOpen && selectedImageName) {
      fetchAndSetMarkers(selectedImageName);
      fetchAndSetComments(selectedImageName);
    }
  }, [
    projectId,
    documentId,
    modalOpen,
    selectedImageName,
    isSharedProject,
    ownerUID,
  ]);

  const getMarkerColor = (marker) => {
    // Directly return the color stored in the marker data
    return marker.color || "gray"; // Default color if not specified
  };

  const renderMarkers = () => {
    if (!imageRenderedSize.width || !imageRenderedSize.height || !modalOpen) {
      return null;
    }
  
    return markers.map((marker, index) => {
      const backgroundColor = getMarkerColor(marker);
  
      // Convert percentage positions to pixel values based on the rendered image size
      const xPercentage = parseFloat(marker.xPercentage);
      const yPercentage = parseFloat(marker.yPercentage);
  
      if (isNaN(xPercentage) || isNaN(yPercentage)) {
        console.error(`Invalid marker position data for marker ${index}`);
        return null; // Skip rendering this marker
      }
  
      const left = (xPercentage / 100) * imageRenderedSize.width;
      const top = (yPercentage / 100) * imageRenderedSize.height;
  
      const markerStyle = {
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,
        transform: "translate(-50%, -50%)",
        backgroundColor,
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "10px",
        color: "white",
      };
  
      return (
        <div key={index} style={markerStyle}>
          {marker.number}
        </div>
      );
    });
  };
  

  return (
    <div className="p-6 bg-white rounded-lg max-w-[620px] ">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Ritningar</h2>
        <div className="flex space-x-3">
          <input
            type="file"
            onChange={handleFileChange}
            accept=".pdf"
            id="fileInput"
            className="hidden"
          />
          <label
            htmlFor="fileInput"
            className="flex items-center px-3 py-2 bg-[#2750E4] text-white rounded cursor-pointer hover:bg-blue-600"
          >
            <PictureAsPdfIcon className="mr-2" />
            Välj en fil
          </label>
          <button
            onClick={handleUpload}
            disabled={!file || uploading}
            className={`flex items-center px-3 py-2 bg-[#2750E4]  text-white rounded ${
              !file || uploading
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-green-600"
            }`}
          >
            <CloudUploadIcon className="mr-2" />
            {uploading ? "Laddar upp..." : "Ladda upp PDF"}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 overflow-auto max-h-[550px]">
     
{images.length > 0 && images.map((image, index) => (
  <div key={index} className="card bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow relative" onClick={() => handleOpenModal(image)}>
    <img src={image.url} alt={`Image ${index + 1}`} className="w-full object-cover max-h-64" />
    <div className="p-4 flex items-center justify-between">
      <div>
        <img src={greenFloorPlanTwo} alt="Floor plan icon" className="w-6 h-6 mr-2" />
        <p className="font-semibold text-lg inline">{image.name.split("_Page1")[0]}</p> {/* Displaying the image name */}
      </div>
      
      <button
  onClick={async (e) => {
    e.stopPropagation(); // Prevent opening the modal

    // Correctly determining the ownerUID
    const effectiveOwnerUID = isSharedProject ? ownerUID : auth.currentUser?.uid;
    console.log(`Attempting to delete image for ownerUID: ${effectiveOwnerUID}`);

    if (!effectiveOwnerUID) {
      alert("Cannot determine the project owner. Please try again.");
      return;
    }

    const confirmDelete = window.confirm("Är du säker på att du vill radera ritningen, du kan inte referera till den i rapporten om du raderar den");
    if (confirmDelete) {
      try {
        await deleteFloorPlanImage(projectId, documentId, image.name, effectiveOwnerUID);
        // Optionally, re-fetch images or remove the image from state to update UI
        setImages(images.filter(img => img.name !== image.name));
      } catch (error) {
        console.error("Error deleting floor-plan image:", error);
        alert("Failed to delete the image. Please check the console for more details.");
      }
    }
  }}
  className="p-2 rounded-full hover:bg-red-100 transition duration-300 ease-in-out"
>
  <img src={Trash} alt="Delete" className="w-6 h-6 text-red-500" />
</button>


    </div>
    <p className="text-gray-600 text-sm px-4 pb-4">Öppna Ritning</p>
  </div>
))}

    {images.length === 0 && <p>Du har inte laddat up några ritningar ännu.</p>}
  </div>


      <Modal open={modalOpen} onClose={handleCloseModal} style={modalStyle}>
        <div
          className="bg-gray-100 rounded-lg mt-20 shadow-xl w-full p-4"
          style={{ maxWidth: MODAL_MAX_WIDTH }}
        >
          {/* Move the image title to here */}
          {selectedImageName && (
            <div className="" style={ImageTitleStyle}>
              <p className="font-semibold text-lg flex-1">
                {selectedImageName.split("_Page1")[0]}
              </p>
            </div>
          )}

          <div style={outerContainerStyle}>
            <div style={innerContainerStyle}>
              <img
                ref={imageRef}
                src={selectedImage}
                alt="Enlarged"
                onLoad={handleImageLoad}
                style={imageStyle}
              />
              {renderMarkers()}
            </div>
          </div>

          <Button onClick={handleCloseModal} style={closeButtonStyle}>
            Stäng
          </Button>
          <Button onClick={handleAddToReport} style={AddToReportButton}>
            Använd i rapport 
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Ritningar;
{
  /* 

import React, { useState, useEffect } from "react";
import { uploadPdfToStorage } from "../Context/firestoreQueries";
import * as pdfjsLib from "pdfjs-dist";

// Ensure to set the workerSrc to point to the pdf.worker.min.js file.
// You might need to adjust the path depending on your project structure.
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;

function Ritningar() {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const convertPdfToImage = async (pdfFile) => {
    try {
      const imagesList = [];
      const pdf = await pdfjsLib.getDocument({
        url: URL.createObjectURL(pdfFile),
      }).promise;

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 1.5 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport }).promise;
        imagesList.push(canvas.toDataURL("image/png"));
      }

      setImages(imagesList);
    } catch (error) {
      console.error("Error converting PDF to image:", error);
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      // Upload the original PDF
      await uploadPdfToStorage(file, "your_project_id_here"); // Replace with actual project ID

      // Convert the PDF to images
      await convertPdfToImage(file);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setUploading(false);
    setFile(null);
  };

  return (
    <div className="p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Ritningar</h2>

      <div className="flex space-x-4 mb-4">
        <input
          type="file"
          onChange={handleFileChange}
          accept=".pdf"
          id="fileInput"
          className="hidden"
        />
        <label
          htmlFor="fileInput"
          className="p-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-700"
        >
          Välj en fil
        </label>
        <button
          onClick={handleUpload}
          disabled={!file || uploading}
          className={`p-2 bg-blue-500 text-white rounded ${
            !file || uploading
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-700"
          }`}
        >
          {uploading ? "Laddar upp..." : "Ladda upp PDF"}
        </button>
      </div>

      <div className="my-4 text-center" id="image-container">
        <h1>Images</h1>
        {images.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
            {images.map((imgSrc, index) => (
              <div key={index} className="relative">
                <img
                  src={imgSrc}
                  alt={`Page ${index + 1}`}
                  className="w-full h-64 object-cover rounded"
                />
                
              </div>
            ))}
          </div>
        ) : (
          <p>Inga bilder har genererats ännu.</p>
        )}
      </div>
    </div>
  );
}

export default Ritningar;

*/
}

{
  /* 
import React, { useState, useEffect } from 'react';
import { uploadPdfToStorage, fetchPdfsFromFirestore } from '../Context/firestoreQueries';
import { useParams } from 'react-router-dom';

function Ritningar() {
  const { projectId } = useParams();
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      await uploadPdfToStorage(file, projectId);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setUploading(false);
    setFile(null);
    fetchAndSetFiles();
  };

  const fetchAndSetFiles = async () => {
    try {
      const fetchedFiles = await fetchPdfsFromFirestore(projectId);
      setFiles(fetchedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    fetchAndSetFiles();
  }, [projectId]);

  return (
    <div className="p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Ritningar</h2>
      
      <div className="flex space-x-4 mb-4">
        <input 
          type="file" 
          onChange={handleFileChange} 
          accept=".pdf" 
          id="fileInput"
          className="hidden"
        />
        <label 
          htmlFor="fileInput" 
          className="p-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-700"
        >
          Välj en fil
        </label>
        <button 
          onClick={handleUpload} 
          disabled={!file || uploading}
          className={`p-2 bg-blue-500 text-white rounded ${!file || uploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
        >
          {uploading ? 'Laddar upp...' : 'Ladda upp PDF'}
        </button>
      </div>
      
      <div className="flex space-x-4">
        {files.length > 0 ? (
          files.map((file, index) => (
            <div key={index} className="flex-shrink-0">
              <a 
                href={file.url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {file.name}
              </a>
            </div>
          ))
        ) : (
          <p>Inga filer har laddats upp ännu.</p>
        )}
      </div>
    </div>
  );
}

export default Ritningar;
*/
}

{
  /* import React, { useState, useEffect } from 'react';
import { uploadPdfToStorage, uploadImageToStorage, fetchPdfsFromFirestore, fetchImagesFromFirestore } from '../Context/firestoreQueries';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`;

function Ritningar({ projectId }) {  // Receive projectId as a prop
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pdfData = await fetchPdfsFromFirestore(projectId);
        setPdfs(pdfData);
        
        const imageData = await fetchImagesFromFirestore(projectId);
        setImageFiles(imageData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
  }, [projectId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const convertPdfToImage = async (pdfFile) => {
    try {
      const imagesList = [];
      const pdf = await pdfjsLib.getDocument({ url: URL.createObjectURL(pdfFile) }).promise;
      
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale: 1.5 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport }).promise;
        imagesList.push(canvas.toDataURL('image/png'));
      }
      
      setImages(imagesList);
    } catch (error) {
      console.error("Error converting PDF to image:", error);
    }
  };

  const handleUpload = async () => {
    setUploading(true);
    try {
      // Upload the original PDF
      const pdfUrl = await uploadPdfToStorage(file, projectId);
      
      // Convert the PDF to images
      const imagesList = await convertPdfToImage(file);
  
      // Upload images to storage
      for (const imgDataUrl of imagesList) {
        await uploadImageToStorage(imgDataUrl, projectId);
      }
  
      // Update state to display images
      setImages(imagesList);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setUploading(false);
    setFile(null);
  };
  

  return (
    <div className="p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Ritningar</h2>

      <div className="flex space-x-4 mb-4">
        <input 
          type="file" 
          onChange={handleFileChange} 
          accept=".pdf" 
          id="fileInput"
          className="hidden"
        />
        <label 
          htmlFor="fileInput" 
          className="p-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-700"
        >
          Välj en fil
        </label>
        <button 
          onClick={handleUpload} 
          disabled={!file || uploading}
          className={`p-2 bg-blue-500 text-white rounded ${!file || uploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
        >
          {uploading ? 'Laddar upp...' : 'Ladda upp PDF'}
        </button>
      </div>

      <div className="my-4">
        <h1>PDF Files</h1>
        {pdfs.length > 0 ? (
          pdfs.map((pdf, index) => (
            <p key={index}><a href={pdf.url} target="_blank" rel="noopener noreferrer">{pdf.name}</a></p>
          ))
        ) : (
          <p>No PDFs available.</p>
        )}
      </div>

      <div className="my-4">
        <h1>PNG Format Files</h1>
        {imageFiles.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
            {imageFiles.map((imgFile, index) => (
              <div key={index} className="relative">
                <img 
                  src={imgFile.url} 
                  alt={`Page ${index + 1}`}
                  className="w-full h-64 object-cover rounded"
                />
                
                </div>
                ))}
              </div>
            ) : (
              <p>No images available.</p>
            )}
          </div>
        </div>
      );
    }
    
    export default Ritningar;
    
     */
}

{
  /*
const handleUseInReport = (comment, photos) => {
    setPages((prevPages) => {
      const newCommentContent = {
        comment: { ...comment, photos },
        type: 'comment',
        estimatedHeight: estimateCommentHeight(comment.commentText, photos.length > 0)
      };
  
      const maxPageHeight = 870;
  
      // Determine if the last page has an image
      const lastPage = prevPages[prevPages.length - 1] || [];
      const lastPageHasImage = lastPage.some(content => content.image);
  
      if (lastPageHasImage || lastPage.length === 0) {
        // If the last page has an image or there are no pages, start a new page for the comment
        return [...prevPages, [newCommentContent]];
      } else {
        // Check if adding a comment exceeds max page height
        const currentPageHeight = lastPage.reduce((total, content) => total + (content.estimatedHeight || 0), 0);
        const newPageHeight = currentPageHeight + newCommentContent.estimatedHeight;
  
        if (newPageHeight > maxPageHeight) {
          // Start a new page if adding the comment exceeds the max height
          return [...prevPages, [newCommentContent]];
        } else {
          // Otherwise, add the comment to the existing last page
          return prevPages.map((page, index) => {
            if (index === prevPages.length - 1) {
              return [...page, newCommentContent];
            }
            return page;
          });
        }
      }
    });
  }; */
}

{
  /*
 const handleUseInReport = (comment, photos) => {
    setPages((prevPages) => {
      const newCommentContent = {
        comment: { ...comment, photos },
        type: 'comment',
        estimatedHeight: estimateCommentHeight(comment.commentText, photos.length > 0)
      };
  
      const maxPageHeight = 870;
  
      if (prevPages.length === 0) {
        // If there are no pages, add the comment to a new page
        return [[newCommentContent]];
      }
  
      const lastPage = prevPages[prevPages.length - 1];
      const lastPageHasImage = lastPage.some(content => content.type === 'image');
      const lastPageIsFull = currentPageHeight(lastPage) + newCommentContent.estimatedHeight > maxPageHeight;
  
      if (lastPageHasImage || lastPageIsFull) {
        // If the last page has an image or is full, add to a new page
        return [...prevPages, [newCommentContent]];
      } else {
        // Otherwise, add to the existing last page
        return prevPages.map((page, index) => {
          if (index === prevPages.length - 1) {
            return [...page, newCommentContent];
          }
          return page;
        });
      }
    });
  }; */
}
