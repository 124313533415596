import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase";
import firebase from "firebase/compat/app";
import Select from "react-select";

export default function ShareProjectPopUp({ visible, onClose, company, projectId }) {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [ownerUID, setOwnerUID] = useState(null); // State to hold the owner UID

    useEffect(() => {
        // Fetch users from the same company
        if (company) {
            db.collection("users")
                .where("company", "==", company)
                .get()
                .then((querySnapshot) => {
                    const users = querySnapshot.docs
                        .filter((doc) => doc.id !== auth.currentUser.uid) // Exclude current user
                        .map((doc) => ({ value: doc.id, label: doc.data().name }));
                    setCompanyUsers(users);
                })
                .catch((error) => {
                    console.error("Error fetching company users:", error);
                });
        }
    }, [company]);

    useEffect(() => {
        // Fetch the project data to get ownerUID
        if (projectId) {
            db.collection("userDocs").doc(auth.currentUser.uid).collection("docs").doc(projectId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        setOwnerUID(doc.data().ownerUID); // Set the ownerUID
                    }
                })
                .catch((error) => {
                    console.error("Error fetching project data:", error);
                });
        }
    }, [projectId]);

    // Function to handle project sharing
    const shareProject = () => {
        if (selectedUsers.length === 0) {
            alert("Please select at least one user to share with.");
            return;
        }

        if (!ownerUID) {
            console.error("Owner UID not found");
            return;
        }

        const sharedWithUIDs = selectedUsers.map((user) => user.value);

        db.collection("userDocs")
            .doc(ownerUID)
            .collection("docs")
            .doc(projectId)
            .update({
                sharedWith: firebase.firestore.FieldValue.arrayUnion(...sharedWithUIDs),
            })
            .then(() => {
                console.log("Project shared successfully");
                onClose();
            })
            .catch((error) => {
                console.error("Error sharing project:", error);
            });
    };

    if (!visible) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-30 backdrop-blur-sm">
            <div className="bg-white p-6 rounded-lg shadow-xl w-96">
                <h2 className="text-2xl font-semibold mb-6 text-gray-800">
                    Dela Projekt
                </h2>

                <Select
                    isMulti
                    options={companyUsers}
                    value={selectedUsers}
                    onChange={(selectedOption) => setSelectedUsers(selectedOption)}
                    placeholder="Dela ditt projekt med..."
                />

                <div className="flex justify-end gap-4">
                    <button
                        className="..." // Add your button styling here
                        onClick={shareProject}
                    >
                        Dela
                    </button>
                    <button
                        onClick={onClose}
                        className="..." // Add your button styling here
                    >
                        Avbryt
                    </button>
                </div>
            </div>
        </div>
    );
}
