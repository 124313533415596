
import imageSrc6 from '../../images/svg-9.svg';
import imageSrc7 from '../../images/svg-10.svg';
import imageSrc8 from '../../images/svg-11.svg';

import imageSrc9 from '../../images/svg-12.svg';


export const homeObjectOne = [
    {
      id: 'om-oss',
      lightBg: false,
      lightText: true,
      lightTextDesc: true,
      topLine: 'Spara Tid',
      headline: 'Accelerera Dina Fuktronder med Vår Digitala Lösning',
      description: 'Vår plattform omvandlar den traditionella kontrollprocessen till en snabb, enkel och helt digital upplevelse. Genom att integrera projektplanering, rapportgenerering och bildhantering i ett enda flöde, minskar vi tidsåtgången betydligt. Upplev hur vårt system kan frigöra värdefull tid för dig och ditt team.',
      buttonLabel: 'Get Started',
      imgStart: false,
      img: imageSrc7,
      alt: 'Car',
      dark: true,
      primary: true,
      darkText: false,
      displayButton: false,
    },
    {
        id: 'om-oss',
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: 'Helhetslösning från Fält till Skrivbord',
        headline: ' Mobil och Webbintegration för Smidiga Fuktronder',
        description: 'Vårt system erbjuder både en mobilapplikation och en webbapplikation, vilket säkerställer en sömlös digital arbetsprocess från byggarbetsplatsen till skrivbordet. All data lagras säkert i molnet, vilket gör att du kan komma åt dina projekt, rapporter och planritningar var du än befinner dig. Maximera effektiviteten och tryggheten i dina projekt med vår molnbaserade lösning.',
        buttonLabel: 'Get Started',
        imgStart: true,
        img: imageSrc8,
        alt: 'Car',
        dark: false,
        primary: false,
        darkText: true,
        displayButton: false,
    },
    {
        id: 'om-oss',
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: 'Strukturerad Professionalism i Varje Rapport',
        headline: 'Enhetlig Rapportering för Ökad Trygghet och Effektivitet',
        description: 'Med vår lösning genereras och lagras alla dina rapporter på ett och samma ställe, vilket garanterar en konsekvent arbetsprocess. Denna centraliserade hantering underlättar uppföljning, säkerställer hög professionalitet i presentationen och erbjuder en ovärderlig trygghet genom lättillgänglig dokumentation. Rapporten följer givetvis ByggaF:s standard. Upplev fördelarna med en strukturerad och pålitlig rapporteringsprocess.',
        buttonLabel: 'Get Started',
        imgStart: false,
        img: imageSrc9,
        alt: 'Car',
        dark: true,
        primary: true,
        darkText: false,
        
        displayButton: false,
    }
  ];



export const homeObjFour = {
    id: 'kontakta-oss',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Kom i Kontakt',
    headline: 'Upptäck Hur Vårt System Kan Förändra Ditt Arbete',
    description: 'Vill du veta mer om hur vår lösning kan effektivisera dina fuktronder? Kontakta oss idag för en personlig demonstration och se själv hur vi kan hjälpa dig att spara tid, förbättra samarbete och säkerställa kvalitet i dina projekt. Vårt team är redo att besvara dina frågor och guida dig genom hur vårt system kan hjälpa dig.',
    buttonLabel: 'Kontakta oss',
    imgStart: false,
    img: imageSrc6,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    to: '/contact',
    displayButton: true,
}