import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import { db, storage } from "../firebase"; // Include storage for image uploads
import logo2 from "../images/projektalliansLogo3.png";
import { Link, useNavigate } from "react-router-dom";
import { storeSignatureAndLogoInStorage } from "../Context/firestoreQueries";
import Logo from "../images/WhiteLogo.svg";
export default function UpdateProfile() {
  const nameRef = useRef();
  const emailRef = useRef();
  const companyRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updateEmail, updatePassword, updateName } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [signatureImage, setSignatureImage] = useState({file: null, name: ''});
  const [logotypeImage, setLogotypeImage] = useState({file: null, name: ''});
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [logotypePreview, setLogotypePreview] = useState(null);


  const uploadImage = async (file, path) => {
    const storageRef = storage.ref(path); // Create a reference to the path where you want to upload the file
    const fileSnapshot = await storageRef.put(file); // Upload the file
    const url = await fileSnapshot.ref.getDownloadURL(); // Get the file's URL
    return url; // Return the URL
  };

  const handleImageChange = async (e, type) => {
    const file = e.target.files[0];
    if (!file) {
      return; // Exit if no file was selected
    }
    const reader = new FileReader();
    reader.onloadend = async () => {
      const uploadPath = `userDocs/${currentUser.uid}/SignatureAndLogo/${type}.jpg`;
      const imageUrl = await uploadImage(file, uploadPath);

      if (type === "signature") {
        setSignaturePreview(reader.result);
        setSignatureImage(file); // Set the file as the state to use its name
        db.collection("users")
          .doc(currentUser.uid)
          .update({ signatureUrl: imageUrl });
      } else if (type === "logotype") {
        setLogotypePreview(reader.result);
        setLogotypeImage(file); // Set the file as the state to use its name
        db.collection("users")
          .doc(currentUser.uid)
          .update({ logotypeUrl: imageUrl });
      }
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    if (currentUser) {
      const signatureAndLogoRef = db.collection("userDocs").doc(currentUser.uid).collection("SignatureAndLogo").doc("userData");
      signatureAndLogoRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          // Update state with file names
          if (data.signatureFileName) {
            setSignatureImage(prevState => ({ ...prevState, name: data.signatureFileName }));
          }
          if (data.logotypeFileName) {
            setLogotypeImage(prevState => ({ ...prevState, name: data.logotypeFileName }));
          }
          // Update previews with URLs if they exist
          if (data.signatureUrl) {
            setSignaturePreview(data.signatureUrl);
          }
          if (data.logotypeUrl) {
            setLogotypePreview(data.logotypeUrl);
          }
        }
      }).catch(error => {
        console.error("Error fetching image names and URLs:", error);
      });
    }
  }, [currentUser]);
  
  useEffect(() => {
    if (currentUser) {
      const docRef = db.collection("users").doc(currentUser.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            nameRef.current.value =
              userData.name || currentUser.displayName || "";
            emailRef.current.value = userData.email || currentUser.email || "";
            companyRef.current.value = userData.company || "";
            // Use Firestore URLs for previews, or keep the existing preview if already set
            setSignaturePreview((prev) => userData.signatureUrl || prev);
            setLogotypePreview((prev) => userData.logotypeUrl || prev);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [currentUser]);

  function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Lösenorden matchar inte");
    }

    setLoading(true);
    setError("");

    const promises = [];

    if (nameRef.current.value !== currentUser.displayName) {
      promises.push(updateName(nameRef.current.value));
    }

    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }

    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    if (signatureImage || logotypeImage) {
      promises.push(
        storeSignatureAndLogoInStorage(
          signatureImage,
          logotypeImage,
          currentUser.uid
        )
      );
    }

    Promise.all(promises)
      .then((results) => {
        const { signatureUrl, logotypeUrl } =
          results.find(
            (result) => result?.signatureUrl || result?.logotypeUrl
          ) || {};

        const updatedUserData = {
          name: nameRef.current.value,
          email: emailRef.current.value,
          company: companyRef.current.value,
          ...(signatureUrl && { signatureUrl }),
          ...(logotypeUrl && { logotypeUrl }),
        };

        return db
          .collection("users")
          .doc(currentUser.uid)
          .update(updatedUserData);
      })
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Failed to update profile", error);
        setError("Misslyckades med att uppdatera din profil");
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <div className="flex h-screen items-center justify-center px-6 py-12 lg:px-8 bg-gradient-to-r from-[#2750E4] to-[#3C7AE9]">
        <Link to="/" className="absolute left-10 top-10">
          <img src={Logo} alt="Logo" className="h-12" />
        </Link>

        <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-4xl">
          <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 text-center mb-10">
            Uppdatera din profil
          </h2>

          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5"
              role="alert"
            >
              <strong className="font-bold">{error}</strong>
            </div>
          )}

          <form className="grid md:grid-cols-2 gap-10" onSubmit={handleSubmit}>
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Namn
                </label>
                <input
                  id="name"
                  name="name"
                  type="name"
                  autoComplete="name"
                  ref={nameRef}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                />
              </div>
              <div>
                <label
                  htmlFor="company"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Företagsnamn
                </label>
                <input
                  id="company"
                  name="company"
                  type="text"
                  autoComplete="company"
                  ref={companyRef}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  ref={emailRef}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Lösenord
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  ref={passwordRef}
                  placeholder="Lämna tomt för att behålla ditt befintliga lösenord"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                />
              </div>
              <div>
                <label
                  htmlFor="password-confirm"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Bekräfta Lösenord
                </label>
                <input
                  id="password-confirm"
                  name="password-confirm"
                  type="password"
                  autoComplete="current-password"
                  ref={passwordConfirmRef}
                  placeholder="Lämna tomt för att behålla ditt befintliga lösenord"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4"
                />
              </div>
            </div>

            <div className="space-y-6">
              {/* Custom Signature File Input */}
              <div>
                <label
                  htmlFor="signature"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Signatur
                </label>
                <div className="mt-2 flex items-center">
                  <span className="mr-2 text-sm font-medium text-gray-700">
                    Välj en fil:
                  </span>
                  <label className="block w-full text-center bg-blue-50 text-blue-700 py-2 rounded-md cursor-pointer hover:bg-blue-100">
                    {signatureImage ? signatureImage.name : "Ingen fil vald"}
                    <input
                      id="signature"
                      name="signature"
                      type="file"
                      onChange={(e) => handleImageChange(e, "signature")}
                      className="hidden"
                    />
                  </label>
                </div>
                {signaturePreview && (
                  <img
                    src={signaturePreview}
                    alt="Signature preview"
                    className="mt-2 max-h-20"
                  />
                )}
              </div>

              {/* Custom Logotype File Input */}
              <div>
                <label
                  htmlFor="logotype"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Logotyp
                </label>
                <div className="mt-2 flex items-center">
                  <span className="mr-2 text-sm font-medium text-gray-700">
                    Välj en fil:
                  </span>
                  <label className="block w-full text-center bg-blue-50 text-blue-700 py-2 rounded-md cursor-pointer hover:bg-blue-100">
                    {logotypeImage ? logotypeImage.name : "Ingen fil vald"}
                    <input
                      id="logotype"
                      name="logotype"
                      type="file"
                      onChange={(e) => handleImageChange(e, "logotype")}
                      className="hidden"
                    />
                  </label>
                </div>
                {logotypePreview && (
                  <img
                    src={logotypePreview}
                    alt="Logotype preview"
                    className="mt-2 max-h-20"
                  />
                )}
              </div>
            </div>

            <div className="md:col-span-2 ">
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#2750E4] to-[#3C7AE9] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 max-w-1/3"
              >
                Uppdatera
              </button>
            </div>
          </form>

          <div className="mt-10 text-center">
            <Link
              to="/"
              className="text-blue-700 hover:text-blue-500 transition duration-150 ease-in-out"
            >
              Avbryt
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
