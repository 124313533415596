import { useEffect, useState } from 'react';
import { db, auth } from "../firebase"; // Adjust the import path if necessary

const useSharedProjects = () => {
  const [sharedProjects, setSharedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userUID = auth.currentUser?.uid;

  useEffect(() => {
    console.log("useSharedProjects: useEffect called");
    if (!userUID) {
      console.log("useSharedProjects: No user UID found, aborting fetch");
      setLoading(false);
      return;
    }

    console.log("useSharedProjects: Starting fetch for shared projects with userUID:", userUID);

    const unsubscribe = db.collectionGroup("docs")
      .where("sharedWith", "array-contains", userUID)
      .onSnapshot(snapshot => {
        console.log(`useSharedProjects: Fetched ${snapshot.docs.length} documents`);
        const projects = snapshot.docs.map(doc => {
          console.log("useSharedProjects: Processing doc:", doc.id, "; data:", doc.data());

          // This check seems incorrect because it compares the user UID with the document's ID, which will always be false.
          // Instead, you should check if the user is part of the sharedWith array, which you do.
          // The following conditional block might not be necessary if you're only filtering by "sharedWith" in the query.
          if (userUID !== doc.id && !doc.data().sharedWith.includes(userUID)) {
            console.error("User doesn't have write permission for this project:", doc.id);
            return null; // Exclude project from results
          }

          return { id: doc.id, ...doc.data() };
        }).filter(project => project !== null);
        setSharedProjects(projects);
        setLoading(false);
      },
      error => {
        console.error("Error fetching shared projects:", error);
        setError(error);
        setLoading(false);
      });

    // Cleanup subscription on unmount
    return () => {
      console.log("useSharedProjects: Cleaning up on unmount");
      unsubscribe();
    };
  }, [userUID]);

  return [sharedProjects, loading, error];
};

export default useSharedProjects;
