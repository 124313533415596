import React, { useEffect, useRef, useState } from "react";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import doclogo from "../images/projektalliansLogo3.png";
import {
  fetchCommentsFromFirestore,
  loadImagesFromLocalStorage,
  saveImagesToLocalStorage,
  loadCommentsFromLocalStorage,
  fetchCoverPhotosFromFirestore,
} from "../Context/firestoreQueries";
import { auth, db } from "../firebase";
import { useParams, useSearchParams } from "react-router-dom";
const DocumentPreview = ({
  projectId,
  documentId,
  documentData,
  printRef,
  documentContent,
  pages,
  setPages,
  project,

  estimatedHeights,
  lastTitleNum,
  photosByImageName,
  titleCounts,
  usedCommentCombinations,
  setUsedCommentCombinations,
}) => {
  console.log("Project data in DocumentPreview.js", project);
  const commentRefs = useRef({});
  const [commentsByImage, setCommentsByImage] = useState({});
  const ORIGINAL_IMAGE_WIDTH = 2526;
  const ORIGINAL_IMAGE_HEIGHT = 3576;
  const ASPECT_RATIO = ORIGINAL_IMAGE_HEIGHT / ORIGINAL_IMAGE_WIDTH;
  const paddingTopPercentage = ASPECT_RATIO * 100;
  const totalPages = pages.length + 1;
  const [localStoredComments, setLocalStoredComments] = useState([]);
  const [searchParams] = useSearchParams();
  const isSharedProject = searchParams.get("isShared") === "true";
  const [logoUrl, setLogoUrl] = useState(doclogo); // Default to your initial logo
  const [signatureUrl, setSignatureUrl] = useState(null);
  const [selectedSignatureBox, setSelectedSignatureBox] = useState("left");

  const defaultLogoUrl = doclogo;
  const defaultSignatureUrl = null;
  const [coverPhotoForReport, setCoverPhotoForReport] = useState(null);
  const ownerUID = searchParams.get("ownerUID");

  const handleRadioChange = (e) => {
    setSelectedSignatureBox(e.target.value);
  };

  const handleRemoveImageFromReport = async (imageUrl, pageIndex) => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    console.log("Removing image from report:", imageUrl);
    console.log("Using UID for Firestore path:", uidToUse);

    try {
      // Firestore update logic
      const imageRef = db
        .collection("userDocs")
        .doc(uidToUse)
        .collection("docs")
        .doc(projectId)
        .collection("Documents")
        .doc(documentId)
        .collection("imageFiles")
        .where("url", "==", imageUrl);

      console.log("Executing Firestore query for image URL:", imageUrl);

      const snapshot = await imageRef.get();

      if (snapshot.empty) {
        console.log("No matching documents found for URL:", imageUrl);
      } else {
        snapshot.forEach((doc) => {
          console.log("Found document to update:", doc.id, doc.data());
          // Update the usedInReport field to false
          doc.ref
            .update({ usedInReport: false })
            .then(() => {
              console.log("Updated usedInReport to false for:", doc.id);
            })
            .catch((updateError) => {
              console.error("Error updating document:", doc.id, updateError);
            });
        });
      }

      // Remove from local storage
      const currentImages = loadImagesFromLocalStorage(projectId, documentId);
      console.log("Current images from local storage:", currentImages);

      const updatedImages = currentImages.filter(
        (imageData) => imageData.url !== imageUrl
      );
      console.log("Images after removal:", updatedImages);

      saveImagesToLocalStorage(projectId, documentId, updatedImages);

      // Update the pages state to remove the image
      setPages((prevPages) => {
        const newPages = prevPages.map((page) => [...page]);
        console.log("Current pages before removal:", newPages);

        if (newPages[pageIndex]) {
          newPages[pageIndex] = newPages[pageIndex].filter(
            (content) => !(content.image && content.image.url === imageUrl)
          );
        }

        console.log("Pages after removal:", newPages);
        return newPages.filter((page) => page.length > 0);
      });
    } catch (error) {
      console.error("Error removing image from report:", error);
    }
  };

  const measureAndLogCommentHeights = () => {
    Object.entries(commentRefs.current).forEach(([key, ref]) => {
      if (ref) {
        const actualHeight = ref.clientHeight;
        console.log(`Actual height of ${key}: ${actualHeight}px`);
      }
    });
  };

  useEffect(() => {
    const fetchImages = async () => {
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
      console.log("Using UID for Firestore query:", uidToUse); // Log the UID being used for the query
  
      const docRef = db.collection("userDocs").doc(uidToUse)
                       .collection("docs").doc(projectId)
                       .collection("Documents").doc(documentId);
  
     
  
      try {
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
          const data = docSnapshot.data();
          console.log("Fetched project data:", data); // Log fetched data for verification
          // Log the specific URLs
          console.log("Logotype URL:", data.logotypeUrl);
          console.log("Signature URL:", data.signatureUrl);
  
          setLogoUrl(data.logotypeUrl || defaultLogoUrl); // Set from data or fallback
          setSignatureUrl(data.signatureUrl || defaultSignatureUrl); // Set from data or fallback
        } else {
          // Handle case where document doesn't have specific images
          console.log("No specific images found for this document. Using default images.");
          setLogoUrl(defaultLogoUrl);
          setSignatureUrl(defaultSignatureUrl);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
        // Fallback to default images in case of error
        setLogoUrl(defaultLogoUrl);
        setSignatureUrl(defaultSignatureUrl);
      }
    };
  
    if (projectId && documentId) {
      fetchImages();
    }
  }, [projectId, documentId, isSharedProject, ownerUID]); // Dependencies
  
  
  
  useEffect(() => {
    const fetchCoverPhotoForReport = async () => {
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
      try {
        const coverPhotos = await fetchCoverPhotosFromFirestore(
          projectId,
          documentId,
          uidToUse
        );
        const usedInReportPhoto = coverPhotos.find(
          (photo) => photo.usedInReport === true
        );
        setCoverPhotoForReport(usedInReportPhoto);
      } catch (error) {
        console.error("Error fetching cover photo for report:", error);
      }
    };

    if (projectId && documentId) {
      fetchCoverPhotoForReport();
    }
  }, [projectId, documentId, isSharedProject, ownerUID]);

  useEffect(() => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    // Attempt to fetch project-specific images first

    const projectImageRef = db
      .collection("userDocs")
      .doc(uidToUse)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId);

    projectImageRef
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          const data = docSnapshot.data();
          // Check if project-specific images are available and set them
          if (data.logotypeUrl || data.signatureUrl) {
            setLogoUrl(data.logotypeUrl); // Use project-specific
            setSignatureUrl(data.signatureUrl); // Use project-specific
          } else {
            console.log(
              "Project-specific images not found, fetching default images..."
            );
            fetchDefaultImagesFromUser(uidToUse);
          }
        } else {
          console.log("Document does not exist, fetching default images...");
          fetchDefaultImagesFromUser(uidToUse);
        }
      })
      .catch((error) => {
        console.error("Error fetching project-specific images:", error);
        fetchDefaultImagesFromUser(uidToUse);
      });

    // Define fetchDefaultImages function
    const fetchDefaultImagesFromUser = (uid) => {
      console.log(
        `Fetching default images for UID: ${uid} from users collection.`
      );
      const userRef = db.collection("users").doc(uid);
      userRef
        .get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            const userData = docSnapshot.data();
            console.log("Default user image data found:", userData);
            setLogoUrl(userData.logotypeUrl || defaultLogoUrl); // Use default if specific not set
            setSignatureUrl(userData.signatureUrl || defaultSignatureUrl); // Use default if specific not set
          } else {
            console.log("No default user image data found.");
            // Optionally, set to some default values or handle the case of missing defaults
          }
        })
        .catch((error) => {
          console.error("Error fetching default user images:", error);
        });
    };
    // Fetch and update comments for page content
    const fetchPageComments = async () => {
      const commentsMap = {};
      for (const page of pages) {
        for (const content of page) {
          if (content.image) {
            try {
              const imageComments = await fetchCommentsFromFirestore(
                uidToUse,
                projectId,
                documentId,
                content.image.name
              );
              commentsMap[content.image.name] = imageComments.filter(
                (comment) => comment.usedInReport
              );
            } catch (error) {
              console.error(
                "Error fetching comments for image:",
                content.image.name,
                error
              );
            }
          }
        }
      }
      setCommentsByImage(commentsMap);
    };

    fetchPageComments();

    // Subscribe to comments updates
    const commentsPath = db
      .collection("userDocs")
      .doc(uidToUse)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers");

    const unsubscribeComments = commentsPath.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const commentData = change.doc.data();
        const commentIdentifier = `${commentData.number}_${change.doc.id}`;

        if (
          (change.type === "added" || change.type === "modified") &&
          commentData.usedInReport
        ) {
          if (!usedCommentCombinations.has(commentIdentifier)) {
            setCommentsByImage((prev) => ({
              ...prev,
              [change.doc.id]: [
                ...(prev[change.doc.id] || []).filter(
                  (c) => c.id !== commentData.id
                ),
                commentData,
              ],
            }));
            setUsedCommentCombinations((prev) =>
              new Set(prev).add(commentIdentifier)
            );
          }
        } else if (change.type === "removed" || !commentData.usedInReport) {
          if (usedCommentCombinations.has(commentIdentifier)) {
            setCommentsByImage((prev) => ({
              ...prev,
              [change.doc.id]: (prev[change.doc.id] || []).filter(
                (comment) => comment.id !== change.doc.id
              ),
            }));
            setUsedCommentCombinations((prev) => {
              const newSet = new Set(prev);
              newSet.delete(commentIdentifier);
              return newSet;
            });
          }
        }
      });
    });

    // Clean up on component unmount
    return () => {
      unsubscribeComments();
    };
  }, [
    projectId,
    documentId,
    isSharedProject,
    ownerUID,
    pages,
    usedCommentCombinations,
  ]);

  const [imageRenderedSize, setImageRenderedSize] = useState({
    width: 0,
    height: 0,
  });

  const extractImageName = (imageUrl) => {
    if (!imageUrl) {
      console.error("extractImageName was called with undefined imageUrl");
      return ""; // Return an empty string or a default value if imageUrl is undefined
    }

    let namePart = imageUrl.split("F").pop(); // Get the last part after '/'
    namePart = namePart.split("_Page")[0]; // Extract the part before '_Page'
    return namePart;
  };

  const calculateAspectRatio = (width, height) => {
    return height / width;
  };
  const imageRef = useRef(null);

  const handleImageLoad = (event) => {
    // Calculate rendered image size
    const renderedWidth = event.target.clientWidth;
    const renderedHeight = event.target.clientHeight;
    setImageRenderedSize({ width: renderedWidth, height: renderedHeight });
  };

  const getPhotosForComment = (commentNumber, commentImageName) => {
    const photosForImage = photosByImageName[commentImageName] || {};
    const photosForComment = photosForImage[commentNumber] || [];

    // Filter photos to only include those with usedWithComment set to true
    return photosForComment.filter((photo) => photo.usedWithComment === true);
  };

  const extractImageNameFromUrl = (imageUrl) => {
    let namePart = imageUrl.split("F").pop(); // Get the last part after '/'
    namePart = namePart.split("_Page")[0]; // Extract the part before '_Page'
    return namePart;
  };
  const renderMarkers = (
    markers,
    imageRenderedSize,
    imageName,
    imageUrl,
    currentDocumentId
  ) => {
    // Check if markers is defined and is an array
    if (!Array.isArray(markers)) {
      console.error("Markers data is undefined or not an array");
      return null; // Return null or an empty array as appropriate
    }

    return markers.map((marker, index) => {
      // Directly use the color from the marker data
      const backgroundColor = marker.color || "blue"; // Default to blue if no color is specified

      const markerStyle = {
        position: "absolute",
        left: `${(marker.xPercentage / 100) * imageRenderedSize.width}px`,
        top: `${(marker.yPercentage / 100) * imageRenderedSize.height}px`,
        transform: "translate(-50%, -50%)",
        backgroundColor, // Apply the color directly from the marker data
        color: "white",
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
      };

      return (
        <div key={index} className="marker" style={markerStyle}>
          {marker.number}
        </div>
      );
    });
  };

  const firstCommentStyle = {
    marginTop: "30px",
  };

  const otherCommentStyle = {
    marginTop: "5px",
  };
  const formatImageName = (imageName) => {
    // Format the image name to remove unwanted parts
    return imageName.split("_Page1")[0];
  };

  const nonEmptyPages = pages.filter((page) => page.length > 0);

  return (
    <>
      <div ref={printRef}>
        <title>Filename</title>

        <div
          className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden relative p-6 print-container mb-8"
          style={{ width: "21cm", height: "29.7cm" }}
        >
          <div className="">
            <div className="relative">
              <div
                className="absolute top-0 left-0"
                style={{ left: "2em", top: "2.2893em" }}
              >
                <img src={logoUrl} alt="Doc logo" className="h-[10%] w-[29%]" />
              </div>
              <div
                className="font-custom1 transform scale-100"
                style={{ transform: "scale(1)" }}
              >
                <div className="relative w-full h-[7em]">
                  <div
                    className="absolute whitespace-nowrap"
                    style={{ left: "27.9758em", top: "2.2893em" }}
                  >
                    <span className="font-calibri-bold text-custom-black text-xl leading-tight font-bold">
                      {documentData?.fileName}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-3"
                    style={{ left: "27.9758em", top: "5.3647em" }}
                  >
                    <span className="font-calibri-bold text-custom-black text-lg leading-relaxed font-bold">
                      {documentData?.foretag}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "8.547em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Uppdragsansvarig&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "10.2647em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.uppdragsansvarig}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "12.127em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Datum:&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "13.4447em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.datum}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "15.607em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Uppdragsnummer&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "17.3247em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.uppdragsnummer}
                    </span>
                  </div>
                  <div className="absolute top-80 left-6 right-6">
                    <div className="flex justify-between">
                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Projekt Nr:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.uppdragsnummer}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Projektnamn:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {project?.fileName}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-r-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Ort:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.ort}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Beställare:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.bestallare}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Fuktsäkerhetsansvarig produktion:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.fuktsakerhetsansvarig}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-r-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Kontrollansvarig PBL:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.kontrollansvarig}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="border-b-2 border-t-2 border-r-2 border-l-2 border-black p-2 w-full">
                        <span className="font-calibri text-custom-black text-lg">
                          Medverkande:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.medverkande}
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-0">
                      <div className="col-span-6 flex flex-col justify-between  h-full">
                        <div className="flex flex-col justify-between h-full">
                          <div className="border-l-2 border-r-2 border-black p-2 flex-grow">
                            <span className="font-calibri text-custom-black text-lg">
                              Byggläge:
                            </span>
                            <br />
                            <span className="font-calibri text-custom-black text-lg font-bold">
                              {documentData?.bygglage}
                            </span>
                          </div>
                          <div className="border-2 border-black p-2 flex-grow">
                            <span className="font-calibri text-custom-black text-lg">
                              Väderlek:
                            </span>
                            <br />
                            <span className="font-calibri text-custom-black text-lg font-bold">
                              {documentData?.vaderlek}
                            </span>
                          </div>
                          <div className="border-b-2 border-r-2 border-l-2 border-black p-2 flex-grow">
                            <span className="font-calibri text-custom-black text-lg">
                              Datum:
                            </span>
                            <br />
                            <span className="font-calibri text-custom-black text-lg font-bold">
                              {documentData?.datum}
                            </span>
                          </div>
                        </div>
                      </div>

                      {coverPhotoForReport && (
                        <div className="col-span-6  flex items-center">
                          <img
                            src={coverPhotoForReport.imageUrl}
                            alt="Cover"
                            onLoad={(e) => {
                              // You can potentially adjust the height of the text boxes based on the image height here, but it requires state management
                            }}
                            className="w-full h-auto max-h-60"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-6 left-6 flex">
              <div className="flex mb-20 ml-6 mr-10">
                <div
                  className={`border-l-2 border-t-2 border-b-2 border-black p-2 w-full ${
                    selectedSignatureBox === "left" ? "" : "opacity-50"
                  }`}
                >
                  <span className="font-calibri text-custom-black text-lg">
                    Byggherrens fuktsakkunnige, sign.
                  </span>
                  <br />
                  {selectedSignatureBox === "left" && signatureUrl && (
                    <img
                      src={signatureUrl}
                      alt="Signature"
                      style={{ maxWidth: "40%", height: "auto" }}
                    />
                  )}
                </div>

                <div
                  className={`border-2 border-black p-2 w-full ${
                    selectedSignatureBox === "right" ? "" : "opacity-50"
                  }`}
                >
                  <span className="font-calibri text-custom-black text-lg">
                    Fuktsäkerhetsansvarig produktion, sign.
                  </span>
                  <br />
                  {selectedSignatureBox === "right" && signatureUrl && (
                    // Assuming the same signature URL is used for demonstration
                    <img
                      src={signatureUrl}
                      alt="Signature"
                      style={{ maxWidth: "40%", height: "auto" }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="radio_buttons">
              <div className="absolute bottom-14 left-6 flex flex-grid ml-6">
                <div className="mr-28">
                  <label>
                    <input
                      type="radio"
                      value="left"
                      checked={selectedSignatureBox === "left"}
                      onChange={handleRadioChange}
                    />
                    Byggherrens fuktsakkunnige, sign.
                  </label>
                </div>
                <label>
                  <input
                    type="radio"
                    value="right"
                    checked={selectedSignatureBox === "right"}
                    onChange={handleRadioChange}
                  />
                  Fuktsäkerhetsansvarig produktion, sign.
                </label>
              </div>
            </div>

            <div className="absolute bottom-6 right-6">
              <span className="font-calibri text-custom-black text-lg">
                Sida: 1({totalPages})
              </span>
            </div>
          </div>
        </div>

        {Array.isArray(nonEmptyPages) &&
          nonEmptyPages.map((page, pageIndex) => (
            <div
              className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden relative p-6 print-container mb-8"
              style={{ width: "21cm", height: "29.7cm" }}
              key={pageIndex}
            >
              <div className="relative">
                <div className="relative">
                  <div
                    className="absolute top-0 left-0"
                    style={{ left: "2em", top: "2.2893em" }}
                  >
                    <img
                      src={logoUrl}
                      alt="Doc logo"
                      className="h-[10%] w-[29%]"
                    />
                  </div>
                  <div
                    className="font-custom1 transform scale-100"
                    style={{ transform: "scale(1)" }}
                  >
                    <div className="relative w-full h-[7em]">
                      <div
                        className="absolute whitespace-nowrap"
                        style={{ left: "27.9758em", top: "2.2893em" }}
                      >
                        <span className="font-calibri-bold text-custom-black text-xl leading-tight font-bold">
                          {documentData?.fileName}
                        </span>
                      </div>

                      <div
                        className="absolute whitespace-nowrap mt-2"
                        style={{ left: "20em", top: "4em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                          Datum:&nbsp;
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-1"
                        style={{ left: "20em", top: "6em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed">
                          {documentData?.datum}
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-2"
                        style={{ left: "27.9758em", top: "4em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                          Uppdragsnummer:&nbsp;
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-1"
                        style={{ left: "27.9758em", top: "6em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed">
                          {documentData?.uppdragsnummer}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {page.map((content, contentIndex) => {
                const commentIdentifier = content.comment
                  ? `${content.comment.number}_${content.comment.imageName}`
                  : null;
                const commentStyle =
                  contentIndex === 0 ? firstCommentStyle : otherCommentStyle;
                if (
                  content &&
                  content.comment &&
                  content.comment.usedInReport
                ) {
                  const isTitleDisplayed =
                    content.comment.titleNumber <= lastTitleNum;
                  const displayTitleNumber = isTitleDisplayed
                    ? null
                    : content.comment.titleNumber;
                  const displayCommentNumber = content.comment.commentNumber;
                  console.log(
                    `Content on Page ${pageIndex + 1}, Comment ${
                      contentIndex + 1
                    }:`,
                    content
                  );
                  content.photos &&
                    content.photos.forEach((photo) => {
                      console.log(
                        `Comment Image URL on Page ${pageIndex + 1}, Comment ${
                          contentIndex + 1
                        }:`,
                        photo.imageUrl
                      );
                    });
                  const titleData = titleCounts[content.comment.title];
                  const commentNumber =
                    titleData?.commentIds[content.comment.id];

                  if (content.comment.number >= 100) {
                    // Render comment content without marker and reference
                    return (
                      <div
                        key={`comment-${pageIndex}-${contentIndex}`}
                        ref={(el) =>
                          (commentRefs.current[
                            `comment-${pageIndex}-${contentIndex}`
                          ] = el)
                        }
                        style={commentStyle}
                        className="comment-content border border-gray-300 rounded p-2"
                      >
                        <div className="flex items-center mb-2">
                          <span className="font-semibold mr-2">
                            {`${titleData?.titleNum}.${commentNumber}`}
                          </span>
                          <h3 className="font-semibold ml-2">
                            {content.comment.title}
                          </h3>
                          <span
                            className={`ml-auto px-2 py-1 inline-flex items-center text-xs leading-5 rounded-full  ${
                              content.comment.status === "Åtgärd Krävs"
                                ? "bg-red-100 text-red-800"
                                : content.comment.status === "Följas Upp"
                                ? "bg-yellow-100 text-yellow-800"
                                : "bg-green-100 text-green-800"
                            }`}
                          >
                            {content.comment.status}
                          </span>
                        </div>
                        <p className="text-sm font-semibold mb-2">
                          {content.comment.question}
                        </p>
                        <p className="mb-4">{content.comment.commentText}</p>

                        {content.comment.photos && (
                          <div className="photo-container flex flex-wrap gap-2 mt-2">
                            {getPhotosForComment(
                              content.comment.number,
                              content.comment.imageName
                            ).map((photo, idx) => (
                              <div
                                key={idx}
                                className="flex-1 min-w-0"
                                style={{ maxWidth: "290px", height: "290px" }}
                              >
                                <img
                                  src={photo.imageUrl}
                                  alt={`Comment related image ${idx}`}
                                  className="w-full h-full object-cover rounded"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={`comment-${pageIndex}-${contentIndex}`}
                      ref={(el) =>
                        (commentRefs.current[
                          `comment-${pageIndex}-${contentIndex}`
                        ] = el)
                      }
                      style={commentStyle}
                      className="comment-content border border-gray-300 rounded p-2"
                    >
                      <div className="flex items-center mb-2">
                        <span className="font-semibold mr-2">
                          {`${titleData?.titleNum}.${commentNumber}`}
                        </span>
                        <h3 className="font-semibold ml-2">
                          {content.comment.title}
                        </h3>
                        <span
                          className={`ml-auto px-2 py-1 inline-flex items-center text-xs leading-5 rounded-full  ${
                            content.comment.status === "Åtgärd Krävs"
                              ? "bg-[#ff0000] text-white font-bold"
                              : content.comment.status === "Följas Upp"
                              ? "bg-[#ffa500] text-white font-bold"
                              : "bg-[#008000] text-white font-bold"
                          }`}
                        >
                          {content.comment.status === "Åtgärd Krävs" ? (
                            <ErrorIcon
                              fontSize="small"
                              style={{ marginRight: "4px", color: "white" }}
                            />
                          ) : content.comment.status === "Följas Upp" ? (
                            <WarningIcon
                              fontSize="small"
                              style={{ marginRight: "4px", color: "white" }}
                            />
                          ) : (
                            <CheckCircleIcon
                              fontSize="small"
                              style={{ marginRight: "4px", color: "white" }}
                            />
                          )}
                          {content.comment.status}
                        </span>
                      </div>
                      <p className="text-sm font-semibold mb-2">
                        {content.comment.question}
                      </p>
                      <p className="mb-4">{content.comment.commentText}</p>

                      {content.comment.photos && (
                        <div className="photo-container flex flex-wrap gap-2 mt-2">
                          {getPhotosForComment(
                            content.comment.number,
                            content.comment.imageName
                          ).map((photo, idx) => (
                            <div
                              key={idx}
                              className="flex-1 min-w-0"
                              style={{ maxWidth: "290px", height: "290px" }}
                            >
                              <img
                                src={photo.imageUrl}
                                alt={`Comment related image ${idx}`}
                                className="w-full h-full object-cover rounded"
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="mt-3 text-left flex items-center">
                        <span>Referenspunkt: </span>
                        <div
                          className={`inline-flex w-5 h-5 rounded-full text-white items-center justify-center ml-1 mr-2 ${
                            content.comment.color === "red"
                              ? "bg-red-500"
                              : content.comment.color === "orange"
                              ? "bg-orange-500"
                              : "bg-green-500"
                          }`}
                          style={{
                            backgroundColor:
                              content.comment.color === "red"
                                ? "#ff0000"
                                : content.comment.color === "orange"
                                ? "#ffa500"
                                : "#008000",
                          }}
                        >
                          <span className="text-xs">
                            {content.comment.number}
                          </span>
                        </div>
                        <span>
                          på ritning{" "}
                          {content.comment.imageName.split("_Page1")[0]}
                        </span>
                      </div>
                    </div>
                  );
                } else if (content && content.image) {
                  return (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "450px",
                      }}
                    >
                      <div
                        key={`image-${pageIndex}-${contentIndex}`}
                        className="image-container"
                        style={{
                          width: "100%",
                          paddingTop: `${paddingTopPercentage}%`,
                          position: "absolute",
                          overflow: "hidden",
                          background: "transparent",
                        }}
                      >
                        <div
                          className="absolute whitespace-nowrap"
                          style={{ left: "2.3em", top: "14em" }}
                        >
                          <span className="font-calibri text-custom-black text-lg font-bold">
                            {extractImageName(content.image.url)}
                          </span>
                        </div>

                        <div
                          className="absolute whitespace-nowrap"
                          style={{ left: "2.1em", top: "10em" }}
                        >
                          <button
                            onClick={() =>
                              handleRemoveImageFromReport(
                                content.image.url,
                                pageIndex
                              )
                            }
                            className="remove-from-report-button"
                            style={{
                              position: "absolute",
                              backgroundColor: "blue",
                              color: "white",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              cursor: "pointer",
                              zIndex: 10, // Ensure it's above other elements
                            }}
                          >
                            Ta bort från rapport
                          </button>
                        </div>
                        <div
                          className="image-content"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            background: "transparent",
                          }}
                        >
                          <img
                            src={content.image.url}
                            alt="Floor plan"
                            onLoad={handleImageLoad}
                            ref={imageRef}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                          {renderMarkers(
                            content.markers,
                            imageRenderedSize,
                            content.image.name,
                            content.image.url,
                            documentId // Pass the current document ID here
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div className="absolute bottom-6 right-6">
                <span className="font-calibri text-custom-black text-lg">
                  Sida: {pageIndex + 2}({totalPages})
                </span>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default DocumentPreview;
{
  /*
  import React, { useEffect, useRef, useState } from "react";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import doclogo from "../images/projektalliansLogo3.png";
import {
  fetchCommentsFromFirestore
} from "../Context/firestoreQueries";
import { auth, db } from "../firebase";

const DocumentPreview = ({
  projectId,
  documentId,
  documentData,
  printRef,
  documentContent,
  pages,
  project,
  estimatedHeights,
  lastTitleNum,
  photosByImageName,
  titleCounts,
  usedCommentCombinations,
  setUsedCommentCombinations,
}) => {
  console.log("Photos By Image Name in DocumentPreview:", photosByImageName);
  const commentRefs = useRef({});
  const [commentsByImage, setCommentsByImage] = useState({});
  const ORIGINAL_IMAGE_WIDTH = 2526;
  const ORIGINAL_IMAGE_HEIGHT = 3576;
  const ASPECT_RATIO = ORIGINAL_IMAGE_HEIGHT / ORIGINAL_IMAGE_WIDTH;
  const paddingTopPercentage = ASPECT_RATIO * 100;
  const totalPages = pages.length + 1;

  const measureAndLogCommentHeights = () => {
    Object.entries(commentRefs.current).forEach(([key, ref]) => {
      if (ref) {
        const actualHeight = ref.clientHeight;
        console.log(`Actual height of ${key}: ${actualHeight}px`);
      }
    });
  };
  useEffect(() => {
    const fetchPageComments = async () => {
      const commentsMap = {};
      for (const page of pages) {
        for (const content of page) {
          if (content.image) {
            try {
              // Fetch only comments used in the report
              const imageComments = await fetchCommentsFromFirestore(
                projectId,
                documentId,
                content.image.name
              );
              commentsMap[content.image.name] = imageComments.filter(
                (comment) => comment.usedInReport
              );
            } catch (error) {
              console.error(
                "Error fetching comments for image:",
                content.image.name,
                error
              );
            }
          }
        }
      }
      setCommentsByImage(commentsMap);
    };

    fetchPageComments();

    const commentsPath = db
      .collection("userDocs")
      .doc(auth.currentUser?.uid)
      .collection("docs")
      .doc(projectId)
      .collection("Documents")
      .doc(documentId)
      .collection("markers");

    const unsubscribe = commentsPath.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const commentData = change.doc.data();
        const commentIdentifier = `${commentData.number}_${change.doc.id}`;

        if (
          (change.type === "added" || change.type === "modified") &&
          commentData.usedInReport
        ) {
          if (!usedCommentCombinations.has(commentIdentifier)) {
            setCommentsByImage((prev) => ({
              ...prev,
              [change.doc.id]: [
                ...(prev[change.doc.id] || []).filter(
                  (c) => c.id !== commentData.id
                ),
                commentData,
              ],
            }));
            setUsedCommentCombinations((prev) =>
              new Set(prev).add(commentIdentifier)
            );
          }
        } else if (change.type === "removed" || !commentData.usedInReport) {
          if (usedCommentCombinations.has(commentIdentifier)) {
            setCommentsByImage((prev) => ({
              ...prev,
              [change.doc.id]: (prev[change.doc.id] || []).filter(
                (comment) => comment.id !== change.doc.id
              ),
            }));
            setUsedCommentCombinations((prev) => {
              const newSet = new Set(prev);
              newSet.delete(commentIdentifier);
              return newSet;
            });
          }
        }
      });
    });

    return () => unsubscribe();
  }, [pages, projectId, documentId, usedCommentCombinations]);

  const [imageRenderedSize, setImageRenderedSize] = useState({
    width: 0,
    height: 0,
  });

  const extractImageName = (imageUrl) => {
    // Extract the filename from the URL and format it
    let namePart = imageUrl.split("F").pop(); // Get the last part after '/'
    // Extract the part before '_Page1'
    namePart = namePart.split("_Page")[0];
    console.log(namePart);
    return namePart;
  };
  const calculateAspectRatio = (width, height) => {
    return height / width;
  };
  const imageRef = useRef(null);

  const handleImageLoad = (event) => {
    // Calculate rendered image size
    const renderedWidth = event.target.clientWidth;
    const renderedHeight = event.target.clientHeight;
    setImageRenderedSize({ width: renderedWidth, height: renderedHeight });
  };

  const getPhotosForComment = (commentNumber, commentImageName) => {
    const photosForImage = photosByImageName[commentImageName] || {};
    const photosForComment = photosForImage[commentNumber] || [];

    // Filter photos to only include those with usedWithComment set to true
    return photosForComment.filter((photo) => photo.usedWithComment === true);
  };

  const extractImageNameFromUrl = (imageUrl) => {
    let namePart = imageUrl.split("F").pop(); // Get the last part after '/'
    namePart = namePart.split("_Page")[0]; // Extract the part before '_Page'
    return namePart;
  };
  const renderMarkers = (
    markers,
    imageRenderedSize,
    imageName,
    imageUrl,
    currentDocumentId
  ) => {
    const imageNameFromUrl = extractImageNameFromUrl(imageUrl);
    const imageComments = commentsByImage[imageName] || [];

    return markers.map((marker, index) => {
      // Find the comment associated with the marker, checking both image names and documentId
      const associatedComment = imageComments.find((comment) => {
        const commentImageBaseName = comment.imageName.split("_Page")[0];
        return (
          comment.number === marker.number &&
          commentImageBaseName === imageNameFromUrl &&
          comment.documentId === currentDocumentId
        );
      });

      // Check if the comment number is less than 100 before rendering
      if (associatedComment?.number >= 100) {
        return null; // Do not render markers for comments with numbers >= 100
      }

      // Use the color from the associated comment if available, otherwise default to blue
      const backgroundColor = associatedComment
        ? associatedComment.color
        : "blue";

      const markerStyle = {
        position: "absolute",
        left: `${(marker.xPercentage / 100) * imageRenderedSize.width}px`,
        top: `${(marker.yPercentage / 100) * imageRenderedSize.height}px`,
        transform: "translate(-50%, -50%)",
        backgroundColor, // Apply the color based on the associated comment
        color: "white",
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
      };

      return (
        <div key={index} className="marker" style={markerStyle}>
          {marker.number}
        </div>
      );
    });
  };

  const firstCommentStyle = {
    marginTop: "40px",
  };

  const otherCommentStyle = {
    marginTop: "5px",
  };
  const formatImageName = (imageName) => {
    // Format the image name to remove unwanted parts
    return imageName.split("_Page1")[0];
  };

  // Modify the rendering logic in renderMarkers function

  return (
    <>
      <div ref={printRef}>
        <title>Filename</title>

        <div
          className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden relative p-6 print-container mb-8"
          style={{ width: "21cm", height: "29.7cm" }}
        >
          <div className="">
            <div className="relative">
              <div
                className="absolute top-0 left-0"
                style={{ left: "2em", top: "2.2893em" }}
              >
                <img src={doclogo} alt="Doc logo" className="h-[10%] w-[29%]" />
              </div>
              <div
                className="font-custom1 transform scale-100"
                style={{ transform: "scale(1)" }}
              >
                <div className="relative w-full h-[7em]">
                  <div
                    className="absolute whitespace-nowrap"
                    style={{ left: "27.9758em", top: "2.2893em" }}
                  >
                    <span className="font-calibri-bold text-custom-black text-xl leading-tight font-bold">
                      {documentData?.fileName}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-3"
                    style={{ left: "27.9758em", top: "5.3647em" }}
                  >
                    <span className="font-calibri-bold text-custom-black text-lg leading-relaxed font-bold">
                      {documentData?.foretag}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "8.547em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Uppdragsansvarig&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "10.2647em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.uppdragsansvarig}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "12.127em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Datum:&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "13.4447em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.datum}
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-2"
                    style={{ left: "27.9758em", top: "15.607em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                      Uppdragsnummer&nbsp;
                    </span>
                  </div>
                  <div
                    className="absolute whitespace-nowrap mt-1"
                    style={{ left: "27.9758em", top: "17.3247em" }}
                  >
                    <span className="font-calibri text-custom-black text-lg leading-relaxed">
                      {documentData?.uppdragsnummer}
                    </span>
                  </div>
                  <div className="absolute top-80 left-6 right-6">
                    <div className="mb-6">
                      <span className="font-calibri text-custom-black text-lg font-bold">
                        {documentData?.fileName}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Projekt Nr:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.uppdragsnummer}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Projektnamn:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {project?.fileName}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-r-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Ort:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.ort}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Beställare:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.bestallare}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Fuktsäkerhetsansvarig produktion:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.fuktsakerhetsansvarig}
                        </span>
                      </div>

                      <div className="border-t-2 border-l-2 border-r-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Kontrollansvarig PBL:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.kontrollansvarig}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="border-b-2 border-t-2 border-r-2 border-l-2 border-black p-2 w-full">
                        <span className="font-calibri text-custom-black text-lg">
                          Medverkande:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.medverkande}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="border-l-2 border-r-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Byggläge:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.bygglage}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="border-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Väderlek:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.vaderlek}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-between mb-4">
                      <div className="border-b-2 border-r-2 border-l-2 border-black p-2 w-1/3">
                        <span className="font-calibri text-custom-black text-lg">
                          Datum:
                        </span>
                        <br />
                        <span className="font-calibri text-custom-black text-lg font-bold">
                          {documentData?.datum}
                        </span>
                      </div>

                      <div className="w-1/3"></div>
                      <div className="w-1/3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-6 right-6">
              <span className="font-calibri text-custom-black text-lg">
                Sida: 1({totalPages})
              </span>
            </div>
          </div>
        </div>

        {Array.isArray(pages) &&
          pages.map((page, pageIndex) => (
            <div
              className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden relative p-6 print-container mb-8"
              style={{ width: "21cm", height: "29.7cm" }}
              key={pageIndex}
            >
              <div className="relative">
                <div className="relative">
                  <div
                    className="absolute top-0 left-0"
                    style={{ left: "2em", top: "2.2893em" }}
                  >
                    <img
                      src={doclogo}
                      alt="Doc logo"
                      className="h-[10%] w-[29%]"
                    />
                  </div>
                  <div
                    className="font-custom1 transform scale-100"
                    style={{ transform: "scale(1)" }}
                  >
                    <div className="relative w-full h-[7em]">
                      <div
                        className="absolute whitespace-nowrap"
                        style={{ left: "27.9758em", top: "2.2893em" }}
                      >
                        <span className="font-calibri-bold text-custom-black text-xl leading-tight font-bold">
                          {documentData?.fileName}
                        </span>
                      </div>

                      <div
                        className="absolute whitespace-nowrap mt-2"
                        style={{ left: "20em", top: "4em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                          Datum:&nbsp;
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-1"
                        style={{ left: "20em", top: "6em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed">
                          {documentData?.datum}
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-2"
                        style={{ left: "27.9758em", top: "4em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed font-bold">
                          Uppdragsnummer:&nbsp;
                        </span>
                      </div>
                      <div
                        className="absolute whitespace-nowrap mt-1"
                        style={{ left: "27.9758em", top: "6em" }}
                      >
                        <span className="font-calibri text-custom-black text-lg leading-relaxed">
                          {documentData?.uppdragsnummer}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {page.map((content, contentIndex) => {
                const commentIdentifier = content.comment
                  ? `${content.comment.number}_${content.comment.imageName}`
                  : null;
                const commentStyle =
                  contentIndex === 0 ? firstCommentStyle : otherCommentStyle;
                if (
                  content &&
                  content.comment &&
                  usedCommentCombinations.has(commentIdentifier) &&
                  content.comment.usedInReport
                ) {
                  const isTitleDisplayed =
                    content.comment.titleNumber <= lastTitleNum;
                  const displayTitleNumber = isTitleDisplayed
                    ? null
                    : content.comment.titleNumber;
                  const displayCommentNumber = content.comment.commentNumber;
                  console.log(
                    `Content on Page ${pageIndex + 1}, Comment ${
                      contentIndex + 1
                    }:`,
                    content
                  );
                  content.photos &&
                    content.photos.forEach((photo) => {
                      console.log(
                        `Comment Image URL on Page ${pageIndex + 1}, Comment ${
                          contentIndex + 1
                        }:`,
                        photo.imageUrl
                      );
                    });
                  const titleData = titleCounts[content.comment.title];
                  const commentNumber =
                    titleData?.commentIds[content.comment.id];

                  if (content.comment.number >= 100) {
                    // Render comment content without marker and reference
                    return (
                      <div
                        key={`comment-${pageIndex}-${contentIndex}`}
                        ref={(el) =>
                          (commentRefs.current[
                            `comment-${pageIndex}-${contentIndex}`
                          ] = el)
                        }
                        style={commentStyle}
                        className="comment-content border border-gray-300 rounded p-2"
                      >
                        <div className="flex items-center mb-2">
                          <span className="font-semibold mr-2">
                            {`${titleData?.titleNum}.${commentNumber}`}
                          </span>
                          <h3 className="font-semibold ml-2">
                            {content.comment.title}
                          </h3>
                          <span
                            className={`ml-auto px-2 py-1 inline-flex items-center text-xs leading-5 rounded-full  ${
                              content.comment.status === "Åtgärd Krävs"
                                ? "bg-red-100 text-red-800"
                                : content.comment.status === "Följas Upp"
                                ? "bg-yellow-100 text-yellow-800"
                                : "bg-green-100 text-green-800"
                            }`}
                          >
                            {content.comment.status}
                          </span>
                        </div>
                        <p className="text-sm font-semibold mb-2">
                          {content.comment.question}
                        </p>
                        <p className="mb-4">{content.comment.commentText}</p>
                     
                        {content.comment.photos && (
                          <div className="photo-container flex flex-wrap gap-2 mt-2">
                            {getPhotosForComment(
                              content.comment.number,
                              content.comment.imageName
                            ).map((photo, idx) => (
                              <div
                                key={idx}
                                className="flex-1 min-w-0"
                                style={{ maxWidth: "290px", height: "290px" }}
                              >
                                <img
                                  src={photo.imageUrl}
                                  alt={`Comment related image ${idx}`}
                                  className="w-full h-full object-cover rounded"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={`comment-${pageIndex}-${contentIndex}`}
                      ref={(el) =>
                        (commentRefs.current[
                          `comment-${pageIndex}-${contentIndex}`
                        ] = el)
                      }
                      style={commentStyle}
                      className="comment-content border border-gray-300 rounded p-2"
                    >
                      <div className="flex items-center mb-2">
                        <span className="font-semibold mr-2">
                          {`${titleData?.titleNum}.${commentNumber}`}
                        </span>
                        <h3 className="font-semibold ml-2">
                          {content.comment.title}
                        </h3>
                        <span
                          className={`ml-auto px-2 py-1 inline-flex items-center text-xs leading-5 rounded-full  ${
                            content.comment.status === "Åtgärd Krävs"
                              ? "bg-red-100 text-red-800"
                              : content.comment.status === "Följas Upp"
                              ? "bg-yellow-100 text-yellow-800"
                              : "bg-green-100 text-green-800"
                          }`}
                        >
                          {content.comment.status === "Åtgärd Krävs" ? (
                            <ErrorIcon
                              fontSize="small"
                              style={{ marginRight: "4px" }}
                            />
                          ) : content.comment.status === "Följas Upp" ? (
                            <WarningIcon
                              fontSize="small"
                              style={{ marginRight: "4px" }}
                            />
                          ) : (
                            <CheckCircleIcon
                              fontSize="small"
                              style={{ marginRight: "4px" }}
                            />
                          )}
                          {content.comment.status}
                        </span>
                      </div>
                      <p className="text-sm font-semibold mb-2">
                        {content.comment.question}
                      </p>
                      <p className="mb-4">{content.comment.commentText}</p>

                      {content.comment.photos && (
                        <div className="photo-container flex flex-wrap gap-2 mt-2">
                          {getPhotosForComment(
                            content.comment.number,
                            content.comment.imageName
                          ).map((photo, idx) => (
                            <div
                              key={idx}
                              className="flex-1 min-w-0"
                              style={{ maxWidth: "290px", height: "290px" }}
                            >
                              <img
                                src={photo.imageUrl}
                                alt={`Comment related image ${idx}`}
                                className="w-full h-full object-cover rounded"
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="mt-3 text-left flex items-center">
                        <span>Referenspunkt: </span>
                        <div
                          className={`inline-flex w-5 h-5 rounded-full text-white items-center justify-center ml-1 mr-2 ${
                            content.comment.color === "red"
                              ? "bg-red-500"
                              : content.comment.color === "orange"
                              ? "bg-orange-500"
                              : "bg-green-500"
                          }`}
                          style={{
                            backgroundColor:
                              content.comment.color === "red"
                                ? "#ff0000"
                                : content.comment.color === "orange"
                                ? "#ffa500"
                                : "#008000",
                          }}
                        >
                          <span className="text-xs">
                            {content.comment.number}
                          </span>
                        </div>
                        <span>
                          på ritning{" "}
                          {content.comment.imageName.split("_Page1")[0]}
                        </span>
                      </div>
                    </div>
                  );
                } else if (content && content.image) {
                  return (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "450px",
                      }}
                    >
                      <div
                        key={`image-${pageIndex}-${contentIndex}`}
                        className="image-container"
                        style={{
                          width: "100%",
                          paddingTop: `${paddingTopPercentage}%`,
                          position: "absolute",
                          overflow: "hidden",
                          background: "transparent",
                        }}
                      >
                        <div
                          className="absolute whitespace-nowrap"
                          style={{ left: "2.3em", top: "14em" }}
                        >
                          <span className="font-calibri text-custom-black text-lg font-bold">
                            {extractImageName(content.image.url)}
                          </span>
                        </div>
                        <div
                          className="image-content"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            background: "transparent",
                          }}
                        >
                          <img
                            src={content.image.url}
                            alt="Floor plan"
                            onLoad={handleImageLoad}
                            ref={imageRef}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                          {renderMarkers(
                            content.markers,
                            imageRenderedSize,
                            content.image.name,
                            content.image.url,
                            documentId // Pass the current document ID here
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div className="absolute bottom-6 right-6">
                <span className="font-calibri text-custom-black text-lg">
                  Sida: {pageIndex + 2}({totalPages})
                </span>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default DocumentPreview;

*/
}
