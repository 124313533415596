import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Navigate to="/landingpage" replace />;
    }

    return children;
}

export default PrivateRoute;

