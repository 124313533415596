import React, { useEffect, useRef } from 'react';
import Trash from '../images/Trash.png';

function DocumentPopup({ visible, onClose, document, onDelete, className }) {
  const popupRef = useRef(null);

  useEffect(() => {
    // Define the function inside useEffect to avoid potential conflicts
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    // Ensure that the event listeners are added to the correct document object
    window.document.addEventListener("mousedown", handleClickOutside);
    
    // Return a cleanup function that removes the event listener
    return () => window.document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]); // Only re-run if onClose changes

  if (!visible) return null;

  const handleDeleteClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    onDelete(document); // Pass the document to the onDelete function
  };

  return (
    <div className={`absolute bottom-full right-0 ${className} z-10`} onClick={e => e.stopPropagation()}>
      <div className='bg-white p-5 rounded-lg shadow-md w-64' ref={popupRef}>
        {/* Delete Button */}
        <button 
          onClick={handleDeleteClick}
          className='flex items-center justify-between w-full px-3 py-2 bg-white text-black text-base font-medium rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-400'
        >
          <img src={Trash} alt="Delete" className="w-5 h-5 mr-3"/>
          Radera Fuktrond
        </button>

        <div className='flex justify-end gap-4 mt-5'>
          <button 
            onClick={onClose}
            className='flex items-center justify-center px-3 py-1 text-black border border-gray-300 text-base font-medium rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-400'
          >
            Stäng
          </button>
        </div>
      </div>
    </div>
  );
}

export default DocumentPopup;

