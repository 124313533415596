import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import {
  fetchPhotosFromFirestore,
  fetchCommentsFromFirestore,
  fetchCoverPhotosFromFirestore,
  updatePhotoInFirestore,
  updateCoverPhotoInReport,
  deletePhoto,
  deleteCoverPhoto,
} from "../Context/firestoreQueries";
import { useSearchParams } from "react-router-dom";
import { auth, db } from "../firebase";
import Trash from "../images/Trash.png"; // add this icon

function Bilder({ projectId, documentId }) {
  const [photosByImageName, setPhotosByImageName] = useState({});
  const [comments, setComments] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [coverPhotoModalOpen, setCoverPhotoModalOpen] = useState(false);
  const [selectedCoverPhoto, setSelectedCoverPhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [photoLimitReached, setPhotoLimitReached] = useState(false);
  const [coverPhotos, setCoverPhotos] = useState([]);
  const [searchParams] = useSearchParams();
  const isSharedProject = searchParams.get("isShared") === "true";
  const ownerUID = searchParams.get("ownerUID");

  const handleDeleteCoverPhotoConfirmation = async (photo) => {
    const confirmDelete = window.confirm(
      "Är du säker på att du vill radera denna bild? Om du trycker på okej raderas bilden permanent."
    );
    if (confirmDelete) {
      await handleDeleteCoverPhoto(photo);
    }
  };

  const handleDeleteCoverPhoto = async (photo) => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    try {
      await deleteCoverPhoto(projectId, documentId, photo.id, uidToUse);
      // Update local state to remove the cover photo
      const updatedCoverPhotos = coverPhotos.filter((p) => p.id !== photo.id);
      setCoverPhotos(updatedCoverPhotos);
      setCoverPhotoModalOpen(false); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting cover photo:", error);
      alert("Failed to delete the cover photo. Please try again later.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!projectId || !documentId) {
        console.warn("Missing projectId or documentId for Bilder component");
        setLoading(false);
        return;
      }

      setLoading(true);
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

      try {
        const fetchedPhotos = await fetchPhotosFromFirestore(
          projectId,
          documentId,
          uidToUse
        );
        const fetchedComments = await fetchCommentsFromFirestore(
          projectId,
          documentId,
          uidToUse
        );
        const fetchedCoverPhotos = await fetchCoverPhotosFromFirestore(
          projectId,
          documentId,
          uidToUse
        );

        setPhotosByImageName(fetchedPhotos);
        setComments(fetchedComments);
        setCoverPhotos(fetchedCoverPhotos);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId, documentId, isSharedProject, ownerUID]);

  const handleRemoveCoverPhotoFromReport = async (photo) => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    try {
      // Directly update the usedInReport status of the specific cover photo
      const coverPhotoRef = db
        .collection("userDocs")
        .doc(uidToUse)
        .collection("docs")
        .doc(projectId)
        .collection("Documents")
        .doc(documentId)
        .collection("CoverPhotos")
        .doc(photo.id);
      await coverPhotoRef.update({ usedInReport: false });

      // Update the local state to reflect the change
      const updatedCoverPhotos = coverPhotos.map((p) =>
        p.id === photo.id ? { ...p, usedInReport: false } : p
      );
      setCoverPhotos(updatedCoverPhotos);

      // Close the modal and possibly force a re-render if needed
      setCoverPhotoModalOpen(false);
    } catch (error) {
      console.error("Error removing cover photo from report:", error);
      alert(
        "Failed to remove the cover photo from the report. Please try again later."
      );
    }
  };

  const getMarkerColor = (commentNumber) => {
    const comment = comments.find((c) => c.number === commentNumber);
    return comment?.status === "Åtgärd Krävs"
      ? "red"
      : comment?.status === "Följas Upp"
      ? "orange"
      : comment?.status === "Okej"
      ? "green"
      : "gray"; // default color
  };

  const handleOpenModal = (photo) => {
    setSelectedPhoto(photo);
    setModalOpen(true);
  };

  const handleOpenCoverPhotoModal = (photo) => {
    setSelectedCoverPhoto(photo);
    setCoverPhotoModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCoverPhotoModalOpen(false); // This line will ensure the cover photo modal is closed
  };

  const renderPhotoWithOverlay = (photo) => {
    return (
      <div
        key={photo.id}
        className="relative flex-1 min-w-0"
        style={{ maxWidth: "290px", height: "290px" }}
      >
        <img
          src={photo.imageUrl}
          alt={`Marker ${photo.number}`}
          className="w-full h-full object-cover rounded"
          onClick={() => handleOpenModal(photo)}
        />
        {photo.usedWithComment && (
          <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-1 text-sm">
            Används i rapport
          </div>
        )}
      </div>
    );
  };
  const handleUseInReport = async (photo) => {
    const imageName = photo.imageName;
    const photoId = photo.id;
    if (!imageName || !photoId) {
      console.error("Image name or photo ID is missing.");
      return;
    }

    // Identify the comment associated with the photo
    const commentNumber = photo.number; // Assuming 'number' in photo corresponds to comment number
    const photosForComment = Object.values(photosByImageName[imageName] || {})
      .flat()
      .filter((p) => p.number === commentNumber);

    // Check if we already have three photos for the specific comment
    const usedPhotosCount = photosForComment.filter(
      (p) => p.usedWithComment
    ).length;

    if (usedPhotosCount >= 3) {
      setPhotoLimitReached(true);
      return;
    }

    try {
      // Determine the correct UID to use based on whether the project is shared
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

      await updatePhotoInFirestore(
        projectId,
        documentId,
        imageName,
        photoId,
        { usedWithComment: true },
        uidToUse
      );
      console.log("Photo marked as used in report.");

      // Update local state to reflect changes
      const updatedPhotos = photosForComment.map((p) =>
        p.id === photoId ? { ...p, usedWithComment: true } : p
      );
      setPhotosByImageName({
        ...photosByImageName,
        [imageName]: {
          ...photosByImageName[imageName],
          [commentNumber]: updatedPhotos,
        },
      });
      handleCloseModal();
      setPhotoLimitReached(false); // Reset the limit reached state
    } catch (error) {
      console.error("Error marking photo as used in report:", error);
    }
  };

  const handleUseCoverPhotoInReport = async () => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    try {
      await updateCoverPhotoInReport(
        projectId,
        documentId,
        selectedCoverPhoto.id,
        uidToUse,
        coverPhotos
      );
      // Update local state to reflect changes
      const updatedCoverPhotos = coverPhotos.map((photo) =>
        photo.id === selectedCoverPhoto.id
          ? { ...photo, usedInReport: true }
          : { ...photo, usedInReport: false }
      );
      setCoverPhotos(updatedCoverPhotos);
      setCoverPhotoModalOpen(false);
    } catch (error) {
      console.error("Error updating cover photo usedInReport status:", error);
    }
  };

  const renderCoverPhotoWithOverlay = (photo) => {
    return (
      <div
        key={photo.id}
        className="relative flex-1 min-w-0"
        style={{ maxWidth: "290px", height: "290px" }}
      >
        <img
          src={photo.imageUrl}
          alt="Cover"
          className="w-full h-full object-cover rounded cursor-pointer"
          onClick={() => handleOpenCoverPhotoModal(photo)}
        />
        <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-1 text-sm">
          {photo.usedInReport ? "Används i rapport" : "Används inte i rapport"}
        </div>
      </div>
    );
  };

  const handleDeletePhotoConfirmation = (photo) => {
    const confirmDelete = window.confirm(
      "Är du säker på att du vill radra denna bild? Om du trycker på okey raderas bilden permanent"
    );
    if (confirmDelete) {
      handleDeletePhoto(photo);
    }
  };

  const handleDeletePhoto = async (photo) => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    try {
      await deletePhoto(
        projectId,
        documentId,
        photo.imageName,
        photo.id,
        uidToUse
      );
      // Update local state to remove the photo
      const updatedPhotosByImageName = { ...photosByImageName };
      updatedPhotosByImageName[photo.imageName][photo.number] =
        updatedPhotosByImageName[photo.imageName][photo.number].filter(
          (p) => p.id !== photo.id
        );
      if (
        updatedPhotosByImageName[photo.imageName][photo.number].length === 0
      ) {
        delete updatedPhotosByImageName[photo.imageName][photo.number];
      }
      setPhotosByImageName(updatedPhotosByImageName);
      handleCloseModal(); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting photo:", error);
      alert("Failed to delete the photo. Please try again later.");
    }
  };

  if (loading) return <div>Laddar bilder...</div>;
  if (error) return <div>Error loading photos: {error.message}</div>;

  return (
    <div className="bg-white rounded-lg overflow-auto max-h-[650px] max-w-[720px] p-6">
      <h2 className="text-2xl font-semibold mb-6 border-b pb-4">Bilder</h2>
      {Object.keys(photosByImageName).length > 0 ? (
        Object.entries(photosByImageName)
          .filter(([_, photosByNumber]) =>
            Object.values(photosByNumber).some(
              (photoArray) => photoArray.length > 0
            )
          )
          .map(([imageName, photosByNumber]) => (
            <div key={imageName} className="mb-6">
              <h3 className="text-lg font-semibold mb-2">
                Refererar till ritning: {imageName.split("_Page1")[0]}
              </h3>
              {Object.entries(photosByNumber).map(
                ([markerNumber, photoArray]) => (
                  <div key={markerNumber} className="mb-4">
                    <div className="flex items-center mb-2">
                      <div
                        className="w-6 h-6 rounded-full text-white flex items-center justify-center mr-2"
                        style={{
                          backgroundColor: getMarkerColor(
                            parseInt(markerNumber)
                          ),
                        }}
                      >
                        {markerNumber}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      {photoArray.map((photo) => renderPhotoWithOverlay(photo))}
                    </div>
                  </div>
                )
              )}
            </div>
          ))
      ) : (
        <div className="text-center text-gray-400">Inga bilder att visa</div>
      )}
      <h3 className="text-lg font-semibold mb-2 mt-6">
        Bilder till första sidan
      </h3>
      <div className="grid grid-cols-3 gap-4">
        {coverPhotos.map((photo) => renderCoverPhotoWithOverlay(photo))}
      </div>
      <Modal
        open={coverPhotoModalOpen}
        onClose={handleCloseModal}
        className="flex justify-center items-center outline-none overflow-x-hidden overflow-y-auto fixed inset-0 z-50"
      >
        <div className="bg-white p-6 rounded-lg max-w-md">
          <img
            src={selectedCoverPhoto?.imageUrl}
            alt="Enlarged cover"
            className="mb-4 max-w-full h-auto rounded-lg"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUseCoverPhotoInReport}
            className="mt-2"
          >
            Använd i rapport
          </Button>
          {selectedCoverPhoto?.usedInReport && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                handleRemoveCoverPhotoFromReport(selectedCoverPhoto)
              }
              className="mt-2"
            >
              Ta bort från rapport
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            startIcon={
              <img src={Trash} alt="Delete" style={{ width: 24, height: 24 }} />
            }
            onClick={() =>
              handleDeleteCoverPhotoConfirmation(selectedCoverPhoto)
            }
            className="mt-2"
          >
            Radera bild
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
            className="mt-2"
          >
            Stäng
          </Button>
        </div>
      </Modal>
     
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        className="flex justify-center items-center outline-none overflow-x-hidden overflow-y-auto fixed inset-0 z-50"
      >
        <div className="bg-white p-6 rounded-lg max-w-md">
          <img
            src={selectedPhoto?.imageUrl}
            alt="Enlarged"
            className="mb-4 max-w-full h-auto rounded-lg"
          />
          {!selectedPhoto?.usedWithComment && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUseInReport(selectedPhoto)}
              className="mt-2"
            >
              Använd i rapport
            </Button>
          )}
          {photoLimitReached && (
            <p className="text-red-500 mt-2">
              Du har redan valt tre bilder för denna kommentar.
            </p>
          )}
          <Button
            variant="contained"
            color="secondary"
            startIcon={
              <img src={Trash} alt="Delete" style={{ width: 24, height: 24 }} />
            }
            onClick={() => handleDeletePhotoConfirmation(selectedPhoto)}
            className="mt-2"
          >
            Delete Photo
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseModal}
            className="mt-2"
          >
            Stäng
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Bilder;
