import React, { useEffect, useRef } from "react";
import Trash from "../images/Trash.png";

function ProjectPopup({ visible, onClose, project, onDelete, className }) {
  const popupRef = useRef(null); // Always define hooks at the top level

  const handleDeleteClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    onDelete(project);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Effect hook for adding/removing the event listener
    const addEventListener = () =>
      document.addEventListener("mousedown", handleClickOutside);
    const removeEventListener = () =>
      document.removeEventListener("mousedown", handleClickOutside);

    if (visible) {
      addEventListener();
    } else {
      removeEventListener();
    }

    // Cleanup function to remove event listener
    return () => removeEventListener();
  }, [visible]); // Depend on `visible` to re-run effect when it changes

  if (!visible) {
    return null; // Return null after hooks to comply with rules of hooks
  }

  return (
    <div
      className={`absolute bottom-full right-0 ${className} z-10`}
      ref={popupRef}
    >
      <div className="bg-white p-5 rounded-lg shadow-md w-64">
        <button
          onClick={handleDeleteClick}
          className="flex items-center justify-between w-full px-3 py-2 bg-white text-black text-base font-medium rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          <img src={Trash} alt="Delete" className="w-5 h-5 mr-3" />
          Radera Projekt
        </button>

        <div className="flex justify-end gap-4 mt-5">
          <button
            onClick={(e) => {
              e.stopPropagation(); // Stop the click event from reaching the project button
              onClose(); // Close the popup
            }}
            className="flex items-center justify-center px-3 py-1 text-black border border-gray-300 text-base font-medium rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            Stäng
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProjectPopup;
