import React from "react";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../../images/WhiteLogo.svg";
import techtaleLogo1 from "../../images/NewLPLogo.svg";
import {
  FooterContainer,
  FooterLink,
  FooterLinkTitle,
  FooterLinksContainer,
  FooterLinksItem,
  FooterLinksWrapper,
  FooterWrap,
  Social,
  MediaContainer,
  SocialLogo,
  WebsiteRights,
  TechtaleLogo,
  LinkContainer
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <LinkContainer>
      <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
      </LinkContainer>
      <FooterWrap>
      
        <Social>
          <MediaContainer>
            <SocialLogo to="/landingpage" onClick={toggleHome}>
              <img src={Logo} alt="Logo" />
              Fuktrond.se
            </SocialLogo>
            <WebsiteRights>
              Techtale © {new Date().getFullYear()} Alla rättigheter förbehålls.
            </WebsiteRights>
            <TechtaleLogo>
              Ett system av <img src={techtaleLogo1} alt="Techtale Logo" />
            </TechtaleLogo>
          </MediaContainer>
        </Social>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
