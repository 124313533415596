import React, { useState, useEffect } from "react";
import {
  fetchCommentsFromFirestore,
  fetchPhotosFromFirestore,
  updateCommentDocument,
  updatePhotoInFirestore,
  updateMarkerColor,
  deleteComment,
  deleteMarker
} from "../Context/firestoreQueries";
import TextField from "@material-ui/core/TextField";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { auth, db } from "../firebase";
import { useParams, useSearchParams } from "react-router-dom";
import Trash from "../images/Trash.png"; // add this icon
function Kommentarer({
  projectId,
  documentId,
  onUseInReport,
  handleRemoveFromReport,
  onUpdatePhotosByImageName,
  userUID,
}) {
  const [comments, setComments] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [usedCommentIds, setUsedCommentIds] = useState(new Set()); // New state for tracking used comments
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [photosByImageName, setPhotosByImageName] = useState({});
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editableComment, setEditableComment] = useState({});
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [usedCommentCombinations, setUsedCommentCombinations] = useState(
    new Set()
  );
  const [searchParams] = useSearchParams();
  const isSharedProject = searchParams.get("isShared") === "true";
  const ownerUID = searchParams.get("ownerUID");


  
  

  const titleToQuestions = {
    "Skydd av material och konstruktion": [
      {
        label:
          "Utförs mottagningskontroll för material och produkter enligt plan?",
        value:
          "Utförs mottagningskontroll för material och produkter enligt plan?",
      },
      {
        label: "Finns möjlighet till torr förvaring avmaterial och produkter?",
        value: "Finns möjlighet till torr förvaring avmaterial och produkter?",
      },
      {
        label:
          "Lagras material och produkter skyddade från fukt, nedsmutsning och åverkan?",
        value:
          "Lagras material och produkter skyddade från fukt, nedsmutsning och åverkan?",
      },
      {
        label:
          "Loggas klimat inne på byggarbetsplats? RF (%), temp (°C), ånghalt g/m^3 ",
        value:
          "Loggas klimat inne på byggarbetsplats? RF (%), temp (°C), ånghalt g/m^3",
      },
      {
        label: "Loggas klimat ute? RF (%), temp (°C), ånghalt g/m^3",
        value: "Loggas klimat ute? RF (%), temp (°C), ånghalt g/m^3",
      },
      // ... other questions for this title ...
    ],
    "Läckage, nederbörd": [
      {
        label:
          "Har det förekommit vattenläckage eller stora mängder nederbörd? Ange var och i vilken omfattning.",
        value:
          "Har det förekommit vattenläckage eller stora mängder nederbörd? Ange var och i vilken omfattning.",
      },
      {
        label:
          "Har vatten använts vid håltagning? Ange var och i vilken omfattning.",
        value:
          "Har vatten använts vid håltagning? Ange var och i vilken omfattning.",
      },
      {
        label: "Finns och fungerar beredskap för att hantera ett läckage?",
        value: "Finns och fungerar beredskap för att hantera ett läckage?",
      },
      {
        label:
          "Finns anordning för avledning av nederbörd från tak eller andra horisontella ytor?",
        value:
          "Finns anordning för avledning av nederbörd från tak eller andra horisontella ytor?",
      },
      // ... other questions for this title ...
    ],
    "Fukt i material": [
      {
        label:
          "Finns det trä eller träbaserade material (monterat eller inte monterat) som har fuktats upp Ange var och i vilken omfattning? Anges på ritning.",
        value:
          "Finns det trä eller träbaserade material (monterat eller inte monterat) som har fuktats upp? Ange var och i vilken omfattning? Anges på ritning.",
      },
      {
        label:
          "Har mätning av fukttillstånd i trä eller träbaserade material utförts? Ange resultat, mätmetod samt vem som utfört mätningen.",
        value:
          "Har mätning av fukttillstånd i trä eller träbaserade material utförts? Ange resultat, mätmetod samt vem som utfört mätningen.",
      },
      {
        label: "Har gips fuktats upp? Ange var och i vilken omfattning.",
        value: "Har gips fuktats upp? Ange var och i vilken omfattning.",
      },
      {
        label:
          "Lägsta uppmätta yttemperaturen på träbaserade material i °C Klimat RF% och temp °C ute och inne",
        value:
          "Lägsta uppmätta yttemperaturen på träbaserade material i °C Klimat RF% och temp °C ute och inne",
      },
      {
        label:
          "Har andra fuktkänsliga material (t ex mineralull, asfaboard, andra skivor etc.) fuktats upp? Ange vilka material, var och i vilken omfattning.",
        value:
          "Har andra fuktkänsliga material (t ex mineralull, asfaboard, andra skivor etc.) fuktats upp? Ange vilka material, var och i vilken omfattning.",
      },
    ],
    "Uttorkning av betong": [
      {
        label:
          "Finns det stående vatten på bjälklag? Ange omfattning och varaktighet samt markeras på ritning.",
        value:
          "Finns det stående vatten på bjälklag? Ange omfattning och varaktighet samt markeras på ritning.",
      },
      {
        label:
          "Har tidplanen för gjutning, uttorkningsklimatet eller typ av betongkvalitet eller ytskikt ändrats utifrån de ursprungliga förutsättningarna? Ange hur detta påverkar uttorkningstiden.",
        value:
          "Har tidplanen för gjutning, uttorkningsklimatet eller typ av betongkvalitet eller ytskikt ändrats utifrån de ursprungliga förutsättningarna? Ange hur detta påverkar uttorkningstiden.",
      },
      {
        label: "Påskyndas uttorkningen? Ange metod och varaktighet.",
        value: "Påskyndas uttorkningen? Ange metod och varaktighet.",
      },
      {
        label:
          "Utförs mätning av RF i betong enligt plan? Ange metod, vem som utför mätningen och omfattning.",
        value:
          "Utförs mätning av RF i betong enligt plan? Ange metod, vem som utför mätningen och omfattning.",
      },
      {
        label:
          "Finns det mätresultat? Ange avvikande mätresultat, orsak, åtgärd.",
        value:
          "Finns det mätresultat? Ange avvikande mätresultat, orsak, åtgärd.",
      },
    ],
    "Tekniska system": [
      {
        label: "Är öppna kanaler och don skyddstäckta?",
        value: "Är öppna kanaler och don skyddstäckta?",
      },
      {
        label: "Har trycksatta ledningar provtyckts innan de byggts in?",
        value: "Har trycksatta ledningar provtyckts innan de byggts in?",
      },
      {
        label:
          "Har det förekommit läckage från tekniska system? Ange typ av system och omfattning på läckaget.",
        value:
          "Har det förekommit läckage från tekniska system? Ange typ av system och omfattning på läckaget.",
      },
    ],
    "Renhet på ytor": [
      {
        label: "Finns smuts eller skräp på ytor?",
        value: "Finns smuts eller skräp på ytor?",
      },
      {
        label:
          "Har betongytor rengjorts innan de belagts med plastfolie, luftspaltbildande matta eller annat ytskikt? Ange metod och omfattning.",
        value:
          "Har betongytor rengjorts innan de belagts med plastfolie, luftspaltbildande matta eller annat ytskikt? Ange metod och omfattning.",
      },
    ],
    "Kontroll av utförandet vid fuktkritiska konstruktioner enligt fuktplan och fuktsäkerhetsprojekteringen":
      [
        {
          label:
            "Har fuktkritisk konstruktion kontrollerats vid det aktuella fuktrondstillfället?",
          value:
            "Har fuktkritisk konstruktion kontrollerats vid det aktuella fuktrondstillfället?",
        },
        {
          label: "Har fuktkritisk konstruktion dokumenterats?",
          value: "Har fuktkritisk konstruktion dokumenterats?",
        },
      ],
    Lufttäthet: [
      {
        label:
          "Har lufttäthetsåtgärder t ex överlapp, klämning vid skarvning plastfolie, tätning vid genomföringar, typ av plastfolie kontrollerats?",
        value:
          "Har lufttäthetsåtgärder t ex överlapp, klämning vid skarvning plastfolie, tätning vid genomföringar, typ av plastfolie kontrollerats?",
      },
    ],
    Övrigt: [
      {
        label: "Övrig kommentar",
        value: "Övrig Kommentar",
      },
    ],

    // ... other titles with their questions ...
  };

  const getMarkerColor = (status) => {
    switch (status) {
      case "Åtgärd Krävs":
        return "red";
      case "Följas Upp":
        return "orange";
      case "Okej":
        return "green";
      default:
        return "gray"; // default color if no status is selected
    }
  };

  const updateQuestionsForTitle = (selectedTitle) => {
    const questionsForTitle = titleToQuestions[selectedTitle] || [];
    setAvailableQuestions(questionsForTitle);
  };

  useEffect(() => {
    console.log("Current Document ID:", documentId);
    const fetchData = async () => {
      if (!projectId || !documentId) {
        console.warn(
          "Missing projectId or documentId for Kommentarer component"
        );
        return;
      }

      setLoading(true);
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

      try {
        // Fetch comments for the new document
        const fetchedComments = await fetchCommentsFromFirestore(
          projectId,
          documentId,
          uidToUse
        );
        const commentsWithLocalState = fetchedComments.map((comment) => ({
          ...comment,
          localUsedInReport: comment.usedInReport,
        }));
        setComments(commentsWithLocalState);

        // Fetch photos if necessary for the new document
        const fetchedPhotosByImageName = await fetchPhotosFromFirestore(
          projectId,
          documentId,
          uidToUse
        );
        setPhotosByImageName(fetchedPhotosByImageName);
        onUpdatePhotosByImageName(fetchedPhotosByImageName);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Real-time subscription to comments collection of the new document
    let unsubscribeComments;
    if (projectId && documentId) {
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
      unsubscribeComments = db
        .collection("userDocs")
        .doc(uidToUse)
        .collection("docs")
        .doc(projectId)
        .collection("Documents")
        .doc(documentId)
        .collection("markers") // Ensure the correct collection is being monitored
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (
              change.type === "removed" ||
              (change.type === "modified" && !change.doc.data().usedInReport)
            ) {
              setComments((prevComments) =>
                prevComments.filter((comment) => comment.id !== change.doc.id)
              );
            } else if (change.type === "added" || change.type === "modified") {
              const updatedComment = {
                ...change.doc.data(),
                localUsedInReport: change.doc.data().usedInReport,
              };
              setComments((prevComments) => [
                ...prevComments.filter(
                  (comment) => comment.id !== change.doc.id
                ),
                updatedComment,
              ]);
            }
          });
        });
    }

    return () => {
      if (unsubscribeComments) unsubscribeComments();
    };
  }, [projectId, documentId, isSharedProject, ownerUID]);

  // Dependency array
  // Dependency array to determine when to re-run the effect

  const getPhotosForComment = (commentNumber, commentImageName) => {
    const photosForImage = photosByImageName[commentImageName] || {};
    const photosForComment = photosForImage[commentNumber] || [];
    console.log("Photos for comment:", photosForComment); // Add this line for debugging
    return photosForComment.filter((photo) => photo.usedWithComment);
  };

  const removePhotoFromComment = async (photo) => {
    const imageName = photo.imageName; // Ensure this property exists in the photo object
    const photoId = photo.id;

    // Determine the correct UID to use based on whether the project is shared
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    console.log("Removing photo from comment:", {
      projectId,
      documentId,
      imageName,
      photoId,
      uidToUse,
    });

    try {
      if (!imageName) {
        throw new Error("Image name is undefined");
      }

      // Update the photo in Firestore using the correct UID
      await updatePhotoInFirestore(
        projectId,
        documentId,
        imageName,
        photoId,
        {
          usedWithComment: false,
        },
        uidToUse
      );

      console.log("Photo updated in Firestore");

      // Fetch updated photos by image name
      const updatedPhotosByImageName = await fetchPhotosFromFirestore(
        projectId,
        documentId,
        uidToUse
      );

      console.log("Updated photos by image name:", updatedPhotosByImageName);

      setPhotosByImageName(updatedPhotosByImageName);
      onUpdatePhotosByImageName(updatedPhotosByImageName);
    } catch (error) {
      console.error("Error updating photo:", error);
    }
    setModalOpen(false);
  };

  const handleUseInReportWrapper = async (comment) => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;

    const commentIdentifier = `${comment.number}_${comment.imageName}`;

    // Determine if the comment is currently in the report
    const isInReport = usedCommentCombinations.has(commentIdentifier);

    if (isInReport) {
      // If the comment is in the report, remove it
      await handleRemoveFromReportWrapper(comment, uidToUse); // Pass uidToUse to handleRemoveFromReportWrapper

      // Update localStorage after removing a comment
      const updatedComments = comments.map((c) => {
        if (c.number === comment.number && c.imageName === comment.imageName) {
          return { ...c, localUsedInReport: false };
        }
        return c;
      });
      localStorage.setItem("comments", JSON.stringify(updatedComments));

      // Optimistically update the local usedCommentCombinations state
      setUsedCommentCombinations((prev) => {
        const newSet = new Set(prev);
        newSet.delete(commentIdentifier);
        return newSet;
      });

      // Optimistically update the local comments state
      setComments(updatedComments);
    } else {
      // If the comment is not in the report, add it
      await onUseInReport(
        comment,
        getPhotosForComment(comment.number, comment.imageName),
        uidToUse
      ); // Pass uidToUse to onUseInReport

      // Optimistically update the local usedCommentCombinations state
      setUsedCommentCombinations((prev) => {
        const newSet = new Set(prev);
        newSet.add(commentIdentifier);
        return newSet;
      });

      // Optimistically update the local comments state and save to localStorage
      const updatedComments = comments.map((c) => {
        if (c.number === comment.number && c.imageName === comment.imageName) {
          return { ...c, localUsedInReport: true };
        }
        return c;
      });
      localStorage.setItem("comments", JSON.stringify(updatedComments));
      setComments(updatedComments);
    }
  };

  const handleRemoveFromReportWrapper = async (comment) => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    const commentIdentifier = `${comment.number}_${comment.imageName}`;

    // Check if the comment is currently in the report before attempting to remove it
    if (usedCommentCombinations.has(commentIdentifier)) {
      await handleRemoveFromReport(comment, uidToUse); // Pass uidToUse to handleRemoveFromReport

      // Mark this comment as removed in the usedCommentCombinations
      setUsedCommentCombinations((prev) => {
        const newSet = new Set(prev);
        newSet.delete(commentIdentifier); // Remove the identifier for the comment being removed
        return newSet;
      });

      // Optimistically update the local comments state and save to localStorage
      const updatedComments = comments.map((c) => {
        if (c.number === comment.number && c.imageName === comment.imageName) {
          return { ...c, localUsedInReport: false };
        }
        return c;
      });
      localStorage.setItem("comments", JSON.stringify(updatedComments));
      setComments(updatedComments);
    }
  };

  const handleOpenModal = (photo) => {
    setSelectedPhoto(photo);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDeleteComment = async (comment) => {
    // Prompt the user to confirm the deletion of the comment
    const confirmDelete = window.confirm("Är du säker på att du vill radera denna kommentar? Om du trycker på okej raderas kommentaren permanent.");
    if (confirmDelete) {
      // Determine the correct UID to use based on whether the project is shared
      const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
      
      try {
        // Attempt to remove the comment from the report if it's included
        // This is a preventive measure to ensure consistency
        // Assumes that handleRemoveFromReportWrapper is designed to handle
        // cases where the comment might not be in the report, without error
        await handleRemoveFromReportWrapper(comment, uidToUse);
  
        // Proceed to delete the comment from Firestore
        await deleteComment(
          projectId,
          documentId,
          comment.imageName,
          comment.id,
          uidToUse
        );
  
        // Also delete the associated marker
        // Assuming the comment's ID is used to identify the marker
        await deleteMarker(
          projectId,
          documentId,
          comment.imageName,
          comment.id,
          uidToUse
        );
  
        // Update the local state to reflect the comment deletion
        setComments((currentComments) => 
          currentComments.filter((c) => c.id !== comment.id)
        );
  
        // If you also track markers in the local state, you should update that state here
        // For example, removing the marker from a local state array of markers
      } catch (error) {
        // Handle any errors that occur during the deletion process
        console.error("Error deleting comment or marker:", error);
        alert("Failed to delete the comment or its marker. Please try again later.");
      }
    }
  };
  
  

  if (loading) return <div>Laddar kommentarer...</div>;
  if (error) return <div>Error loading comments: {error.message}</div>;

  const startEditing = (comment) => {
    setEditingCommentId(`${comment.imageName}_${comment.number}`);
    setEditableComment({
      ...comment,
      title: comment.title || "",
      status: comment.status || "",
      question: comment.question || "",
      commentText: comment.commentText || "",
    });
  
    // Update the available questions based on the comment's title
    updateQuestionsForTitle(comment.title);
  };
  

  const cancelEditing = () => {
    setEditingCommentId(null);
    setEditableComment({});
  };

  const handleCommentUpdate = async () => {
    const uidToUse = isSharedProject ? ownerUID : auth.currentUser?.uid;
    const color = getMarkerColor(editableComment.status);
  
    try {
      // Update the comment document with the new title, status, question, and comment text
      await updateCommentDocument(
        projectId,
        documentId,
        editableComment.imageName,
        editableComment.number,
        editableComment.title,
        editableComment.status,
        editableComment.commentText,
        color,
        editableComment.question, // Include the updated question here
        editableComment.localUsedInReport,
        uidToUse
      );
  
      // Then, update the color of the associated marker
      await updateMarkerColor(
        projectId,
        documentId,
        editableComment.imageName,
        editableComment.number,
        color,
        uidToUse
      );
  
      // Reflect changes in the local state
      setComments((prevComments) =>
        prevComments.map((comment) =>
          `${comment.imageName}_${comment.number}` === editingCommentId
            ? { ...editableComment, color }
            : comment
        )
      );
  
      cancelEditing(); // Reset editing state
    } catch (error) {
      console.error("Failed to update comment or marker color:", error);
    }
  };
  

  const renderCommentContent = (comment) => {
    const photosForThisComment = getPhotosForComment(
      comment.number,
      comment.imageName
    );
    const getStatusIcon = (status) => {
      switch (status) {
        case "Åtgärd Krävs":
          return <ErrorIcon style={{ fontSize: 20, marginRight: 4 }} />;
        case "Följas Upp":
          return <WarningIcon style={{ fontSize: 20, marginRight: 4 }} />;
        case "Okej":
          return <CheckCircleIcon style={{ fontSize: 20, marginRight: 4 }} />;
        default:
          return null;
      }
    };
    let commentNumberElement = null;
    let commentReferenceElement = null;

    if (comment.number < 100) {
      commentNumberElement = (
        <div
          className="w-6 h-6 rounded-full text-white flex items-center justify-center"
          style={{
            backgroundColor:
              comment.color === "red"
                ? "#ff0000"
                : comment.color === "orange"
                ? "#ffa500"
                : "#008000",
          }}
        >
          {comment.number}
        </div>
      );

      commentReferenceElement = (
        <p className="mt-2 text-sm text-gray-600">
          Refererar till ritning: {comment.imageName.split("_Page1")[0]}
        </p>
      );
    }
    if (`${comment.imageName}_${comment.number}` === editingCommentId) {
      return (
        <div>
          {/* Comment Number and Color Indicator */}
          {commentNumberElement}

        {/* Titel */}
        <FormControl fullWidth margin="normal">
    <InputLabel>Titel</InputLabel>
    <Select
      value={editableComment.title}
      onChange={(e) => {
        const newTitle = e.target.value;
        setEditableComment(prev => ({
          ...prev,
          title: newTitle,
        }));
        updateQuestionsForTitle(newTitle);
      }}
    >
      {/* Dynamiskt genererade menyobjekt baserade på tillgängliga titlar */}
      <MenuItem value="Skydd av material och konstruktion">Skydd av material och konstruktion</MenuItem>
      <MenuItem value="Läckage, nederbörd">Läckage, nederbörd</MenuItem>
      <MenuItem value="Fukt i material">Fukt i material</MenuItem>
      <MenuItem value="Uttorkning av betong">Uttorkning av betong</MenuItem>
      <MenuItem value="Tekniska system">Tekniska system</MenuItem>
      <MenuItem value="Renhet på ytor">Renhet på ytor</MenuItem>
      <MenuItem value="Kontroll av utförandet vid fuktkritiska konstruktioner enligt fuktplan och fuktsäkerhetsprojekteringen">
        Kontroll av utförandet vid fuktkritiska konstruktioner enligt fuktplan och fuktsäkerhetsprojekteringen
      </MenuItem>
      <MenuItem value="Lufttäthet">Lufttäthet</MenuItem>
      <MenuItem value="Övrigt">Övrigt</MenuItem>
    </Select>
  </FormControl>

  {/* Status */}
  <FormControl fullWidth margin="normal">
    <InputLabel>Status</InputLabel>
    <Select
      value={editableComment.status}
      onChange={(e) => setEditableComment({ ...editableComment, status: e.target.value })}
    >
      <MenuItem value="Okej">Okej</MenuItem>
      <MenuItem value="Följas Upp">Följas Upp</MenuItem>
      <MenuItem value="Åtgärd Krävs">Åtgärd Krävs</MenuItem>
    </Select>
  </FormControl>

  {/* Fråga */}
  <FormControl fullWidth margin="normal">
    <InputLabel>Fråga</InputLabel>
    <Select
      value={editableComment.question}
      onChange={(e) => setEditableComment({ ...editableComment, question: e.target.value })}
    >
      {availableQuestions.map((question) => (
        <MenuItem key={question.value} value={question.value}>{question.label}</MenuItem>
      ))}
    </Select>
  </FormControl>

  {/* Kommentartextfält */}
  <TextField
    label="Kommentar"
    fullWidth
    multiline
    minRows={4}
    margin="normal"
    variant="outlined"
    value={editableComment.commentText}
    onChange={(e) => setEditableComment({ ...editableComment, commentText: e.target.value })}
    className="mb-4"
  />

  {/* Uppdatera och Avbryt Knappar */}
  <div className="action-buttons">
    <Button
      onClick={handleCommentUpdate}
      variant="contained"
      style={{ backgroundColor: '#4CAF50', color: 'white', marginRight: '8px' }}
    >
      Uppdatera
    </Button>
    <Button
      onClick={cancelEditing}
      variant="contained"
      style={{ backgroundColor: '#F44336', color: 'white' }}
    >
      Avbryt
    </Button>
  </div>
</div>
      );
    } else {
      return (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <div className="flex items-center justify-between mb-4">
            {commentNumberElement}
            <h3 className="font-semibold text-xl text-gray-800">
              {comment.title}
            </h3>
            <span
              className={`px-3 py-1 inline-flex items-center text-sm font-semibold rounded-full ${
                comment.status === "Åtgärd Krävs"
                  ? "bg-[#ff0000] text-white"
                  : comment.status === "Följas Upp"
                  ? "bg-[#ffa500] text-white"
                  : "bg-[#008000] text-white"
              }`}
            >
              {comment.status}
            </span>
          </div>

          <div className="max-w-[600px]">
            <p className="mt-2 mb-5 text-gray-700">{comment.question}</p>
          </div>

          <TextField
            fullWidth
            multiline
            minRows={4}
            variant="outlined"
            value={comment.commentText}
            InputProps={{ readOnly: true }}
            className="bg-gray-100 mb-4"
          />

          <div className="mt-4 flex flex-wrap">
            {photosForThisComment.map((photo, index) => (
              <img
                key={index}
                src={photo.imageUrl}
                alt={`Thumbnail ${index}`}
                className="w-24 h-24 mr-2 mb-2 rounded shadow-sm object-cover" // Removed border styling
                onClick={() => handleOpenModal(photo)}
              />
            ))}
          </div>

          <div className="flex justify-between items-center mt-4">
            <div>
            <button
              onClick={() => handleUseInReportWrapper(comment)}
              className="bg-[#2750E4] flex-start hover:bg-blue-600 text-white font-semibold rounded-md px-5 mr-4 py-2"
            >
              {comment.localUsedInReport
                ? "Ta bort från rapport"
                : "Använd i rapport"}
            </button>

            <button
              onClick={() => startEditing(comment)}
              className="bg-green-600 flex-start hover:bg-green-500 text-white font-semibold rounded-md mr-4 px-5 py-2"
            >
              Uppdatera Kommentar
            </button>
            </div>
            <button
              onClick={() => handleDeleteComment(comment)}
              className="ml-2 p-1 rounded-full hover:bg-red-100"
            >
              <img src={Trash} alt="Delete" className="w-6 h-6 flex-end" />
            </button>
          </div>
          {commentReferenceElement}
        </div>
      );
    }
  };

  return (
    <div className="bg-white rounded-lg overflow-hidden p-6 max-w-[700px] mx-auto my-6">
      <h2 className="text-2xl font-semibold text-left mb-4 text-black">
        Kommentarer
      </h2>
      <div className="overflow-auto max-h-[580px] space-y-0">
        {comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id} className=" rounded-lg p-4">
              {renderCommentContent(comment)}
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">
            Inga kommentarer att visa
          </div>
        )}
      </div>
      {/* Modal component */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        className="flex justify-center items-center outline-none overflow-x-hidden overflow-y-auto fixed inset-0 z-50"
      >
        <div className="bg-white p-6 rounded-lg max-w-md">
          <img
            src={selectedPhoto?.imageUrl}
            alt="Förhandsgranskning"
            className="mb-4 max-w-full h-auto rounded-lg"
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => removePhotoFromComment(selectedPhoto)}
            className="mt-2"
          >
            Ta bort bild från kommentar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
            className="mt-2"
          >
            Stäng
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Kommentarer;