import React from 'react'
import Icon1 from '../../images/svg-15.svg'
import Icon2 from '../../images/svg-14.svg'
import Icon3 from '../../images/svg-13.svg'
import { ServicesContainer, ServicesH1, ServicesH2, ServicesWrapper, ServiceCard, ServicesIcon, ServicesP } from './ServicesElements'
const Services = () => {
  return (
    <ServicesContainer id="services">
        <ServicesH1>
            Processen
        </ServicesH1>
        <ServicesWrapper>
            <ServiceCard>
            <ServicesIcon src={Icon1}/>
            <ServicesH2>
            I Fält
            </ServicesH2>
            <ServicesP>
            Använd vår mobilapp för att lägga till kommentarer, bilder och göra markeringar på planritningar.
            </ServicesP>
            </ServiceCard>

            <ServiceCard>
            <ServicesIcon src={Icon2}/>
            <ServicesH2>
                Rapportgenerering
            </ServicesH2>
            <ServicesP>
                Använd informatioinen från din fuktrond för att skapa en detaljerad rapport med några få knapptryck. 
            </ServicesP>
            </ServiceCard>

            <ServiceCard>
            <ServicesIcon src={Icon3}/>
            <ServicesH2>
               Delning och Samarbete
            </ServicesH2>
            <ServicesP>
            Dela enkelt din rapport med teammedlemmar eller kunder. Samarbeta effektivt, oavsett var ni befinner er.
            </ServicesP>
            </ServiceCard>
            
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services