import styled from 'styled-components';

export const ServicesContainer = styled.div` 
    height: 100vh; // Uppdaterat för att ta upp hela skärmens höjd
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;

    @media screen and (max-width: 768px) {
        padding-top: 0px; /* Adjust as needed to compensate for the navbar height */
        min-height: 1600px; /* Allow container to expand based on content *
    }
`;

export const ServicesWrapper = styled.div` 
    max-width: 1000px;
    margin: 0 auto;
    display: grid; 
    grid-template-columns: repeat(3, 1fr);
    align-items: center; 
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 10px;
        
    }
`;

export const ServiceCard = styled.div` 
    background: #010606;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    border-radius: 10px; 
    min-height: 280px; // Minskat från 340px för att göra korten kortare
    padding: 30px; 
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out; 
        cursor: pointer; 
    }

    @media screen and (max-width: 768px) {
        min-height: 40px; /* Adjust as needed for smaller screens */
    }
`;

export const ServicesH1 = styled.h1` 
    font-size: 2.5rem; 
    font-weight: 700; 
    color: #010606; 
    margin-bottom: 64px; 

    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`;

export const ServicesH2 = styled.h2` 
    font-size: 1rem; 
    font-weight: 700; 
    margin-bottom: 10px; 
    color: #fff; 
`;

export const ServicesP = styled.p` 
    font-size: 1rem; 
    text-align: center; 
    color: #fff;
`;